export default class IpAddress {
    address: string;
    firstSeen: string;
    agent: string;

    constructor(address: string, firstSeen: string, agent: string) {
        this.address = address;
        this.firstSeen = firstSeen;
        this.agent = agent;
    }

    static arrayFromJson(json: any): IpAddress[] {
        if (json === undefined || json === null) {
            return [];
        }

        let newArray: IpAddress[] = [];
        (json as []).forEach(value => {
            newArray.push(IpAddress.singleFromJson(value));
        });
        return newArray;
    }

    static singleFromJson(json: any): IpAddress {
        if (json === undefined || json === null) {
            return new IpAddress("", "", "");
        }

        return new IpAddress(
            json["address"],
            json["firstSeen"],
            json["agent"]
        );
    }
}