export enum JobStateEnum {
    NEW = "New",
    DECLINED = "Declined",
    ESTIMATING = "Estimating",
    AWAITING_APPROVAL = "Awaiting approval",
    PERMITTING = "Permitting",
    ROUGH_IN = "Rough in",
    WAITING_FOR_TRIM_OUT = "Waiting for trim out",
    TRIM_OUT = "Trim out",
    WARRANTY = "Warranty",
    SERVICE_CALL = "Service call",
    CLOSED = "Closed"
}

const jobStateMap = new Map();
jobStateMap.set("NEW", JobStateEnum.NEW);
jobStateMap.set("DECLINED", JobStateEnum.DECLINED);
jobStateMap.set("ESTIMATING", JobStateEnum.ESTIMATING);
jobStateMap.set("AWAITING_APPROVAL", JobStateEnum.AWAITING_APPROVAL);
jobStateMap.set("PERMITTING", JobStateEnum.PERMITTING);
jobStateMap.set("ROUGH_IN", JobStateEnum.ROUGH_IN);
jobStateMap.set("WAITING_FOR_TRIM_OUT", JobStateEnum.WAITING_FOR_TRIM_OUT);
jobStateMap.set("TRIM_OUT", JobStateEnum.TRIM_OUT);
jobStateMap.set("WARRANTY", JobStateEnum.WARRANTY);
jobStateMap.set("SERVICE_CALL", JobStateEnum.SERVICE_CALL);
jobStateMap.set("CLOSED", JobStateEnum.CLOSED);
jobStateMap.set("UNKNOWN", "UNKNOWN");

export function getJobStateFromKey(key: string | undefined) {
    return jobStateMap.get(key ?? "UNKNOWN");
}

export const ALL_JOB_STATE_ENUM_KEYS = Array.from(jobStateMap.keys());