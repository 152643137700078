import React from "react";
import {
    Anchor,
    Box,
    Button,
    Center,
    Container,
    Group,
    Paper,
    PasswordInput,
    Progress,
    rem,
    Text,
    TextInput,
    Title,
} from '@mantine/core';
import gatewayClasses from "../Gateway.module.css";
import globalClasses from "../../Global.module.css";
import {useLocation, useNavigate} from "react-router-dom";
import {EMAIL_PLACEHOLDER, EMAIL_VALIDATION_ERROR_TEXT, ROUTE_LOGIN, UUID_PLACEHOLDER} from "../../../Constants";
import {validate as isValidUUID} from 'uuid';
import {IconArrowLeft, IconCheck, IconX} from "@tabler/icons-react";
import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import GlobalLoading from "../../../components/GlobalLoading";
import ManagedAlert from "../../../components/ManagedAlert";
import {useUserFunctionProvider} from "../../../context/UserContext";
import {isEmail, useForm} from "@mantine/form";
import {hasError} from "../../../util/GraphQl";
import {getTitle} from "../../../util/Util";

export function PasswordRequirement({meets, label}: { meets: boolean; label: string }) {
    return (
        <Text component="div" c={meets ? 'teal' : 'red'} mt={5} size="sm">
            <Center inline>
                {meets ? <IconCheck size="0.9rem" stroke={1.5}/> : <IconX size="0.9rem" stroke={1.5}/>}
                <Box ml={7}>{label}</Box>
            </Center>
        </Text>
    );
}

export const requirements = [
    {re: /^.{6,}$/, label: 'Has at least 6 characters'},
    {re: /[0-9]/, label: 'Includes number'},
    {re: /[a-z]/, label: 'Includes lowercase letter'},
    {re: /[A-Z]/, label: 'Includes uppercase letter'},
    {re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol'},
];

export function getStrength(password: string) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
        if (!requirement.re.test(password)) {
            multiplier += 1;
        }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}

interface SignupProps {
    isResetPassword?: boolean;
}

function Signup(props: SignupProps) {
    useDocumentTitle(getTitle(props.isResetPassword ? "Reset password" : "Signup"));
    const navigate = useNavigate();
    const location = useLocation();
    const userFunctions = useUserFunctionProvider();
    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});
    const form = useForm({
        initialValues: {
            token: location.pathname.split('/')[3] ?? '',
            email: '',
            password: '',
            confirmPassword: ''
        },
        validateInputOnBlur: true,
        validate: {
            token: (v) => (!isValidUUID(v) ? "Invalid token" : null),
            email: isEmail(EMAIL_VALIDATION_ERROR_TEXT),
            password: (v) => (!requirements.every(e => e.re.test(v)) ? true : null),
            confirmPassword: (value, values) => (value !== values.password ? 'Passwords did not match' : null),
        }
    });
    const strength = getStrength(form.values.password);
    const checks = requirements.map((requirement, index) => (
        <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(form.values.password)}/>
    ));
    const bars = Array(4)
        .fill(0)
        .map((_, index) => (
            <Progress
                styles={{section: {transitionDuration: '0ms'}}}
                value={
                    form.values.password.length > 0 && index === 0 ? 100 : strength >= ((index + 1) / 4) * 100 ? 100 : 0
                }
                color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
                key={index}
                size={4}
            />
        ));

    const completeCreateUser = (values: {
        token: string,
        email: string,
        password: string,
        confirmPassword: string
    }) => {
        loadingHandlers.open();
        if (props.isResetPassword) {
            userFunctions.updatePassword(values.email, values.password, values.token, () => {
                navigate(ROUTE_LOGIN, {state: {alert: {message: "Successfully updated password", color: "green"}}});
            }, (errors) => {
                if (hasError(errors, "InvalidDataException")) {
                    setAlert({message: "Invalid email or password", color: "red"});
                } else if (hasError(errors, "UserNotFoundException")) {
                    setAlert({message: "Unable to update user at this time", color: "red"});
                } else {
                    setAlert({message: "Error while trying to update user", color: "red"});
                }

            }, () => {
                loadingHandlers.close();
            });
        } else {
            userFunctions.completeCreateUser(values.email, values.password, values.token, () => {
                navigate(ROUTE_LOGIN, {state: {alert: {message: "Successfully create account", color: "green"}}});
            }, (errors) => {
                if (hasError(errors, "InvalidDataException")) {
                    setAlert({message: "Invalid email or password", color: "red"});
                } else if (hasError(errors, "UserNotFoundException")) {
                    setAlert({message: "Unable to create user at this time", color: "red"});
                } else {
                    setAlert({message: "Error while trying to create user", color: "red"});
                }

            }, () => {
                loadingHandlers.close();
            });
        }
    };

    const signupContent = (
        <React.Fragment>
            <TextInput
                label="Invite token"
                placeholder={UUID_PLACEHOLDER}
                required
                {...form.getInputProps('token')}
            />
            <TextInput
                type={"email"}
                label="Email"
                placeholder={EMAIL_PLACEHOLDER}
                required
                mt="md"
                {...form.getInputProps('email')}
            />
            <PasswordInput
                label="Password"
                placeholder="Your password"
                required
                mt="md"
                {...form.getInputProps('password')}
            />
            <Group gap={5} grow mt="xs" mb="md">
                {bars}
            </Group>
            {checks}
            <PasswordInput
                label="Confirm password"
                placeholder="Your password"
                required
                mt="md"
                {...form.getInputProps('confirmPassword')}
            />
            <Group justify={props.isResetPassword ? "flex-end" : "space-between"} mt="lg"
                   className={globalClasses.controls}>
                {props.isResetPassword !== true && (
                    <Anchor c="dimmed" size="sm" className={globalClasses.control}
                            onClick={() => navigate(ROUTE_LOGIN)}>
                        <Center inline>
                            <IconArrowLeft style={{width: rem(12), height: rem(12)}} stroke={1.5}/>
                            <Box ml={5}>Back to the login page</Box>
                        </Center>
                    </Anchor>
                )}
                <Button className={globalClasses.control}
                        type={"submit"}>{props.isResetPassword ? "Update password" : "Create account"}</Button>
            </Group>
        </React.Fragment>
    );

    return (
        <Box className={gatewayClasses.container}>
            <Box id={"needed-so-flex-does-not-resize-paper"}>
                <Container size={420}>
                    <Title ta="center" className={gatewayClasses.title}>
                        {props.isResetPassword ? "Reset your password!" : "Create your account!"}
                    </Title>
                    <Text c="dimmed" fz="sm" ta="center">
                        Enter your information
                        to {props.isResetPassword ? "reset your password" : "complete the sign up process"}
                    </Text>
                    <form onSubmit={form.onSubmit(completeCreateUser)}>
                        <GlobalLoading loading={loading}/>
                        <ManagedAlert mt={30} message={alert.message} color={alert.color} onClose={() => {
                            setAlert(prevState => ({...prevState, message: ""}));
                        }}/>
                        <Paper withBorder shadow="md" p={30} mt={30} radius="md" visibleFrom={"xs"}>
                            {signupContent}
                        </Paper>
                        <Box mt={30} hiddenFrom={"xs"}>
                            {signupContent}
                        </Box>
                    </form>
                </Container>
            </Box>
        </Box>
    );
}

export default Signup;