import React, {ReactNode} from "react";
import {UserContextProvider} from "./UserContext";
import {ApolloClient, ApolloProvider, gql, InMemoryCache} from "@apollo/client";
import {createFragmentRegistry} from "@apollo/client/cache";
import {CookiesProvider} from "react-cookie";
import {TitleContextProvider} from "./TitleContext";
import {PermissionGroupContextProvider} from "./PermissionGroupContext";
import {ContactContextProvider} from "./ContactContext";
import {JobContextProvider} from "./JobContext";
import {TimeContextProvider, TimeContextState} from "./TimeContext";
import {DatesProvider} from "@mantine/dates";

interface ContextComboProviderProps {
    children?: ReactNode;
}

const client = new ApolloClient({
    uri: "/graphql",
    cache: new InMemoryCache({
        fragments: createFragmentRegistry(gql`
            fragment FullIpAddress on IpAddress {
                address
                firstSeen
                agent
            }
            fragment FullTokenHash on TokenHash {
                id
                tokenHash
                expirationDate
                agent
            }
            fragment FullPersonalInformation on PersonalInformation {
                firstName
                preferredFirstName
                middleName
                lastName
                phoneNumber
            }
            fragment FullUser on User {
                id
                email
                permissionGroupIds
                knownIpAddresses {
                    ...FullIpAddress
                }
                lastKnownIpAddress {
                    ...FullIpAddress
                }
                personalInformation {
                    ...FullPersonalInformation
                }
                createdAt
                updatedAt
                tokenHashes {
                    ...FullTokenHash
                }
                revokedTokenHashes {
                    ...FullTokenHash
                }
                active
                titleId
                inviteToken
            }
            fragment FullTitle on Title {
                id
                name
                burdenRate
                billableRate
            }
            fragment FullPermissionGroup on PermissionGroup {
                id
                name
                permissions
            }
            fragment FullAddress on Address {
                addressLine1,
                addressLine2,
                city,
                state,
                zipCode,
                notes
            }
            fragment FullContact on Contact {
                id
                email
                personalInformation {
                    ...FullPersonalInformation
                }
                isBusiness
                businessName
                mailingAddress {
                    ...FullAddress
                }
                billingAddress {
                    ...FullAddress
                }
                notes
                active
            }
            fragment FullJob on Job {
                id
                date
                name
                shortName
                customerId
                location {
                    ...FullAddress
                }
                states {
                    date
                    state
                }
                notes
            }
            fragment FullTimeItem on TimeItem {
                job
                time
            }
            fragment FullTime on Time {
                id
                userId
                date
                time {
                    ...FullTimeItem
                }
                locked
            }
        `)
    })
});

export function ContextComboProvider(props: ContextComboProviderProps) {
    return (
        <CookiesProvider>
            <ApolloProvider client={client}>
                <UserContextProvider>
                    <PermissionGroupContextProvider>
                        <TitleContextProvider>
                            <ContactContextProvider>
                                <JobContextProvider>
                                    <TimeContextProvider>
                                        <DatesProvider settings={{firstDayOfWeek: 0}}>
                                            {props.children}
                                        </DatesProvider>
                                    </TimeContextProvider>
                                </JobContextProvider>
                            </ContactContextProvider>
                        </TitleContextProvider>
                    </PermissionGroupContextProvider>
                </UserContextProvider>
            </ApolloProvider>
        </CookiesProvider>
    );
}