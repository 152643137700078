// noinspection GraphQLUnresolvedReference

import {GraphQLErrors} from "@apollo/client/errors";
import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo} from "react";
import {ApolloError, gql, useLazyQuery, useMutation} from "@apollo/client";
import {useUserContextState, useUserContextUpdater} from "./UserContext";
import {onUnauthenticatedError} from "../util/GraphQl";
import {NavigateFunction} from "react-router-dom";
import {Job} from "../models/Job";

export interface JobContextStateType {
    updateJob: Job | undefined,
    opened: boolean,
    jobs: Job[]
}

export interface JobFunctionProvider {
    getJobs(jobId: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    createJob(data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    updateJob(data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    deleteJob(id: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;
}
const DEFAULT_STATE = {
    updateJob: undefined,
    opened: false,
    jobs: []
};
export const JobContextState = createContext<JobContextStateType>(DEFAULT_STATE);
export const JobContextUpdater = createContext<Dispatch<SetStateAction<JobContextStateType>>>(() => {
    return;
});
export const JobFunctionProviderContext = createContext<JobFunctionProvider>({} as JobFunctionProvider);

export function useJobContextState() {
    return useContext(JobContextState);
}

export function useJobContextUpdater() {
    return useContext(JobContextUpdater);
}

export function useJobFunctionProvider() {
    return useContext(JobFunctionProviderContext);
}

const GET_JOB = gql`
    query jobs($jobId: ID) {
        jobs(jobId: $jobId) {
            ...FullJob
        }
    }
`;
const CREATE_JOB = gql`
    mutation createJob($data: JobInput!) {
        createJob(data: $data) {
            ...FullJob
        }
    }
`;
const UPDATE_JOB = gql`
    mutation updateJob($data: JobInput!, $newState: String) {
        updateJob(data: $data, newState: $newState) {
            ...FullJob
        }
    }
`;
const DELETE_JOB = gql`
    mutation deleteJob($id: ID!) {
        deleteJob(id: $id)
    }
`;

interface JobContextProviderProps {
    children?: ReactNode;
}

export function JobContextProvider(props: JobContextProviderProps) {
    const user = useUserContextState();
    const setUser = useUserContextUpdater();

    const [jobs, setJobs] = React.useState<JobContextStateType>(DEFAULT_STATE);

    useEffect(() => {
        if(user.user === undefined) {
            setJobs(DEFAULT_STATE);
        }
    }, [user.user]);

    const [getJobs] = useLazyQuery(GET_JOB);
    const [createJob] = useMutation(CREATE_JOB);
    const [updateJob] = useMutation(UPDATE_JOB);
    const [deleteJob] = useMutation(DELETE_JOB);

    const sharedUpdate = (json: any) => {
        const item = Job.singleFromJson(json);
        setJobs((prevState) => {
            const newItems = [...prevState.jobs];
            const index = newItems.findIndex(v => v.id === item.id);
            if (index === -1) {
                newItems.push(item);
            } else {
                newItems[index] = item;
            }
            return {...prevState, jobs: newItems, updateJob: index === -1 ? prevState.updateJob : item};
        });
    };

    const funcObj = useMemo(() => {
        return {
            getJobs: (jobId: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                getJobs({
                    variables: {
                        jobId: jobId
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.error !== undefined || r.data === undefined) {
                        onUnauthenticatedError(r.error, setUser, navigate);
                        onError?.(r.error?.graphQLErrors ?? []);
                        return;
                    }

                    setJobs(prevState => ({...prevState, jobs: Job.arrayFromJson(r.data.jobs) ?? []}));
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            createJob: (data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                createJob({
                    variables: data,
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    sharedUpdate(r.data.createJob);
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            updateJob: (data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                updateJob({
                    variables: data,
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    sharedUpdate(r.data.updateJob);
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            deleteJob: (id: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                deleteJob({
                    variables: {
                        id: id ?? ""
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    setJobs((prevState) => {
                        const newItems = [...prevState.jobs];
                        const index = newItems.findIndex(v => v.id === id);
                        if (index !== -1) {
                            newItems.splice(index, 1);
                        }
                        return {...prevState, updateJob: undefined, jobs: newItems};
                    });
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            }
        };
    }, []);

    return (
        <JobContextState.Provider value={jobs}>
            <JobContextUpdater.Provider value={setJobs}>
                <JobFunctionProviderContext.Provider value={funcObj}>
                    {props.children}
                </JobFunctionProviderContext.Provider>
            </JobContextUpdater.Provider>
        </JobContextState.Provider>
    );
}