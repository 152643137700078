import {Paper, Table, Text} from "@mantine/core";
import classes from "../UserDrawer.module.css";
import React, {useMemo} from "react";
import {useUserContextState} from "../../../context/UserContext";

function RevokedTokens() {
    const user = useUserContextState();

    const tokens = useMemo(() => {
        return user.updateUser?.revokedTokenHashes.sort((a, b) => Date.parse(b.expirationDate) - Date.parse(a.expirationDate)) ?? [];
    }, [user.updateUser]);

    return (
        <Paper withBorder mt={"xl"} mb={"sm"} style={{maxHeight: "400px", overflow: "scroll"}}>
            <Table highlightOnHover maw={"100%"} layout={"fixed"}>
                <Table.Thead className={classes.header}>
                    <Table.Tr>
                        <Table.Th>Revoked token Hash</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {tokens.map((item) => (
                        <Table.Tr key={item.id}>
                            <Table.Td>
                                <div style={{wordWrap: "break-word"}}>
                                    <Text fw={500}>{item.tokenHash}</Text>
                                    <Text size="sm" c="dimmed">{item.agent}</Text>
                                    <Text size="sm" c="dimmed">{item.expirationDate + " (Expiration date)"}</Text>
                                </div>
                            </Table.Td>
                        </Table.Tr>
                    ))}
                    {tokens.length === 0 && (
                        <Table.Tr>
                            <Table.Td>
                                <Text fs="italic">No revoked tokens</Text>
                            </Table.Td>
                        </Table.Tr>
                    )}
                </Table.Tbody>
            </Table>
        </Paper>
    );
}

export default RevokedTokens;