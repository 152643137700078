import React, {useEffect} from "react";
import {Box, Button, Divider, Drawer, Flex, Group, Modal, Stack, Text} from "@mantine/core";
import {useForm, UseFormReturnType} from "@mantine/form";
import globalClasses from "../../pages/Global.module.css";
import GlobalLoading from "../GlobalLoading";
import ManagedAlert from "../ManagedAlert";
import {useDisclosure} from "@mantine/hooks";

interface FormDrawerProps<T> {
    opened: boolean;
    onClose: () => void;
    title: string;
    titleName: string | undefined;
    defaultValues: T;
    onComplete: (values: T, form: UseFormReturnType<T, (values: T) => T>, setAlert: React.Dispatch<React.SetStateAction<{message: string, color: string}>>) => void;
    onDelete: (form: UseFormReturnType<T, (values: T) => T>, setAlert: React.Dispatch<React.SetStateAction<{message: string, color: string}>>) => void;
    canDelete: boolean;
    canCreate?: boolean;
    isUpdate: boolean;
    updateValue: T | undefined;
    form: (form: UseFormReturnType<T, (values: T) => T>) => React.JSX.Element;
    loading: boolean;
    footer?: React.JSX.Element;
}

function FormDrawer<T>(props: FormDrawerProps<T>) {
    const [alert, setAlert] = React.useState({message: "", color: "red"});
    const [opened, {open, close}] = useDisclosure(false);
    const form = useForm({
        initialValues: props.defaultValues,
        validateInputOnBlur: true
    });

    useEffect(() => {
        setAlert({message: "", color: "red"});
    }, [props.opened]);

    useEffect(() => {
        form.setValues(props.updateValue !== undefined ? props.updateValue : props.defaultValues);
    }, [props.updateValue]);

    useEffect(() => {
        if (!form.isTouched()) {
            form.resetDirty();
        }
    }, [form.values]);

    return (
        <Drawer
            opened={props.opened}
            onClose={props.onClose}
            title={
                <Box style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "8px"}}>
                    <Text size={"lg"} fw={500} tt={"capitalize"}>{props.title}</Text>
                    <Divider variant={"solid"} orientation="vertical" size={"sm"} my={2}/>
                    <Text size={"sm"}>{props.titleName ?? "New"}</Text>
                </Box>
            }
            position={"right"}
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1
                }
            }}
            overlayProps={{backgroundOpacity: 0.5, blur: 2}}
        >
            <GlobalLoading loading={props.loading}/>
            <ManagedAlert size={"xs"} mb={"md"} message={alert.message} color={alert.color} onClose={() => {
                setAlert(prevState => ({...prevState, message: ""}));
            }}/>
            <form onSubmit={form.onSubmit(values => props.onComplete(values, form, setAlert))}>
                {props.form(form)}
                <Group justify="right" mt="lg" className={globalClasses.controls} mb={"lg"}>
                    {props.canDelete && props.isUpdate && (
                        <Button
                            className={globalClasses.control}
                            color={"red"}
                            variant={"subtle"}
                            onClick={open}
                        >
                            Delete {props.title}
                        </Button>
                    )}
                    <Button
                        className={globalClasses.control}
                        type={"submit"}
                        disabled={!form.isDirty() || (!props.canCreate && !props.isUpdate)}
                    >
                        {(!props.isUpdate ? "Create" : "Update") + " " + props.title}
                    </Button>
                </Group>
            </form>
            {props.footer}
            <Modal opened={opened} onClose={close} title={"Delete " + props.title} withCloseButton centered>
                <Stack>
                    <Text>Are You sure you want to delete {props.title}</Text>
                    <Flex
                        direction={{base: 'column', sm: 'row'}}
                        gap={{base: 'sm', sm: 'lg'}}
                        justify={"flex-end"}
                    >
                        <Button
                            className={globalClasses.control}
                            color={"gray"}
                            variant={"subtle"}
                            onClick={close}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={globalClasses.control}
                            color={"red"}
                            variant={"filled"}
                            onClick={() => {
                                close();
                                props.onDelete(form, setAlert);
                            }}
                        >
                            Delete
                        </Button>
                    </Flex>
                </Stack>
            </Modal>
        </Drawer>
    );
}

export default FormDrawer;