import React from "react";
import ReactDOM from "react-dom/client";
//import '../fonts/GreycliffCF/styles.css';
import '@mantine/core/styles.css';
import '@mantine/code-highlight/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/spotlight/styles.css';
import '@mantine/dates/styles.css';
import './index.css';
import Root from './Root';
import reportWebVitals from './reportWebVitals';
import {createTheme, MantineProvider} from "@mantine/core";
import {ContextComboProvider} from "./context/ContextComboProvider";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from "dayjs";

dayjs.extend(utc);
dayjs.extend(timezone);

const theme = createTheme({
    breakpoints: {
        xs: '30em',
        sm: '48em',
        md: '64em',
        lg: '74em',
        xl: '90em',
    },
    cursorType: 'pointer',
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <MantineProvider theme={theme} defaultColorScheme="dark">
            <ContextComboProvider>
                <Root/>
            </ContextComboProvider>
        </MantineProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
