// noinspection GraphQLUnresolvedReference

import {GraphQLErrors} from "@apollo/client/errors";
import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo} from "react";
import {ApolloError, gql, useLazyQuery, useMutation} from "@apollo/client";
import {useUserContextState, useUserContextUpdater} from "./UserContext";
import {onUnauthenticatedError} from "../util/GraphQl";
import {NavigateFunction} from "react-router-dom";
import {Contact} from "../models/Contact";

export interface ContactContextStateType {
    updateContact: Contact | undefined,
    opened: boolean,
    contacts: Contact[]
}

export interface ContactFunctionProvider {
    getContacts(jobId: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    createContact(data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    updateContact(data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    deleteContact(id: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;
}
const DEFAULT_STATE = {
    updateContact: undefined,
    opened: false,
    contacts: []
}
export const ContactContextState = createContext<ContactContextStateType>(DEFAULT_STATE);
export const ContactContextUpdater = createContext<Dispatch<SetStateAction<ContactContextStateType>>>(() => {
    return;
});
export const ContactFunctionProviderContext = createContext<ContactFunctionProvider>({} as ContactFunctionProvider);

export function useContactContextState() {
    return useContext(ContactContextState);
}

export function useContactContextUpdater() {
    return useContext(ContactContextUpdater);
}

export function useContactFunctionProvider() {
    return useContext(ContactFunctionProviderContext);
}

const GET_CONTACT = gql`
    query contacts($jobId: ID) {
        contacts(jobId: $jobId) {
            ...FullContact
        }
    }
`;
const CREATE_CONTACT = gql`
    mutation createContact($data: ContactInput!) {
        createContact(data: $data) {
            ...FullContact
        }
    }
`;
const UPDATE_CONTACT = gql`
    mutation updateContact($data: ContactInput!) {
        updateContact(data: $data) {
            ...FullContact
        }
    }
`;
const DELETE_CONTACT = gql`
    mutation deleteContact($id: ID!) {
        deleteContact(id: $id)
    }
`;

interface ContactContextProviderProps {
    children?: ReactNode;
}

export function ContactContextProvider(props: ContactContextProviderProps) {
    const user = useUserContextState();
    const setUser = useUserContextUpdater();

    const [contacts, setContacts] = React.useState<ContactContextStateType>(DEFAULT_STATE);

    useEffect(() => {
        if(user.user === undefined) {
            setContacts(DEFAULT_STATE);
        }
    }, [user.user]);

    const [getContacts] = useLazyQuery(GET_CONTACT);
    const [createContact] = useMutation(CREATE_CONTACT);
    const [updateContact] = useMutation(UPDATE_CONTACT);
    const [deleteContact] = useMutation(DELETE_CONTACT);

    const sharedUpdate = (json: any) => {
        const item = Contact.singleFromJson(json);
        setContacts((prevState) => {
            const newItems = [...prevState.contacts];
            const index = newItems.findIndex(v => v.id === item.id);
            if (index === -1) {
                newItems.push(item);
            } else {
                newItems[index] = item;
            }
            return {...prevState, contacts: newItems, updateContact: index === -1 ? prevState.updateContact : item};
        });
    };

    const funcObj = useMemo(() => {
        return {
            getContacts: (jobId: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                getContacts({
                    variables: {
                        jobId: jobId
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.error !== undefined || r.data === undefined) {
                        onUnauthenticatedError(r.error, setUser, navigate);
                        onError?.(r.error?.graphQLErrors ?? []);
                        return;
                    }

                    setContacts(prevState => ({...prevState, contacts: Contact.arrayFromJson(r.data.contacts) ?? []}));
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            createContact: (data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                createContact({
                    variables: data,
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    sharedUpdate(r.data.createContact);
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            updateContact: (data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                updateContact({
                    variables: data,
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    sharedUpdate(r.data.updateContact);
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            deleteContact: (id: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                deleteContact({
                    variables: {
                        id: id ?? ""
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    setContacts((prevState) => {
                        const newItems = [...prevState.contacts];
                        const index = newItems.findIndex(v => v.id === id);
                        if (index !== -1) {
                            newItems.splice(index, 1);
                        }
                        return {...prevState, updateContact: undefined, contacts: newItems};
                    });
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            }
        };
    }, []);

    return (
        <ContactContextState.Provider value={contacts}>
            <ContactContextUpdater.Provider value={setContacts}>
                <ContactFunctionProviderContext.Provider value={funcObj}>
                    {props.children}
                </ContactFunctionProviderContext.Provider>
            </ContactContextUpdater.Provider>
        </ContactContextState.Provider>
    );
}