import {TimeItem} from "./TimeItem";

export class Time {
    id: string;
    userId: string;
    date: string;
    time: TimeItem[] | undefined;
    locked: boolean;


    constructor(id: string,
                userId: string,
                date: string,
                time: TimeItem[] | undefined,
                locked: boolean) {
        this.id = id;
        this.userId = userId;
        this.date = date;
        this.time = time;
        this.locked = locked;
    }

    static arrayFromJson(json: any): Time[] | undefined {
        if (json === undefined || json === null) {
            return undefined;
        }

        let newArray: Time[] = [];
        (json as []).forEach(value => {
            newArray.push(Time.singleFromJson(value));
        });
        return newArray;
    }

    static singleFromJson(json: any): Time {
        return new Time(
            json["id"],
            json["userId"],
            json["date"],
            TimeItem.arrayFromJson(json["time"]),
            (json["locked"] === "true" || json["locked"] === true)
        );
    }

    copyTimeItems(): TimeItem[] {
        if(this.time === undefined) {
            return [];
        }

        return this.time.map(t => new TimeItem(t.job, t.time));
    }
}