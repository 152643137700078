import {ActionIcon, Button, Checkbox, Flex, Group, Menu, Paper, rem, Table, Text} from "@mantine/core";
import classes from "../UserDrawer.module.css";
import cx from 'clsx';
import {IconChevronDown, IconTrash} from "@tabler/icons-react";
import React, {useMemo} from "react";
import TokenHash from "../../../models/TokenHash";
import {useUserContextState, useUserContextUpdater, useUserFunctionProvider} from "../../../context/UserContext";
import {useNavigate} from "react-router-dom";
import {useDisclosure} from "@mantine/hooks";
import {hasError} from "../../../util/GraphQl";
import GlobalLoading from "../../GlobalLoading";
import ManagedAlert from "../../ManagedAlert";
import globalClasses from "../../../pages/Global.module.css";


function Tokens() {
    const user = useUserContextState();
    const setUser = useUserContextUpdater();
    const userFunctionProvider = useUserFunctionProvider();
    const navigate = useNavigate();
    const [selection, setSelection] = React.useState<string[]>([]);
    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});

    const tokenHashes = useMemo(() => {
        const rTokenHashes = user.updateUser?.revokedTokenHashes.map(token => token.tokenHash);
        return user.updateUser?.tokenHashes
            .filter(token => !rTokenHashes?.includes(token.tokenHash))
            .sort((a, b) => Date.parse(b.expirationDate) - Date.parse(a.expirationDate)) as TokenHash[] ?? [];
    }, [user]);

    const toggleRow = (id: string) => {
        setSelection(prevState => prevState.includes(id) ? prevState.filter((item) => item !== id) : [...prevState, id]);
    };
    const toggleAll = () => {
        setSelection(prevState => (prevState.length === user.updateUser?.tokenHashes.length ? [] : (user.updateUser?.tokenHashes ?? []).map(item => item.tokenHash)));
    };

    const revokeTokens = (tokens: string[] | undefined) => {
        loadingHandlers.open();
        setAlert({message: "", color: "red"});
        userFunctionProvider.revokeHashes(
            user.user?.id === user.updateUser?.id ? undefined : user.updateUser?.id,
            tokens,
            navigate,
            () => {
                setAlert({message: "Successfully revoked tokens", color: "green"});
                setSelection([]);
                if (tokens === undefined) {
                    setUser(prevState => ({
                        ...prevState,
                        user: undefined,
                        loading: false,
                        updateUser: undefined,
                        opened: false
                    }));
                }
            },
            (errors) => {
                if (hasError(errors, "DataCorruptionException")) {
                    setAlert({message: "Invalid user to update", color: "red"});
                } else if (hasError(errors, "UserNotFoundException")) {
                    setAlert({message: "Unable to find user to update", color: "red"});
                } else {
                    setAlert({message: "Error while trying to revoke tokens", color: "red"});
                }
            },
            () => {
                loadingHandlers.close();
            },
        );
    };

    return (
        <React.Fragment>
            <GlobalLoading loading={loading}/>
            <ManagedAlert size={"xs"} mt={"md"} message={alert.message} color={alert.color} onClose={() => {
                setAlert(prevState => ({...prevState, message: ""}));
            }}/>
            <Paper withBorder mt={"xl"} mb={"sm"} style={{maxHeight: "400px", overflow: "scroll"}}>
                <Table highlightOnHover maw={"100%"} layout={"fixed"}>
                    <Table.Thead className={classes.header}>
                        <Table.Tr>
                            <Table.Th style={{width: rem(40)}}>
                                <Checkbox
                                    onChange={toggleAll}
                                    checked={selection.length === user.updateUser?.tokenHashes.length ?? -1}
                                    indeterminate={selection.length > 0 && selection.length !== (user.updateUser?.tokenHashes.length ?? -1)}
                                />
                            </Table.Th>
                            <Table.Th>Token Hash</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {tokenHashes.map((item) => {
                            const selected = selection.includes(item.tokenHash);
                            return (
                                <Table.Tr key={item.id}
                                          className={cx(classes.trClick, {[classes.rowSelected]: selected})}
                                          onClick={() => toggleRow(item.tokenHash)}>
                                    <Table.Td>
                                        <Checkbox checked={selection.includes(item.tokenHash)}
                                                  style={{cursor: "pointer"}} onChange={() => {
                                        }}/>
                                    </Table.Td>
                                    <Table.Td>
                                        <div style={{wordWrap: "break-word"}}>
                                            <Text fw={500}>{item.tokenHash}</Text>
                                            <Text size="sm" c="dimmed">{item.agent}</Text>
                                            <Text size="sm"
                                                  c="dimmed">{item.expirationDate + " (Expiration date)"}</Text>
                                        </div>
                                    </Table.Td>
                                </Table.Tr>
                            );
                        })}
                        {tokenHashes.length === 0 && (
                            <Table.Tr>
                                <Table.Td colSpan={2}>
                                    <Text fs="italic">No active tokens</Text>
                                </Table.Td>
                            </Table.Tr>
                        )}
                    </Table.Tbody>
                </Table>
            </Paper>
            <Flex gap={"sm"} direction={"row"} justify={"flex-end"}>
                <Group wrap="nowrap" gap={0} className={globalClasses.control}>
                    <Button
                        className={cx(globalClasses.control, classes.button)} color={"orange"}
                        onClick={() => revokeTokens(selection)}
                        disabled={selection.length === 0 || tokenHashes.length === 0}
                    >
                        Revoke token(s)
                    </Button>
                    <Menu transitionProps={{transition: 'pop'}} position="bottom-end" withinPortal>
                        <Menu.Target>
                            <ActionIcon
                                variant="filled"
                                color={"orange"}
                                size={36}
                                className={classes.menuControl}
                                disabled={tokenHashes.length === 0}
                            >
                                <IconChevronDown style={{width: rem(16), height: rem(16)}} stroke={1.5}/>
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item
                                color={"red"}
                                leftSection={
                                    <IconTrash
                                        style={{width: rem(16), height: rem(16)}}
                                        stroke={1.5}
                                        color={"red"}
                                    />
                                }
                                onClick={() => revokeTokens(undefined)}
                            >
                                Revoke all token(s)
                            </Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Flex>
        </React.Fragment>
    );
}

export default Tokens;