import {Group, Loader} from "@mantine/core";
import React from "react";

function PageLoading() {
    return (
        <Group justify={"center"}>
            <Loader color="blue" type="dots" />
        </Group>
    );
}

export default PageLoading;