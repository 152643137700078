import React from "react";
import {LoadingOverlay} from "@mantine/core";

export interface GlobalLoadingProps {
    loading: boolean;
}

function GlobalLoading(props: GlobalLoadingProps) {
    return (
        <LoadingOverlay
            visible={props.loading}
            zIndex={1000}
            overlayProps={{radius: 'sm', blur: 2}}
            loaderProps={{type: 'dots'}}
        />
    );
}

export default GlobalLoading;