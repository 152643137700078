import React, {useEffect} from "react";
import {IconChevronDown, IconChevronUp, IconPlus, IconSearch, IconSelector} from "@tabler/icons-react";
import {
    Button,
    Center,
    CloseButton,
    Flex,
    Group, Paper,
    ScrollArea,
    Table,
    Text,
    TextInput,
    UnstyledButton
} from "@mantine/core";
import classes from "./SortedTable.module.css";
import globalClasses from "../../pages/Global.module.css";
import cx from "clsx";

interface TableHeadProps {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;
    onSort(): void;
}

export function TableHead(props: TableHeadProps) {
    const Icon = props.sorted ? (props.reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <Table.Th className={classes.th}>
            <UnstyledButton onClick={props.onSort} className={classes.control}>
                <Group justify="space-between" wrap={"nowrap"}>
                    <Text fw={500} fz="sm" style={{textTransform: "capitalize", whiteSpace: "nowrap"}}>
                        {props.children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon size={16} stroke={1.5}/>
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

export function sortData<T>(
    data: T[],
    columns: {name: string, data: (item: T) => string}[],
    filterData: (data: T[], search: string) => T[],
    payload: { sortBy: number; reversed: boolean; search: string }
) {
    const {sortBy} = payload;
    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return columns[sortBy].data(b).localeCompare(columns[sortBy].data(a));
            }
            return columns[sortBy].data(a).localeCompare(columns[sortBy].data(b));
        }),
        payload.search
    );
}

interface SortedTableProps<T> {
    columns: {name: string, data: (item: T) => string}[];
    data: T[];
    onRow(value: T): React.JSX.Element;
    onFilter(data: T[], search: string): T[];
    onNew(): void;
    onEdit(item: T): void;
    canEdit?: boolean;
    canCreate?: boolean;
    isBasic?: boolean;
}

export function SortedTable<T>(props: SortedTableProps<T>) {
    const [search, setSearch] = React.useState("");
    const [sortedData, setSortedData] = React.useState(props.data);
    const [sortBy, setSortBy] = React.useState<number>(0);
    const [reverseSortDirection, setReverseSortDirection] = React.useState(false);

    useEffect(() => {
        setSortedData(sortData(props.data, props.columns, props.onFilter, { sortBy, reversed: reverseSortDirection, search }));
        // eslint-disable-next-line
    }, [props.data]);

    const setSorting = (index: number) => {
        const reversed = index === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(index);
        setSortedData(sortData(props.data, props.columns, props.onFilter, { sortBy: index, reversed, search }));
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleSearchChangeValue(event.currentTarget.value);
    };

    const handleSearchChangeValue = (value: string) => {
        setSearch(value);
        setSortedData(sortData(props.data, props.columns, props.onFilter, { sortBy, reversed: reverseSortDirection, search: value }));
    };

    const heads = props.columns.map((value, index) => (
        <TableHead
            key={index}
            sorted={sortBy === index}
            reversed={reverseSortDirection}
            onSort={() => setSorting(index)}
        >
            {value.name}
        </TableHead>
    ));

    const rows = sortedData.map((row, index) => {
        return (
            <Table.Tr key={"row_"+index} onClick={props.canEdit ? () => props.onEdit(row) : undefined} className={cx(classes.noWrapText, props.canEdit ? globalClasses.pointer : undefined)}>
                {props.onRow(row)}
            </Table.Tr>
        );
    });

    return (
        <React.Fragment>
            <Flex
                direction={{ base: 'column', sm: 'row' }}
                gap={{ base: 'sm', sm: 'lg' }}
                mb="md"
                className={props.isBasic === true && globalClasses.hidden}
            >
                <TextInput
                    placeholder="Search by any field"
                    leftSection={<IconSearch size={16} stroke={1.5} />}
                    value={search}
                    onChange={handleSearchChange}
                    rightSection={
                        <CloseButton
                            onClick={() => handleSearchChangeValue('')}
                            style={{ display: search ? undefined : 'none' }}
                        />
                    }
                />
                <Button className={globalClasses.control} onClick={props.onNew} leftSection={<IconPlus size={16}/>} disabled={!props.canCreate}>
                    New
                </Button>
            </Flex>
            <Paper shadow="xs" radius="md" className={globalClasses.paper} withBorder>
                <ScrollArea>
                    <Table horizontalSpacing="md" verticalSpacing="xs" highlightOnHover>{/*layout="fixed"*/}
                        <Table.Tbody>
                            <Table.Tr>
                                {heads}
                            </Table.Tr>
                        </Table.Tbody>
                        <Table.Tbody>
                            {rows.length > 0 ? (
                                rows
                            ) : (
                                <Table.Tr>
                                    <Table.Td colSpan={props.columns.length}>
                                        <Text fw={500} ta="center">
                                            Nothing found
                                        </Text>
                                    </Table.Td>
                                </Table.Tr>
                            )}
                        </Table.Tbody>
                    </Table>
                </ScrollArea>
            </Paper>

        </React.Fragment>
    );
}