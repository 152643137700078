import {getOrDefault} from "../util/Util";

export default class PersonalInformation {
    firstName: string;
    preferredFirstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;

    constructor(firstName: string, preferredFirstName: string, middleName: string, lastName: string, phoneNumber: string) {
        this.firstName = firstName;
        this.preferredFirstName = preferredFirstName;
        this.middleName = middleName;
        this.lastName = lastName;
        this.phoneNumber = phoneNumber;
    }

    static singleFromJson(json: any): PersonalInformation {
        if (json === undefined || json === null) {
            return new PersonalInformation("", "", "", "", "");
        }

        return new PersonalInformation(
            getOrDefault(json["firstName"], ""),
            getOrDefault(json["preferredFirstName"], ""),
            getOrDefault(json["middleName"], ""),
            getOrDefault(json["lastName"], ""),
            getOrDefault(json["phoneNumber"], "")
        );
    }
}