import React, {useEffect} from "react";
import {Divider, Group, Select, TextInput} from "@mantine/core";
import {useUserFunctionProvider} from "../../context/UserContext";
import {UseFormReturnType} from "@mantine/form";
import {Permission} from "../../models/Permission";
import {useDisclosure} from "@mantine/hooks";
import {hasError} from "../../util/GraphQl";
import {useNavigate} from "react-router-dom";
import FormDrawer from "../formdrawer/FormDrawer";
import {useJobContextState, useJobFunctionProvider} from "../../context/JobContext";
import {useContactContextState, useContactFunctionProvider} from "../../context/ContactContext";
import {ALL_JOB_STATE_ENUM_KEYS, getJobStateFromKey} from "../../models/JobStateEnum";

interface JobFormType {
    id: string;
    name: string;
    shortName: string;
    customerId: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    addressNotes: string;
    notes: string;
    jobState: string;
}

const DEFAULT_VALUES: JobFormType = {
    id: '',
    name: '',
    shortName: '',
    customerId: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    addressNotes: '',
    notes: '',
    jobState: ''
};

interface JobDrawerProps {
    opened: boolean;
    close: () => void;
}

function JobDrawer(props: JobDrawerProps) {
    const userFunctionProvider = useUserFunctionProvider();
    const jobs = useJobContextState();
    const jobFunctionProvider = useJobFunctionProvider();
    const contacts = useContactContextState();
    const contactFunctionProvider = useContactFunctionProvider();
    const navigate = useNavigate();
    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});

    const canDelete = userFunctionProvider.hasPermission([Permission.DELETE_JOB]);
    const canCreate = userFunctionProvider.hasPermission([Permission.CREATE_JOB]);
    const canUpdate = userFunctionProvider.hasPermission([Permission.UPDATE_JOB]);
    const canSeeContact = userFunctionProvider.hasPermission([
        Permission.CREATE_CONTACT,
        Permission.DELETE_CONTACT,
        Permission.UPDATE_CONTACT,
        Permission.READ_CONTACT
    ]);

    useEffect(() => {
        loadingHandlers.open();
        contactFunctionProvider.getContacts(undefined, navigate, () => {
        }, () => {
            /*setAlert({message: "Error fetching titles", color: "red"});*/
        }, () => {
            loadingHandlers.close();
        })
    }, [jobs.updateJob]);

    const deleteJob = (form: UseFormReturnType<JobFormType, (values: JobFormType) => JobFormType>,
                       setAlert: React.Dispatch<React.SetStateAction<{ message: string, color: string }>>) => {
        loadingHandlers.open();
        setAlert({message: "", color: "red"});
        jobFunctionProvider.deleteJob(jobs.updateJob?.id, navigate, () => {
            setAlert({message: "Successfully deleted job", color: "green"});
            form.setValues(DEFAULT_VALUES);
        }, (errors) => {
            if (hasError(errors, "UserNotFoundException")) {
                setAlert({message: "Unable to find job to delete", color: "red"});
            } else {
                setAlert({message: "Error while trying to delete job", color: "red"});
            }
        }, () => {
            loadingHandlers.close();
        });
    };

    const complete = (values: JobFormType,
                      form: UseFormReturnType<JobFormType, (values: JobFormType) => JobFormType>,
                      setAlert: React.Dispatch<React.SetStateAction<{ message: string, color: string }>>) => {
        loadingHandlers.open();
        setAlert({message: "", color: "red"});
        if (jobs.updateJob === undefined) {
            jobFunctionProvider.createJob({
                data: {
                    name: values.name,
                    shortName: values.shortName,
                    customerId: values.customerId,
                    location: {
                        addressLine1: values.addressLine1,
                        addressLine2: values.addressLine1,
                        city: values.city,
                        state: values.state,
                        zipCode: values.zipCode,
                        notes: values.addressNotes,
                    },
                    notes: values.notes
                }
            }, navigate, () => {
                setAlert({message: "Successfully created job", color: "green"});
                form.reset();
            }, (errors) => {
                if (hasError(errors, "UserNotFoundException")) {
                    setAlert({message: "Unable to find user", color: "red"});
                } else if (hasError(errors, "JobAlreadyExistsException")) {
                    setAlert({message: "Job already exists", color: "red"});
                } else {
                    setAlert({message: "Error while trying to create job", color: "red"});
                }
            }, () => {
                loadingHandlers.close();
            });
        } else {
            jobFunctionProvider.updateJob({
                data: {
                    id: values.id,
                    ...(values.name !== jobs.updateJob?.name && {name: values.name}),
                    ...(values.shortName !== jobs.updateJob?.shortName && {shortName: values.shortName}),
                    ...(canSeeContact && values.customerId !== jobs.updateJob?.customerId && {customerId: values.customerId}),
                    location: {
                        ...(values.addressLine1 !== jobs.updateJob?.location?.addressLine1 && {addressLine1: values.addressLine1}),
                        ...(values.addressLine2 !== jobs.updateJob?.location?.addressLine2 && {addressLine2: values.addressLine2}),
                        ...(values.city !== jobs.updateJob?.location?.city && {city: values.city}),
                        ...(values.state !== jobs.updateJob?.location?.state && {state: values.state}),
                        ...(values.zipCode !== jobs.updateJob?.location?.zipCode && {zipCode: values.zipCode}),
                        ...(values.notes !== jobs.updateJob?.location?.notes && {notes: values.notes}),
                    },
                    ...(values.notes !== jobs.updateJob?.notes && {notes: values.notes}),
                },
                ...(values.jobState !== jobs.updateJob?.states?.at(-1)?.state && {newState: values.jobState}),
            }, navigate, () => {
                setAlert({message: "Successfully updated job", color: "green"});
            }, (errors) => {
                if (hasError(errors, "JobNotFoundException")) {
                    setAlert({message: "Unable to find job to update", color: "red"});
                } else if (hasError(errors, "UserNotFoundException")) {
                    setAlert({message: "Unable to find user", color: "red"});
                } else {
                    setAlert({message: "Error while trying to update job", color: "red"});
                }

            }, () => {
                form.resetDirty();
                form.resetTouched();
                loadingHandlers.close();
            });
        }
    };

    return (
        <FormDrawer
            opened={props.opened}
            onClose={props.close}
            title={"job"}
            titleName={jobs.updateJob?.name}
            defaultValues={DEFAULT_VALUES}
            onComplete={complete}
            onDelete={deleteJob}
            canDelete={canDelete}
            canCreate={canCreate}
            isUpdate={jobs.updateJob !== undefined}
            updateValue={{
                id: jobs.updateJob?.id ?? '',
                name: jobs.updateJob?.name ?? '',
                shortName: jobs.updateJob?.shortName ?? '',
                customerId: jobs.updateJob?.customerId ?? '',
                addressLine1: jobs.updateJob?.location?.addressLine1 ?? '',
                addressLine2: jobs.updateJob?.location?.addressLine2 ?? '',
                city: jobs.updateJob?.location?.city ?? '',
                state: jobs.updateJob?.location?.state ?? '',
                zipCode: jobs.updateJob?.location?.zipCode ?? '',
                addressNotes: jobs.updateJob?.location?.notes ?? '',
                notes: jobs.updateJob?.notes ?? '',
                jobState: jobs.updateJob?.states?.at(-1)?.state ?? ''
            }}
            form={(form) => (
                <>
                    <TextInput
                        type={"text"}
                        label="Name"
                        placeholder={"Name"}
                        required
                        {...form.getInputProps('name')}
                        disabled={jobs.updateJob ? !canUpdate : !canCreate}
                    />
                    <TextInput
                        type={"text"}
                        label="Short name"
                        placeholder={"Short name"}
                        mt={"md"}
                        {...form.getInputProps('shortName')}
                        disabled={jobs.updateJob ? !canUpdate : !canCreate}
                    />
                    <Select
                        label="Contact"
                        placeholder="Contact"
                        data={contacts.contacts.map(item => ({value: item.id, label: item.getPreferredName()}))}
                        nothingFoundMessage="Nothing found..."
                        searchable
                        mt={"md"}
                        {...form.getInputProps('customerId')}
                        disabled={jobs.updateJob ? !canUpdate : !canCreate || !canSeeContact}
                    />
                    <TextInput
                        type={"text"}
                        label="Notes"
                        mt="md"
                        placeholder={"Notes"}
                        {...form.getInputProps('notes')}
                        disabled={jobs.updateJob ? !canUpdate : !canCreate}
                    />
                    <Divider my={"md"} label={"Location"}/>
                    <TextInput
                        type={"text"}
                        label="Address line 1"
                        placeholder={"Address line 1"}
                        mt="md"
                        {...form.getInputProps('addressLine1')}
                        disabled={jobs.updateJob ? !canUpdate : !canCreate}
                    />
                    <TextInput
                        type={"text"}
                        label="Address line 2"
                        placeholder={"Address line 2"}
                        mt="md"
                        {...form.getInputProps('addressLine2')}
                        disabled={jobs.updateJob ? !canUpdate : !canCreate}
                    />
                    <Group justify="space-between" mt="md" grow wrap={"nowrap"}>
                        <TextInput
                            type={"text"}
                            label="City"
                            placeholder={"City"}
                            {...form.getInputProps('city')}
                            disabled={jobs.updateJob ? !canUpdate : !canCreate}
                        />
                        <TextInput
                            type={"text"}
                            label="State"
                            placeholder={"State"}
                            {...form.getInputProps('state')}
                            disabled={jobs.updateJob ? !canUpdate : !canCreate}
                        />
                        <TextInput
                            type={"text"}
                            label="Zip"
                            placeholder={"Zip code"}
                            {...form.getInputProps('zipCode')}
                            disabled={jobs.updateJob ? !canUpdate : !canCreate}
                        />
                    </Group>
                    <TextInput
                        type={"text"}
                        label="Notes"
                        mt="md"
                        placeholder={"Notes"}
                        {...form.getInputProps('addressNotes')}
                        disabled={jobs.updateJob ? !canUpdate : !canCreate}
                    />
                    <Divider my={"md"} label={"Job state"}/>
                    <Select
                        placeholder="State"
                        data={ALL_JOB_STATE_ENUM_KEYS.map(key => ({value: key, label: getJobStateFromKey(key)}))}
                        nothingFoundMessage="Nothing found..."
                        searchable
                        disabled={jobs.updateJob === undefined || !canUpdate}
                        {...form.getInputProps('jobState')}
                    />
                </>
            )}
            loading={loading}
        />
    );
}

export default JobDrawer;