import {useDisclosure} from '@mantine/hooks';
import {ActionIcon, AppShell, Box, Burger, Code, Group, rem, ScrollArea, Text, useMantineTheme} from '@mantine/core';
import {Outlet, useLocation} from "react-router-dom";
import {
    IconBuildingSkyscraper,
    IconClock,
    IconListDetails,
    IconShieldLock,
    IconUser,
    IconUsers,
    IconX,
} from '@tabler/icons-react';
import classes from "./App.module.css";
import React, {useEffect} from "react";
import {LinkGroup} from "../../components/navlinkgroups/NavLinkGroups";
import {UserButton} from "../../components/userbutton/UserButton";
import {
    ROUTE_APP_CONTACTS,
    ROUTE_APP_JOBS,
    ROUTE_APP_PERMISSION_GROUPS,
    ROUTE_APP_TIME,
    ROUTE_APP_TITLES,
    ROUTE_APP_USERS
} from "../../Constants";
import UserDrawer from "../../components/userdrawer/UserDrawer";
import {useUserContextState, useUserContextUpdater, useUserFunctionProvider} from "../../context/UserContext";
import PageTitle from "../../components/PageTitle";
import PermissionGroupDrawer from "../../components/permissiongroupdrawer/PermissionGroupDrawer";
import {usePermissionGroupContextState, usePermissionGroupContextUpdater} from "../../context/PermissionGroupContext";
import TitleDrawer from "../../components/titledrawer/TitleDrawer";
import {useTitleContextState, useTitleContextUpdater} from "../../context/TitleContext";
import ContactDrawer from "../../components/contactdrawer/ContactDrawer";
import {useContactContextState, useContactContextUpdater} from "../../context/ContactContext";
import JobDrawer from "../../components/jobdrawer/JobDrawer";
import {useJobContextState, useJobContextUpdater} from "../../context/JobContext";
import {CAN_SEE_TIME} from "./time/Time";
import {CAN_SEE_CONTACTS} from "./contacts/Contacts";
import {CAN_SEE_JOBS} from "./jobs/Jobs";
import {CAN_SEE_USERS} from "./users/Users";
import {CAN_SEE_TITLES} from "./titles/Titles";
import {CAN_SEE_PERMISSION_GROUPS} from "./permissiongroups/PermissionGroups";
import {JobBoxIcon} from "../../components/JobBoxIcon";

const sidebarItems = [
    {label: 'Time', icon: IconClock, link: ROUTE_APP_TIME, permissions: CAN_SEE_TIME},
    {label: 'Jobs', icon: IconBuildingSkyscraper, link: ROUTE_APP_JOBS, permissions: CAN_SEE_JOBS},
    {label: 'Users', icon: IconUser, link: ROUTE_APP_USERS, permissions: CAN_SEE_USERS},
    {label: 'Contacts', icon: IconUsers, link: ROUTE_APP_CONTACTS, permissions: CAN_SEE_CONTACTS},
    {label: 'Titles', icon: IconListDetails, link: ROUTE_APP_TITLES, permissions: CAN_SEE_TITLES},
    {
        label: 'Permissions',
        icon: IconShieldLock,
        link: ROUTE_APP_PERMISSION_GROUPS,
        permissions: CAN_SEE_PERMISSION_GROUPS
    },
];

function App() {
    const theme = useMantineTheme();
    const location = useLocation();
    const user = useUserContextState();
    const setUser = useUserContextUpdater();
    const userFunctionProvider = useUserFunctionProvider();
    const permissionGroups = usePermissionGroupContextState();
    const setPermissionGroup = usePermissionGroupContextUpdater();
    const titles = useTitleContextState();
    const setTitles = useTitleContextUpdater();
    const contacts = useContactContextState();
    const setContacts = useContactContextUpdater();
    const jobs = useJobContextState();
    const setJobs = useJobContextUpdater();
    const [opened, {close, toggle}] = useDisclosure();
    const links = sidebarItems
        .filter(item => userFunctionProvider.hasPermission(item.permissions))
        .map((item) => <LinkGroup {...item} key={item.label}/>);

    const pathItems = location.pathname.split('/');

    useEffect(() => {
        close();
        // eslint-disable-next-line
    }, [location]);

    return (
        <AppShell
            navbar={{width: 300, breakpoint: 'sm', collapsed: {mobile: !opened}}}
            padding={rem("24px")}
        >
            <AppShell.Header className={classes.header} hiddenFrom={"xs"} withBorder={false}>
                <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" ml={16}/>
            </AppShell.Header>

            <AppShell.Navbar className={classes.navbar}>
                <AppShell.Section className={classes.navbarHeader}>
                    <Group justify="space-between">
                        <Group gap={12} align={"center"}>
                            <JobBoxIcon size={30} color={theme.colors[theme.primaryColor][5]}/>
                            <Text fw={600} fz={24} lh={1}>JobBox</Text>
                            <Box><Code fw={700}>v0.0.1</Code></Box>
                        </Group>

                        <ActionIcon variant="subtle" color="gray" hiddenFrom={"xs"} onClick={toggle}>
                            <IconX style={{width: '70%', height: '70%'}} stroke={1.5}/>
                        </ActionIcon>
                    </Group>
                </AppShell.Section>
                <AppShell.Section grow component={ScrollArea} className={classes.links}>
                    <div className={classes.linksInner}>
                        {links}
                    </div>
                </AppShell.Section>
                <AppShell.Section className={classes.footer}>
                    <UserButton/>
                </AppShell.Section>
            </AppShell.Navbar>

            <AppShell.Main className={classes.main}>
                <PageTitle text={pathItems.at(pathItems.length - 1) ?? "Unknown"}/>
                <Outlet/>
            </AppShell.Main>

            <UserDrawer opened={user.opened} close={() => {
                setUser(prevState => {
                    return {...prevState, opened: false}
                })
            }}/>
            <PermissionGroupDrawer opened={permissionGroups.opened} close={() => {
                setPermissionGroup(prevState => {
                    return {...prevState, opened: false}
                })
            }}/>
            <TitleDrawer opened={titles.opened} close={() => {
                setTitles(prevState => {
                    return {...prevState, opened: false}
                })
            }}/>
            <ContactDrawer opened={contacts.opened} close={() => {
                setContacts(prevState => {
                    return {...prevState, opened: false}
                })
            }}/>
            <JobDrawer opened={jobs.opened} close={() => {
                setJobs(prevState => {
                    return {...prevState, opened: false}
                })
            }}/>
        </AppShell>
    );
}

export default App;