import React, {useEffect} from "react";
import {Badge, Group, rem, Table, Text} from "@mantine/core";
import {Permission} from "../../../models/Permission";
import {useUserFunctionProvider} from "../../../context/UserContext";
import {useNavigate} from "react-router-dom";
import {SortedTable} from "../../../components/sortedtable/SortedTable";
import {ROUTE_APP_TIME} from "../../../Constants";
import ManagedAlert from "../../../components/ManagedAlert";
import {useJobContextState, useJobContextUpdater, useJobFunctionProvider} from "../../../context/JobContext";
import {Job} from "../../../models/Job";
import {useContactContextState, useContactFunctionProvider} from "../../../context/ContactContext";
import {getJobStateFromKey} from "../../../models/JobStateEnum";
import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import {getTitle, multiLoad, MultiLoadFunction} from "../../../util/Util";
import PageLoading from "../../../components/PageLoading";

export const CAN_SEE_JOBS = [
    Permission.READ_JOB,
    Permission.UPDATE_JOB,
    Permission.CREATE_JOB,
    Permission.DELETE_JOB
];

function Jobs() {
    useDocumentTitle(getTitle("Jobs"));
    const jobs = useJobContextState();
    const setJobs = useJobContextUpdater();
    const userFunctionProvider = useUserFunctionProvider();
    const jobFunctionProvider = useJobFunctionProvider();
    const contacts = useContactContextState();
    const contactFunctionProvider = useContactFunctionProvider();
    const navigate = useNavigate();

    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});

    const canSeeContacts = userFunctionProvider.hasPermission([
        Permission.READ_CONTACT,
        Permission.UPDATE_CONTACT,
        Permission.CREATE_CONTACT,
        Permission.DELETE_CONTACT,
    ]);
    const canSeeJobs = userFunctionProvider.hasPermission(CAN_SEE_JOBS);
    const canEditJobs = userFunctionProvider.hasPermission([
        Permission.UPDATE_JOB,
        Permission.DELETE_JOB,
        Permission.CREATE_JOB
    ]);
    const canCreateJobs = userFunctionProvider.hasPermission([
        Permission.CREATE_JOB,
    ]);

    const getContacts: MultiLoadFunction = (onComplete) => {
        if (!canSeeContacts) {
            onComplete();
            return;
        }

        contactFunctionProvider.getContacts(undefined, navigate, () => {
        }, () => {
            setAlert({message: "Error getting contacts", color: "red"});
        }, () => {
            onComplete();
        });
    };
    const getJobs: MultiLoadFunction = (onComplete) => {
        jobFunctionProvider.getJobs(undefined, navigate, () => {
        }, () => {
            setAlert({message: "Error getting jobs", color: "red"});
        }, () => {
            onComplete();
        });
    };

    useEffect(() => {
        if (!canSeeJobs) {
            navigate(ROUTE_APP_TIME);
        }

        multiLoad([getJobs, getContacts], loadingHandlers.open, loadingHandlers.close);
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <PageLoading/>
    ) : (
        <React.Fragment>
            <ManagedAlert size={"xs"} mb={"md"} message={alert.message} color={alert.color} onClose={() => {
                setAlert(prevState => ({...prevState, message: ""}));
            }}/>
            <SortedTable
                columns={[
                    {name: "name", data: (item: Job) => item.name},
                    {name: "short name", data: (item: Job) => item.shortName},
                    {
                        name: "contact",
                        data: (item: Job) => canSeeContacts ? contacts.contacts.find(c => c.id === item.customerId)?.getPreferredName() ?? "" : ""
                    },
                    {name: "location", data: (item: Job) => item.location?.toSingleLine() ?? ""},
                    {name: "state", data: (item: Job) => getJobStateFromKey(item.states?.at(-1)?.state)}
                ]}
                data={jobs.jobs}
                onRow={item => {
                    const contact = contacts.contacts.find(c => c.id === item.customerId);
                    return (
                        <React.Fragment>
                            <Table.Td>{item.name}</Table.Td>
                            <Table.Td>{item.shortName}</Table.Td>
                            <Table.Td>
                                {canSeeContacts && contact !== undefined ? (
                                    <Group wrap={"nowrap"}>
                                        <Text>{contact.getPreferredName()}</Text>
                                        <Badge color={contact.isBusiness ? "pink" : "cyan"} size="xs"
                                               radius="xs" style={{minWidth: rem(60)}}>
                                            {contact.isBusiness ? "Business" : "Person"}
                                        </Badge>
                                    </Group>

                                ) : (
                                    <Text fw={500} size={"sm"}>[ REDACTED ]</Text>
                                )}
                            </Table.Td>
                            <Table.Td>{item.location?.toSingleLine() ?? ""}</Table.Td>
                            <Table.Td>
                                <Badge color={"yellow"} size="xs" radius="xs" style={{minWidth: rem(60)}}>
                                    {getJobStateFromKey(item.states?.at(-1)?.state)}
                                </Badge>
                            </Table.Td>
                        </React.Fragment>
                    );
                }}
                onFilter={(data, search) => {
                    const query = search.toLowerCase().trim();
                    return data.filter(value => {
                        const values = [
                            value.name,
                            value.shortName,
                            canSeeContacts ? contacts.contacts.find(c => c.id === value.customerId)?.getPreferredName() ?? "" : "",
                            getJobStateFromKey(value.states?.at(-1)?.state)
                        ];

                        return values.some(key => key.toLowerCase().includes(query));
                    });
                }}
                canEdit={canEditJobs}
                canCreate={canCreateJobs}
                onNew={() => setJobs(prevState => {
                    return {...prevState, updateJob: undefined, opened: true}
                })}
                onEdit={item => setJobs(prevState => {
                    return {...prevState, updateJob: item, opened: true}
                })}
            />
        </React.Fragment>
    );
}

export default Jobs;