// noinspection GraphQLUnresolvedReference

import {GraphQLErrors} from "@apollo/client/errors";
import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo} from "react";
import {Title} from "../models/Title";
import {ApolloError, gql, useLazyQuery, useMutation} from "@apollo/client";
import {useUserContextState, useUserContextUpdater} from "./UserContext";
import {onUnauthenticatedError} from "../util/GraphQl";
import {NavigateFunction} from "react-router-dom";
import {PermissionGroup} from "../models/PermissionGroup";
import {User} from "../models/User";

export interface PermissionGroupContextStateType {
    updatePermissionGroup: PermissionGroup | undefined,
    opened: boolean,
    permissionGroups: PermissionGroup[]
}

export interface PermissionGroupFunctionProvider {
    getPermissionGroups(navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    createPermissionGroup(data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    updatePermissionGroup(data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    deletePermissionGroup(id: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;
}
const DEFAULT_STATE = {
    updatePermissionGroup: undefined,
    opened: false,
    permissionGroups: []
};
export const PermissionGroupContextState = createContext<PermissionGroupContextStateType>(DEFAULT_STATE);
export const PermissionGroupContextUpdater = createContext<Dispatch<SetStateAction<PermissionGroupContextStateType>>>(() => {
    return;
});
export const PermissionGroupFunctionProviderContext = createContext<PermissionGroupFunctionProvider>({} as PermissionGroupFunctionProvider);

export function usePermissionGroupContextState() {
    return useContext(PermissionGroupContextState);
}

export function usePermissionGroupContextUpdater() {
    return useContext(PermissionGroupContextUpdater);
}

export function usePermissionGroupFunctionProvider() {
    return useContext(PermissionGroupFunctionProviderContext);
}

const GET_PERMISSION_GROUP = gql`
    query permissionGroups {
        permissionGroups {
            ...FullPermissionGroup
        }
    }
`;
const CREATE_PERMISSION_GROUP = gql`
    mutation createPermissionGroup($data: PermissionGroupInput!) {
        createPermissionGroup(data: $data) {
            ...FullPermissionGroup
        }
    }
`;
const UPDATE_PERMISSION_GROUP = gql`
    mutation updatePermissionGroup($data: PermissionGroupInput!) {
        updatePermissionGroup(data: $data) {
            ...FullPermissionGroup
        }
    }
`;
const DELETE_PERMISSION_GROUP = gql`
    mutation deletePermissionGroup($id: ID!) {
        deletePermissionGroup(id: $id)
    }
`;

interface PermissionGroupContextProviderProps {
    children?: ReactNode;
}

export function PermissionGroupContextProvider(props: PermissionGroupContextProviderProps) {
    const user = useUserContextState();
    const setUser = useUserContextUpdater();

    const [permissionGroups, setPermissionGroups] = React.useState<PermissionGroupContextStateType>(DEFAULT_STATE);

    useEffect(() => {
        if(user.user === undefined) {
            setPermissionGroups(DEFAULT_STATE);
        }
    }, [user.user]);

    const [getPermissionGroups] = useLazyQuery(GET_PERMISSION_GROUP);
    const [createPermissionGroup] = useMutation(CREATE_PERMISSION_GROUP);
    const [updatePermissionGroup] = useMutation(UPDATE_PERMISSION_GROUP);
    const [deletePermissionGroup] = useMutation(DELETE_PERMISSION_GROUP);

    const sharedUpdate = (json: any) => {
        const item = PermissionGroup.singleFromJson(json);
        setPermissionGroups((prevState) => {
            const newItems = [...prevState.permissionGroups];
            const index = newItems.findIndex(v => v.id === item.id);
            if (index === -1) {
                newItems.push(item);
            } else {
                newItems[index] = item;
            }
            return {...prevState, permissionGroups: newItems, updatePermissionGroup: index === -1 ? prevState.updatePermissionGroup : item};
        });
    };

    const funcObj = useMemo(() => {
        return {
            getPermissionGroups: (navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                getPermissionGroups({
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.error !== undefined || r.data === undefined) {
                        onUnauthenticatedError(r.error, setUser, navigate);
                        onError?.(r.error?.graphQLErrors ?? []);
                        return;
                    }

                    setPermissionGroups(prevState => ({...prevState, permissionGroups: PermissionGroup.arrayFromJson(r.data.permissionGroups) ?? []}));
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            createPermissionGroup: (data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                createPermissionGroup({
                    variables: data,
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    sharedUpdate(r.data.createPermissionGroup);
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            updatePermissionGroup: (data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                updatePermissionGroup({
                    variables: data,
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    sharedUpdate(r.data.updatePermissionGroup);
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            deletePermissionGroup: (id: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                deletePermissionGroup({
                    variables: {
                        id: id ?? ""
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    setPermissionGroups((prevState) => {
                        const newItems = [...prevState.permissionGroups];
                        const index = newItems.findIndex(v => v.id === id);
                        if (index !== -1) {
                            newItems.splice(index, 1);
                        }
                        return {...prevState, updatePermissionGroup: undefined, permissionGroups: newItems};
                    });
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            }
        };
    }, []);

    return (
        <PermissionGroupContextState.Provider value={permissionGroups}>
            <PermissionGroupContextUpdater.Provider value={setPermissionGroups}>
                <PermissionGroupFunctionProviderContext.Provider value={funcObj}>
                    {props.children}
                </PermissionGroupFunctionProviderContext.Provider>
            </PermissionGroupContextUpdater.Provider>
        </PermissionGroupContextState.Provider>
    );
}