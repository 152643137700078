import React, {useEffect, useMemo} from "react";
import cx from 'clsx';
import {
    ActionIcon,
    Anchor,
    Box,
    Button,
    Center,
    Checkbox,
    Divider,
    Drawer, Flex,
    Group, Menu, Paper,
    rem, ScrollArea,
    Select, Table,
    Tabs,
    Text,
    TextInput
} from "@mantine/core";
import {APP_NAME, EMAIL_PLACEHOLDER, ROUTE_LOGIN, UUID_PLACEHOLDER} from "../../Constants";
import {useUserContextState, useUserContextUpdater, useUserFunctionProvider} from "../../context/UserContext";
import {
    IconArrowLeft,
    IconBracketsAngle,
    IconChevronDown,
    IconFingerprint,
    IconFingerprintOff, IconTrash,
    IconUser
} from "@tabler/icons-react";
import {useForm} from "@mantine/form";
import globalClasses from "../../pages/Global.module.css";
import PermissionGroupInput from "../PermissionGroupInput";
import {Permission} from "../../models/Permission";
import GlobalLoading from "../GlobalLoading";
import ManagedAlert from "../ManagedAlert";
import {useDisclosure} from "@mantine/hooks";
import {hasError} from "../../util/GraphQl";
import {useNavigate} from "react-router-dom";
import classes from "./UserDrawer.module.css";
import TokenHash from "../../models/TokenHash";
import Metadata from "./metadata/Metadata";
import RevokedTokens from "./revokedtokens/RevokedTokens";
import Tokens from "./tokens/Tokens";
import UserInfo from "./userInfo/UserInfo";

interface UserDrawerProps {
    opened: boolean;
    close: () => void;
}

function UserDrawer(props: UserDrawerProps) {
    const user = useUserContextState();
    const userFunctionProvider = useUserFunctionProvider();
    const navigate = useNavigate();
    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});

    const canRevokeTokens = userFunctionProvider.hasPermission([Permission.REVOKE_OTHERS_TOKENS]);

    const logout = () => {
        userFunctionProvider.logout(navigate, () => {
            setAlert({message: "Successfully logged out", color: "green"});
        }, (errors) => {
            if (hasError(errors, "DataCorruptionException")) {
                setAlert({message: "Invalid user to update", color: "red"});
            } else {
                setAlert({message: "Error while trying to logout", color: "red"});
            }

        }, () => {
            loadingHandlers.close();
        });
    };

    return (
        <Drawer
            opened={props.opened}
            onClose={props.close}
            title={
                <Box style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "8px"}}>
                    <Text size={"lg"} fw={500}>{APP_NAME} Account</Text>
                    <Divider variant={"solid"} orientation="vertical" size={"sm"} my={2}/>
                    <Text size={"sm"}>{user.updateUser?.getPreferredName()}</Text>
                </Box>
            }
            position={"right"}
            styles={{
                content: {
                    display: "flex",
                    flexDirection: "column"
                },
                body: {
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    justifyContent: "space-between"
                }
            }}
            overlayProps={{backgroundOpacity: 0.5, blur: 2}}
        >
            <GlobalLoading loading={loading}/>
            <ManagedAlert size={"xs"} mt={"md"} message={alert.message} color={alert.color} onClose={() => {
                setAlert(prevState => ({...prevState, message: ""}));
            }}/>
            <Tabs radius="xs" defaultValue="account">
                <Tabs.List>
                    <Tabs.Tab value={"account"} leftSection={<IconUser size={12}/>} defaultChecked>
                        Account
                    </Tabs.Tab>
                    <Tabs.Tab value={"access_token"} leftSection={<IconFingerprint size={12}/>}
                              disabled={user.updateUser === undefined || (!canRevokeTokens && user.user?.id !== user.updateUser.id)}>
                        Access tokens
                    </Tabs.Tab>
                    <Tabs.Tab value={"revoked_token"} leftSection={<IconFingerprintOff size={12}/>}
                              disabled={user.updateUser === undefined || (!canRevokeTokens && user.user?.id !== user.updateUser.id)}>
                        Revoked tokens
                    </Tabs.Tab>
                    <Tabs.Tab value={"meta_data"} leftSection={<IconBracketsAngle size={12}/>}
                              disabled={user.updateUser === undefined}>
                        Meta-data
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="account">
                    <UserInfo/>
                </Tabs.Panel>
                <Tabs.Panel value="access_token">
                    <Tokens/>
                </Tabs.Panel>
                <Tabs.Panel value="revoked_token">
                    <RevokedTokens/>
                </Tabs.Panel>
                <Tabs.Panel value="meta_data">
                    <Metadata/>
                </Tabs.Panel>
            </Tabs>
            {user.user?.id === user.updateUser?.id && (
                <Button fullWidth color={"red"} onClick={logout}>Logout</Button>
            )}
        </Drawer>
    );
}

export default UserDrawer;