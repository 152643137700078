import React from "react";
import {MultiSelect, TextInput} from "@mantine/core";
import {useUserFunctionProvider} from "../../context/UserContext";
import {UseFormReturnType} from "@mantine/form";
import {ALL_PERMISSIONS, Permission, permissionToName} from "../../models/Permission";
import {useDisclosure} from "@mantine/hooks";
import {hasError} from "../../util/GraphQl";
import {useNavigate} from "react-router-dom";
import {usePermissionGroupContextState, usePermissionGroupFunctionProvider} from "../../context/PermissionGroupContext";
import FormDrawer from "../formdrawer/FormDrawer";

interface PermissionGroupFormType {
    id: string;
    name: string;
    permissions: string[];
}

const DEFAULT_VALUES: PermissionGroupFormType = {
    id: '',
    name: '',
    permissions: new Array<string>(),
};

interface PermissionGroupDrawerProps {
    opened: boolean;
    close: () => void;
}

function PermissionGroupDrawer(props: PermissionGroupDrawerProps) {
    const userFunctionProvider = useUserFunctionProvider();
    const permissionGroups = usePermissionGroupContextState();
    const permissionGroupFunctionProvider = usePermissionGroupFunctionProvider();
    const navigate = useNavigate();
    const [loading, loadingHandlers] = useDisclosure(false);

    const canDelete = userFunctionProvider.hasPermission([Permission.DELETE_PERMISSION_GROUPS]);
    const canCreate = userFunctionProvider.hasPermission([Permission.CREATE_PERMISSION_GROUPS]);
    const canUpdate = userFunctionProvider.hasPermission([Permission.UPDATE_PERMISSION_GROUPS]);

    const deletePermGroup = (form: UseFormReturnType<PermissionGroupFormType, (values: PermissionGroupFormType) => PermissionGroupFormType>,
                             setAlert: React.Dispatch<React.SetStateAction<{ message: string, color: string }>>) => {
        loadingHandlers.open();
        setAlert({message: "", color: "red"});
        permissionGroupFunctionProvider.deletePermissionGroup(permissionGroups.updatePermissionGroup?.id, navigate, () => {
            setAlert({message: "Successfully deleted permission group", color: "green"});
            form.setValues(DEFAULT_VALUES);
        }, (errors) => {
            if (hasError(errors, "UserNotFoundException")) {
                setAlert({message: "Unable to find permission group to delete", color: "red"});
            } else {
                setAlert({message: "Error while trying to delete permission group", color: "red"});
            }
        }, () => {
            loadingHandlers.close();
        });
    };

    const complete = (values: PermissionGroupFormType,
                      form: UseFormReturnType<PermissionGroupFormType, (values: PermissionGroupFormType) => PermissionGroupFormType>,
                      setAlert: React.Dispatch<React.SetStateAction<{ message: string, color: string }>>) => {
        loadingHandlers.open();
        setAlert({message: "", color: "red"});
        if (permissionGroups.updatePermissionGroup === undefined) {
            permissionGroupFunctionProvider.createPermissionGroup({
                data: {
                    name: values.name,
                    permissions: values.permissions.map(item => parseInt(item))
                }
            }, navigate, () => {
                setAlert({message: "Successfully created permission group", color: "green"});
                form.reset();
            }, (errors) => {
                if (hasError(errors, "UserNotFoundException")) {
                    setAlert({message: "Unable to find user", color: "red"});
                } else if (hasError(errors, "PermissionGroupAlreadyExistsException")) {
                    setAlert({message: "Permission group already exists", color: "red"});
                } else {
                    setAlert({message: "Error while trying to create permission group", color: "red"});
                }
            }, () => {
                loadingHandlers.close();
            });
        } else {
            const permissionsEqual = values.permissions.length === permissionGroups.updatePermissionGroup?.permissions.length && values.permissions.map(item => parseInt(item))
                .every((v, i) => v === permissionGroups.updatePermissionGroup?.permissions[i]);
            permissionGroupFunctionProvider.updatePermissionGroup({
                data: {
                    id: values.id,
                    ...(values.name !== permissionGroups.updatePermissionGroup?.name && {name: values.name}),
                    ...(!permissionsEqual && {permissions: values.permissions.map(item => parseInt(item))}),
                }
            }, navigate, () => {
                setAlert({message: "Successfully updated permission group", color: "green"});
                userFunctionProvider.getPermissions(navigate, () => {
                }, () => {
                    setAlert({message: "Error while trying to fetch user permissions", color: "red"});
                }, () => {
                });
            }, (errors) => {
                if (hasError(errors, "PermissionGroupNotFoundException")) {
                    setAlert({message: "Unable to find permission group to update", color: "red"});
                } else if (hasError(errors, "UserNotFoundException")) {
                    setAlert({message: "Unable to find user", color: "red"});
                } else {
                    setAlert({message: "Error while trying to update permission group", color: "red"});
                }

            }, () => {
                form.resetDirty();
                form.resetTouched();
                loadingHandlers.close();
            });
        }
    };

    return (
        <FormDrawer
            opened={props.opened}
            onClose={props.close}
            title={"permission group"}
            titleName={permissionGroups.updatePermissionGroup?.name}
            defaultValues={DEFAULT_VALUES}
            onComplete={complete}
            onDelete={deletePermGroup}
            canDelete={canDelete}
            canCreate={canCreate}
            isUpdate={permissionGroups.updatePermissionGroup !== undefined}
            updateValue={{
                id: permissionGroups.updatePermissionGroup?.id ?? '',
                name: permissionGroups.updatePermissionGroup?.name ?? '',
                permissions: permissionGroups.updatePermissionGroup?.permissions.map(item => item.toString()) ?? [],
            }}
            form={(form) => (<>
                <TextInput
                    type={"text"}
                    label="Name"
                    placeholder={"Name"}
                    required
                    {...form.getInputProps('name')}
                    disabled={permissionGroups.updatePermissionGroup ? !canUpdate : !canCreate}
                />
                <MultiSelect
                    label={"Permissions"}
                    placeholder="Pick value"
                    mt={"md"}
                    data={ALL_PERMISSIONS.map(item => ({value: item.toString(), label: permissionToName(item) ?? ""}))}
                    checkIconPosition="left"
                    searchable
                    nothingFoundMessage="Nothing found..."
                    clearable
                    {...form.getInputProps('permissions')}
                    disabled={permissionGroups.updatePermissionGroup ? !canUpdate : !canCreate}
                />
            </>)}
            loading={loading}
        />
    );
}

export default PermissionGroupDrawer;