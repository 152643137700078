export enum Permission {
    ADMIN = 0,
    CREATE_PERMISSION_GROUPS = 1,
    READ_PERMISSION_GROUPS = 2,
    UPDATE_PERMISSION_GROUPS = 3,
    DELETE_PERMISSION_GROUPS = 4,
    CREATE_EMPLOYEES = 5,
    READ_EMPLOYEES = 6,
    UPDATE_EMPLOYEES = 7,
    DELETE_EMPLOYEES = 8,
    CREATE_TITLE = 9,
    READ_TITLE = 10,
    UPDATE_TITLE = 11,
    DELETE_TITLE = 12,
    CREATE_CONTACT = 13,
    READ_CONTACT = 14,
    UPDATE_CONTACT = 15,
    DELETE_CONTACT = 16,
    CREATE_JOB = 17,
    READ_JOB = 18,
    UPDATE_JOB = 19,
    DELETE_JOB = 21,
    SUBMIT_TIME = 23,
    READ_OTHERS_TIME = 24,
    UPDATE_OTHERS_TIME = 25,
    REVOKE_OTHERS_TOKENS = 26,
    SEND_INVITE_EMAIL = 27
}

export const ALL_PERMISSIONS = [
    Permission.ADMIN,
    Permission.CREATE_PERMISSION_GROUPS,
    Permission.READ_PERMISSION_GROUPS,
    Permission.UPDATE_PERMISSION_GROUPS,
    Permission.DELETE_PERMISSION_GROUPS,
    Permission.CREATE_EMPLOYEES,
    Permission.READ_EMPLOYEES,
    Permission.UPDATE_EMPLOYEES,
    Permission.DELETE_EMPLOYEES,
    Permission.CREATE_TITLE,
    Permission.READ_TITLE,
    Permission.UPDATE_TITLE,
    Permission.DELETE_TITLE,
    Permission.CREATE_CONTACT,
    Permission.READ_CONTACT,
    Permission.UPDATE_CONTACT,
    Permission.DELETE_CONTACT,
    Permission.CREATE_JOB,
    Permission.READ_JOB,
    Permission.UPDATE_JOB,
    Permission.DELETE_JOB,
    Permission.SUBMIT_TIME,
    Permission.READ_OTHERS_TIME,
    Permission.UPDATE_OTHERS_TIME
];

export const PERMISSION_MAP = [
    {key: Permission.ADMIN, value: "Admin"},
    {key: Permission.CREATE_PERMISSION_GROUPS, value: "Create permission groups"},
    {key: Permission.READ_PERMISSION_GROUPS, value: "Read permission groups"},
    {key: Permission.UPDATE_PERMISSION_GROUPS, value: "Update permission groups"},
    {key: Permission.DELETE_PERMISSION_GROUPS, value: "Delete permission groups"},
    {key: Permission.CREATE_EMPLOYEES, value: "Create Users"},
    {key: Permission.READ_EMPLOYEES, value: "Read Users"},
    {key: Permission.UPDATE_EMPLOYEES, value: "Update Users"},
    {key: Permission.DELETE_EMPLOYEES, value: "Delete Users"},
    {key: Permission.CREATE_TITLE, value: "Create titles"},
    {key: Permission.READ_TITLE, value: "Read titles"},
    {key: Permission.UPDATE_TITLE, value: "Update titles"},
    {key: Permission.DELETE_TITLE, value: "Delete titles"},
    {key: Permission.CREATE_CONTACT, value: "Create contacts"},
    {key: Permission.READ_CONTACT, value: "Read contacts"},
    {key: Permission.UPDATE_CONTACT, value: "Update contacts"},
    {key: Permission.DELETE_CONTACT, value: "Delete contacts"},
    {key: Permission.CREATE_JOB, value: "Create jobs"},
    {key: Permission.READ_JOB, value: "Read jobs"},
    {key: Permission.UPDATE_JOB, value: "Update jobs"},
    {key: Permission.DELETE_JOB, value: "Delete jobs"},
    {key: Permission.SUBMIT_TIME, value: "Submit time"},
    {key: Permission.READ_OTHERS_TIME, value: "Read others time"},
    {key: Permission.UPDATE_OTHERS_TIME, value: "Update others time"}
];

export function permissionToName(permission: Permission): string | undefined {
    return PERMISSION_MAP.find(value => value.key === permission)?.value
}