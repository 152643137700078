import React, {useEffect} from "react";
import {Badge, Table} from "@mantine/core";
import pgClasses from "./PermissionGroups.module.css";
import {Permission, permissionToName} from "../../../models/Permission";
import {useUserFunctionProvider} from "../../../context/UserContext";
import {useNavigate} from "react-router-dom";
import {SortedTable} from "../../../components/sortedtable/SortedTable";
import {
    usePermissionGroupContextState,
    usePermissionGroupContextUpdater,
    usePermissionGroupFunctionProvider
} from "../../../context/PermissionGroupContext";
import {PermissionGroup} from "../../../models/PermissionGroup";
import {ROUTE_APP_TIME} from "../../../Constants";
import ManagedAlert from "../../../components/ManagedAlert";
import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import {getTitle, multiLoad, MultiLoadFunction} from "../../../util/Util";
import PageLoading from "../../../components/PageLoading";

export const CAN_SEE_PERMISSION_GROUPS = [
    Permission.READ_PERMISSION_GROUPS,
    Permission.UPDATE_PERMISSION_GROUPS,
    Permission.CREATE_PERMISSION_GROUPS,
    Permission.DELETE_PERMISSION_GROUPS,
];

function PermissionGroups() {
    useDocumentTitle(getTitle("Permissions group"));
    const permissionGroups = usePermissionGroupContextState();
    const setPermissionGroups = usePermissionGroupContextUpdater();
    const userFunctionProvider = useUserFunctionProvider();
    const permissionGroupFunctionProvider = usePermissionGroupFunctionProvider();
    const navigate = useNavigate();

    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});

    const canSeePermissionGroups = userFunctionProvider.hasPermission(CAN_SEE_PERMISSION_GROUPS);
    const canEditPermissionGroups = userFunctionProvider.hasPermission([
        Permission.UPDATE_PERMISSION_GROUPS,
        Permission.CREATE_PERMISSION_GROUPS,
        Permission.DELETE_PERMISSION_GROUPS,
    ]);
    const canCreatePermissionGroups = userFunctionProvider.hasPermission([
        Permission.CREATE_PERMISSION_GROUPS,
    ]);

    const getPermissionGroups: MultiLoadFunction = (onComplete) => {
        permissionGroupFunctionProvider.getPermissionGroups(navigate, () => {
        }, () => {
            setAlert({message: "Error getting permission groups", color: "red"});
        }, () => {
            onComplete();
        });
    };

    useEffect(() => {
        if (!canSeePermissionGroups) {
            navigate(ROUTE_APP_TIME);
        }

        multiLoad([getPermissionGroups], loadingHandlers.open, loadingHandlers.close);
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <PageLoading/>
    ) : (
        <React.Fragment>
            <ManagedAlert size={"xs"} mb={"md"} message={alert.message} color={alert.color} onClose={() => {
                setAlert(prevState => ({...prevState, message: ""}));
            }}/>
            <SortedTable
                columns={[
                    {name: "name", data: (item: PermissionGroup) => item.name},
                    {name: "Permissions", data: (item: PermissionGroup) => item.permissions.map(item => permissionToName(item)).join(",")},
                ]}
                data={permissionGroups.permissionGroups}
                onRow={item => {
                    return (
                        <React.Fragment>
                            <Table.Td>{item.name}</Table.Td>
                            <Table.Td className={pgClasses.tdBadges}>{item.permissions.map(perm => (
                                <Badge key={perm} size="xs" radius="xs">{permissionToName(perm)}</Badge>
                            ))}</Table.Td>
                        </React.Fragment>
                    );
                }}
                onFilter={(data, search) => {
                    const query = search.toLowerCase().trim();
                    return data.filter(value => {
                        const values = [
                            value.name,
                            value.permissions.map(item => permissionToName(item)).join(","),
                        ];

                        return values.some(key => key.toLowerCase().includes(query));
                    });
                }}
                canEdit={canEditPermissionGroups}
                canCreate={canCreatePermissionGroups}
                onNew={() => setPermissionGroups(prevState => {
                    return {...prevState, updatePermissionGroup: undefined, opened: true}
                })}
                onEdit={item => setPermissionGroups(prevState => {
                    return {...prevState, updatePermissionGroup: item, opened: true}
                })}
            />
        </React.Fragment>
    );
}

export default PermissionGroups;