export const EMAIL_PLACEHOLDER = "username@example.com";
export const EMAIL_VALIDATION_ERROR_TEXT = "Invalid email";
export const UUID_PLACEHOLDER = "00000000-0000-0000-0000-000000000000";

export const APP_NAME = "JobBox";
export const JOBBOX_URL = "https://jobbox.acramo.com";
export const ROUTE_APP_BASE = "jobbox";
export const ROUTE_LOGIN = "/" + ROUTE_APP_BASE + "/login";
export const ROUTE_SIGNUP = "/" + ROUTE_APP_BASE + "/signup";
export const ROUTE_FORGOT_PASSWORD = "/" + ROUTE_APP_BASE + "/forgot-password";
export const ROUTE_RESET_PASSWORD = "/" + ROUTE_APP_BASE + "/reset-password";

export const ROUTE_APP_USERS_BASE = "users";
export const ROUTE_APP_USERS = "/" + ROUTE_APP_BASE + "/" + ROUTE_APP_USERS_BASE;
export const ROUTE_APP_PERMISSION_GROUPS_BASE = "permission-groups";
export const ROUTE_APP_PERMISSION_GROUPS = "/" + ROUTE_APP_BASE + "/" + ROUTE_APP_PERMISSION_GROUPS_BASE;
export const ROUTE_APP_TITLES_BASE = "titles";
export const ROUTE_APP_TITLES = "/" + ROUTE_APP_BASE + "/" + ROUTE_APP_TITLES_BASE;
export const ROUTE_APP_CONTACTS_BASE = "contacts";
export const ROUTE_APP_CONTACTS = "/" + ROUTE_APP_BASE + "/" + ROUTE_APP_CONTACTS_BASE;
export const ROUTE_APP_JOBS_BASE = "jobs";
export const ROUTE_APP_JOBS = "/" + ROUTE_APP_BASE + "/" + ROUTE_APP_JOBS_BASE;
export const ROUTE_APP_TIME_BASE = "time";
export const ROUTE_APP_TIME = "/" + ROUTE_APP_BASE + "/" + ROUTE_APP_TIME_BASE;
