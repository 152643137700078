import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {useUserContextState} from "./context/UserContext";
import {
    ROUTE_APP_BASE,
    ROUTE_APP_CONTACTS_BASE,
    ROUTE_APP_JOBS_BASE,
    ROUTE_APP_PERMISSION_GROUPS_BASE,
    ROUTE_APP_TIME,
    ROUTE_APP_TIME_BASE,
    ROUTE_APP_TITLES_BASE,
    ROUTE_APP_USERS_BASE,
    ROUTE_FORGOT_PASSWORD,
    ROUTE_LOGIN,
    ROUTE_RESET_PASSWORD,
    ROUTE_SIGNUP,
} from "./Constants";
import Login from "./pages/gateway/login/Login";
import {Box, LoadingOverlay} from "@mantine/core";
import classes from "./App.module.css"
import ForgotPassword from "./pages/gateway/forgotpassword/ForgotPassword";
import Signup from "./pages/gateway/signup/Signup";
import App from "./pages/app/App";
import Users from "./pages/app/users/Users";
import GlobalLoading from "./components/GlobalLoading";
import PermissionGroups from "./pages/app/permissiongroups/PermissionGroups";
import Titles from "./pages/app/titles/Titles";
import Contacts from "./pages/app/contacts/Contacts";
import Jobs from "./pages/app/jobs/Jobs";
import Time from "./pages/app/time/Time";

function Root() {
    const userObj = useUserContextState();

    const unAuthenticatedRoute = (route: React.JSX.Element) => {
        return userObj.user === undefined ? route : <Navigate to={ROUTE_APP_TIME}/>;
    };

    const authenticatedRoute = (route: React.JSX.Element) => {
        return userObj.user === undefined ? <Navigate to={ROUTE_LOGIN}/> : route;
    };

    return userObj.loading ? (
        <GlobalLoading loading={true}/>
    ) : (
        <Box id={"App"} className={classes.App}>
            <LoadingOverlay
                visible={userObj.loading}
                zIndex={1000}
                overlayProps={{radius: 'sm', blur: 2}}
                loaderProps={{type: 'dots'}}
            />
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Navigate to={ROUTE_LOGIN}/>}/>
                    <Route path={ROUTE_LOGIN} element={unAuthenticatedRoute(<Login/>)}/>
                    <Route path={ROUTE_SIGNUP} element={unAuthenticatedRoute(<Signup/>)}>
                        <Route index path="*" element={unAuthenticatedRoute(<Signup/>)}/>
                    </Route>
                    <Route path={ROUTE_RESET_PASSWORD} element={unAuthenticatedRoute(<Signup isResetPassword/>)}>
                        <Route index path="*" element={unAuthenticatedRoute(<Signup isResetPassword/>)}/>
                    </Route>
                    <Route path={ROUTE_FORGOT_PASSWORD} element={unAuthenticatedRoute(<ForgotPassword/>)}/>
                    <Route path={ROUTE_RESET_PASSWORD} element={unAuthenticatedRoute(<div/>)}/>
                    <Route path={ROUTE_APP_BASE} element={authenticatedRoute(<App/>)}>
                        <Route path={ROUTE_APP_USERS_BASE} element={<Users/>}/>
                        <Route path={ROUTE_APP_PERMISSION_GROUPS_BASE} element={<PermissionGroups/>}/>
                        <Route path={ROUTE_APP_TITLES_BASE} element={<Titles/>}/>
                        <Route path={ROUTE_APP_CONTACTS_BASE} element={<Contacts/>}/>
                        <Route path={ROUTE_APP_JOBS_BASE} element={<Jobs/>}/>
                        <Route path={ROUTE_APP_TIME_BASE} element={<Time/>}/>
                        <Route index path="*" element={<Navigate to={ROUTE_APP_TIME}/>}/>
                    </Route>
                    <Route path="*" element={unAuthenticatedRoute(<div/>)}/>
                </Routes>
            </BrowserRouter>
        </Box>
    );
}

export default Root;
