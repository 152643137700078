export class TimeItem {
    job: string | null;
    time: number;

    constructor(job: string | null, time: number) {
        this.job = job;
        this.time = time;
    }

    static arrayFromJson(json: any): TimeItem[] | undefined {
        if (json === undefined || json === null) {
            return undefined;
        }

        let newArray: TimeItem[] = [];
        (json as []).forEach(value => {
            newArray.push(TimeItem.singleFromJson(value));
        });
        return newArray;
    }

    static singleFromJson(json: any): TimeItem {
        return new TimeItem(
            json["job"],
            parseFloat(json["time"])
        );
    }
}