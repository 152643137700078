import {Avatar, Group, rem, Text, UnstyledButton} from '@mantine/core';
import {IconChevronRight} from '@tabler/icons-react';
import classes from './UserButton.module.css';
import {useUserContextState, useUserContextUpdater} from "../../context/UserContext";
import React from "react";
import {getColorFromString} from "../../util/Util";



export function UserButton() {
    const user = useUserContextState();
    const setUser = useUserContextUpdater();
    const initials = (user.user?.getInitials().toUpperCase()) ?? "U";
    const color = getColorFromString(initials);


    return (
        <UnstyledButton className={classes.user} onClick={() => setUser(prevState => {
            return {...prevState, updateUser: prevState.user, opened: true}
        })}>
            <Group>
                <Avatar radius="xl" color={color}>{initials}</Avatar>
                <div style={{flex: 1}}>
                    <Text size="sm" fw={500}>{user.user?.getPreferredName()}</Text>
                    <Text c="dimmed" size="xs">{user.user?.email}</Text>
                </div>
                <IconChevronRight style={{width: rem(14), height: rem(14)}} stroke={1.5}/>
            </Group>
        </UnstyledButton>
    );
}