import {
    ActionIcon,
    Button,
    Checkbox,
    Divider,
    Flex,
    Group,
    Menu,
    Modal,
    MultiSelect,
    Select,
    Stack,
    Text,
    TextInput
} from "@mantine/core";
import {EMAIL_PLACEHOLDER, EMAIL_VALIDATION_ERROR_TEXT} from "../../../Constants";
import React, {useEffect} from "react";
import globalClasses from "../../../pages/Global.module.css";
import GlobalLoading from "../../GlobalLoading";
import ManagedAlert from "../../ManagedAlert";
import {hasError} from "../../../util/GraphQl";
import {useUserContextState, useUserFunctionProvider} from "../../../context/UserContext";
import {useNavigate} from "react-router-dom";
import {useDisclosure} from "@mantine/hooks";
import {Permission} from "../../../models/Permission";
import {isEmail, useForm} from "@mantine/form";
import PersonalInformation from "../../../models/PersonalInformation";
import {useTitleContextState, useTitleFunctionProvider} from "../../../context/TitleContext";
import {
    usePermissionGroupContextState,
    usePermissionGroupFunctionProvider
} from "../../../context/PermissionGroupContext";
import classes from "../UserDrawer.module.css";
import {IconChevronDown, IconMailFast} from "@tabler/icons-react";
import cx from "clsx";
import {multiLoad, MultiLoadFunction} from "../../../util/Util";

const DEFAULT_VALUES = {
    email: '',
    firstName: '',
    preferredFirstName: '',
    middleName: '',
    lastName: '',
    phoneNumber: '',
    permissionGroupIds: new Array<string>(),
    titleId: '',
    active: false,
};

function UserInfo() {
    const user = useUserContextState();
    const userFunctionProvider = useUserFunctionProvider();
    const titleFunctionProvider = useTitleFunctionProvider();
    const titles = useTitleContextState();
    const permissionGroupFunctionProvider = usePermissionGroupFunctionProvider();
    const permissionGroups = usePermissionGroupContextState();
    const navigate = useNavigate();
    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});
    const userForm = useForm({
        initialValues: DEFAULT_VALUES,
        validateInputOnBlur: true,
        validate: {
            email: isEmail(EMAIL_VALIDATION_ERROR_TEXT),
        }
    });
    const [opened, {open, close}] = useDisclosure(false);

    const canSeeTitle = userFunctionProvider.hasPermission([Permission.READ_TITLE, Permission.DELETE_TITLE, Permission.UPDATE_TITLE, Permission.CREATE_TITLE]);
    const canSeePermissionGroups = userFunctionProvider.hasPermission([Permission.READ_PERMISSION_GROUPS, Permission.DELETE_PERMISSION_GROUPS, Permission.UPDATE_PERMISSION_GROUPS, Permission.CREATE_PERMISSION_GROUPS]);
    const canUpdateUser = userFunctionProvider.hasPermission([Permission.UPDATE_EMPLOYEES]);
    const canCreateUser = userFunctionProvider.hasPermission([Permission.CREATE_EMPLOYEES]);
    const canDeleteUser = userFunctionProvider.hasPermission([Permission.DELETE_EMPLOYEES]);
    const canSendInvite = userFunctionProvider.hasPermission([Permission.SEND_INVITE_EMAIL]);

    const getPermissionGroups: MultiLoadFunction = (onComplete) => {
        if (!canSeePermissionGroups) {
            onComplete();
            return;
        }

        permissionGroupFunctionProvider.getPermissionGroups(navigate, () => {
        }, () => {
            setAlert({message: "Error fetching permission groups", color: "red"});
        }, () => {
            onComplete();
        });
    };

    const getTitles: MultiLoadFunction = (onComplete) => {
        if (!canSeeTitle) {
            onComplete();
            return;
        }

        titleFunctionProvider.getTitles(navigate, () => {
        }, () => {
            setAlert({message: "Error fetching titles", color: "red"});
        }, () => {
            onComplete();
        });
    };

    useEffect(() => {
        if (user.updateUser !== undefined) {
            userForm.setValues({
                email: user.updateUser?.email ?? '',
                firstName: user.updateUser?.personalInformation.firstName ?? '',
                preferredFirstName: user.updateUser?.personalInformation.preferredFirstName ?? '',
                middleName: user.updateUser?.personalInformation.middleName ?? '',
                lastName: user.updateUser?.personalInformation.lastName ?? '',
                phoneNumber: user.updateUser?.personalInformation.phoneNumber ?? '',
                permissionGroupIds: user.updateUser?.permissionGroupIds.map(v => canSeePermissionGroups ? v : "[ REDACTED ]") ?? [],
                titleId: canSeeTitle ? user.updateUser?.titleId ?? '' : '',
                active: user.updateUser?.active ?? false,
            });
        }

        multiLoad([getTitles, getPermissionGroups], loadingHandlers.open, loadingHandlers.close);
        // eslint-disable-next-line
    }, [user.updateUser]);

    useEffect(() => {
        if (!userForm.isTouched()) {
            userForm.resetDirty();
        }
        // eslint-disable-next-line
    }, [userForm.values]);

    const completeCreateUser = (values: {
        email: string,
        firstName: string,
        preferredFirstName: string,
        middleName: string,
        lastName: string,
        phoneNumber: string,
        permissionGroupIds: string[],
        titleId: string,
        active: boolean
    }) => {
        loadingHandlers.open();
        setAlert({message: "", color: "red"});
        if (user.updateUser === undefined) {
            userFunctionProvider.createUser(values.email, new PersonalInformation(
                values.firstName,
                values.preferredFirstName,
                values.middleName,
                values.lastName,
                values.phoneNumber
            ), navigate, () => {
                setAlert({message: "Successfully created user", color: "green"});
                userForm.reset();
            }, (errors) => {
                if (hasError(errors, "InvalidDataException")) {
                    setAlert({message: "Email is invalid", color: "red"});
                } else if (hasError(errors, "UserAlreadyExistsException")) {
                    setAlert({message: "User already exists", color: "red"});
                } else {
                    setAlert({message: "Error while trying to create user", color: "red"});
                }
            }, () => {
                loadingHandlers.close();
            });
        } else {
            userFunctionProvider.updateSelf({
                input: {
                    ...(canUpdateUser && {id: user.updateUser?.id}),
                    ...(values.email !== user.updateUser?.email && {email: values.email}),
                    ...(
                        canUpdateUser && canSeePermissionGroups &&
                        (
                            user.updateUser?.permissionGroupIds.length !== values.permissionGroupIds.length ||
                            !values.permissionGroupIds.every((value, index) => value === user.updateUser?.permissionGroupIds[index])
                        ) &&
                        {permissionGroupIds: values.permissionGroupIds}
                    ),
                    personalInformation: {
                        ...(values.firstName !== user.updateUser?.personalInformation.firstName && {firstName: values.firstName}),
                        ...(values.preferredFirstName !== user.updateUser?.personalInformation.preferredFirstName && {preferredFirstName: values.preferredFirstName}),
                        ...(values.middleName !== user.updateUser?.personalInformation.middleName && {middleName: values.middleName}),
                        ...(values.lastName !== user.updateUser?.personalInformation.lastName && {lastName: values.lastName}),
                        ...(values.phoneNumber !== user.updateUser?.personalInformation.phoneNumber && {phoneNumber: values.phoneNumber}),
                    },
                    ...(canUpdateUser && values.active !== user.updateUser?.active && {active: values.active}),
                    ...(canUpdateUser && canSeeTitle && values.titleId !== user.updateUser?.titleId && {titleId: values.titleId}),
                }
            }, navigate, () => {
                setAlert({message: "Successfully updated user", color: "green"});
            }, (errors) => {
                if (hasError(errors, "DataCorruptionException")) {
                    setAlert({message: "Invalid user to update", color: "red"});
                } else if (hasError(errors, "UserNotFoundException")) {
                    setAlert({message: "Unable to find user to update", color: "red"});
                } else {
                    setAlert({message: "Error while trying to update user", color: "red"});
                }

            }, () => {
                userForm.resetDirty();
                userForm.resetTouched();
                loadingHandlers.close();
            });
        }
    };

    const deleteUser = () => {
        close();
        loadingHandlers.open();
        setAlert({message: "", color: "red"});
        userFunctionProvider.deleteUser(user.updateUser?.id, navigate, () => {
            setAlert({message: "Successfully deleted user", color: "green"});
            userForm.setValues(DEFAULT_VALUES);
        }, (errors) => {
            if (hasError(errors, "UserNotFoundException")) {
                setAlert({message: "Unable to find user to delete", color: "red"});
            } else {
                setAlert({message: "Error while trying to delete user", color: "red"});
            }
        }, () => {
            loadingHandlers.close();
        });
    };

    const sendInvite = () => {
        loadingHandlers.open();
        setAlert({message: "", color: "red"});
        userFunctionProvider.sendInvite(user.updateUser?.id, navigate, () => {
            setAlert({message: "Successfully invited user", color: "green"});
        }, (errors) => {
            if (hasError(errors, "UserNotFoundException")) {
                setAlert({message: "Unable to find user to delete", color: "red"});
            } else if (hasError(errors, "InvalidDataException")) {
                setAlert({message: "Invalid email or invite token", color: "red"});
            } else {
                setAlert({message: "Error while trying to send invite", color: "red"});
            }
        }, () => {
            loadingHandlers.close();
        });
    };

    return (
        <React.Fragment>
            <GlobalLoading loading={loading}/>
            <ManagedAlert size={"xs"} mt={"md"} message={alert.message} color={alert.color} onClose={() => {
                setAlert(prevState => ({...prevState, message: ""}));
            }}/>
            <form onSubmit={userForm.onSubmit(completeCreateUser)}>
                <TextInput
                    type={"email"}
                    label="Email"
                    placeholder={EMAIL_PLACEHOLDER}
                    required
                    mt="md"
                    {...userForm.getInputProps('email')}
                    disabled={user.updateUser ? !canUpdateUser : !canCreateUser}
                />
                <Group justify="space-between" mt="md" grow wrap={"nowrap"}>
                    <TextInput
                        type={"text"}
                        label="Name"
                        placeholder={"First name"}
                        {...userForm.getInputProps('firstName')}
                        disabled={user.updateUser ? !canUpdateUser : !canCreateUser}
                    />
                    <TextInput
                        type={"text"}
                        label="Preferred name"
                        placeholder={"Preferred first name"}
                        {...userForm.getInputProps('preferredFirstName')}
                        disabled={user.updateUser ? !canUpdateUser : !canCreateUser}
                    />
                </Group>
                <Group justify="space-between" mt="md" grow wrap={"nowrap"}>
                    <TextInput
                        type={"text"}
                        label="Middle name"
                        placeholder={"Middle name"}
                        {...userForm.getInputProps('middleName')}
                        disabled={user.updateUser ? !canUpdateUser : !canCreateUser}
                    />
                    <TextInput
                        type={"text"}
                        label="Last name"
                        placeholder={"Last name"}
                        {...userForm.getInputProps('lastName')}
                        disabled={user.updateUser ? !canUpdateUser : !canCreateUser}
                    />
                </Group>
                <TextInput
                    type={"tel"}
                    label="Phone number"
                    placeholder={"+1-012-345-6789"}
                    mt="md"
                    {...userForm.getInputProps('phoneNumber')}
                    disabled={user.updateUser ? !canUpdateUser : !canCreateUser}
                />
                {canUpdateUser && (
                    <React.Fragment>
                        <Divider my={"md"}/>
                        <Select
                            label="Title"
                            placeholder="Title"
                            data={titles.titles.map(item => ({value: item.id, label: item.name}))}
                            nothingFoundMessage="Nothing found..."
                            searchable
                            disabled={!canSeeTitle || (user.updateUser ? !canUpdateUser : false)}
                            mt={"md"}
                            {...userForm.getInputProps('titleId')}
                        />
                        <MultiSelect
                            label={"Permission groups"}
                            placeholder="Pick value"
                            mt={"md"}
                            data={permissionGroups.permissionGroups.map(item => ({value: item.id, label: item.name}))}
                            checkIconPosition="left"
                            searchable
                            nothingFoundMessage="Nothing found..."
                            disabled={!canSeePermissionGroups || (user.updateUser ? !canUpdateUser : false)}
                            clearable
                            {...userForm.getInputProps('permissionGroupIds')}
                        />
                        <Checkbox
                            label="Active"
                            mt={"md"}
                            {...userForm.getInputProps('active', {type: 'checkbox'})}
                            disabled={user.updateUser === undefined}
                        />
                    </React.Fragment>
                )}
                <Group justify="right" mt="lg" className={globalClasses.controls} mb={"lg"}>
                    {canDeleteUser && user.updateUser !== undefined && (
                        <Button className={globalClasses.control} color={"red"} variant={"subtle"} onClick={open}>Delete
                            account</Button>
                    )}
                    <Group wrap="nowrap" gap={0} className={globalClasses.control}>
                        <Button
                            className={cx(globalClasses.control, {[classes.button]: user.updateUser !== undefined && canSendInvite})}
                            type={"submit"} disabled={!userForm.isDirty()}>
                            {user.updateUser === undefined ? "Create account" : "Update account"}
                        </Button>
                        {user.updateUser !== undefined && canSendInvite && (
                            <Menu transitionProps={{transition: 'pop'}} position="bottom-end" withinPortal>
                                <Menu.Target>
                                    <ActionIcon
                                        variant="filled"
                                        size={36}
                                        className={classes.menuControl}
                                    >
                                        <IconChevronDown size={16} stroke={1.5}/>
                                    </ActionIcon>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Item
                                        leftSection={
                                            <IconMailFast
                                                size={16}
                                                stroke={1.5}
                                            />
                                        }
                                        onClick={sendInvite}
                                    >
                                        {(user.updateUser?.inviteToken ?? "").length === 0 ? "Send invite" : "Re-send invite"}
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        )}
                    </Group>
                </Group>
            </form>
            <Modal opened={opened} onClose={close} title="Delete user" withCloseButton centered>
                <Stack>
                    <Text>Are You sure you want to delete user {user.updateUser?.getPreferredName() ?? ""}?</Text>
                    <Flex
                        direction={{base: 'column', sm: 'row'}}
                        gap={{base: 'sm', sm: 'lg'}}
                        justify={"flex-end"}
                    >
                        <Button className={globalClasses.control} color={"gray"} variant={"subtle"}
                                onClick={close}>Cancel</Button>
                        <Button className={globalClasses.control} color={"red"} variant={"filled"} onClick={deleteUser}>Delete
                            account</Button>
                    </Flex>
                </Stack>
            </Modal>
        </React.Fragment>

    );
}

export default UserInfo;