import {Paper, Table, Text, TextInput} from "@mantine/core";
import {UUID_PLACEHOLDER} from "../../../Constants";
import React from "react";
import {useUserContextState} from "../../../context/UserContext";

function Metadata() {
    const user = useUserContextState();

    return (
        <React.Fragment>
            <TextInput
                label="User id"
                placeholder={UUID_PLACEHOLDER}
                mt="md"
                value={user.updateUser?.id ?? UUID_PLACEHOLDER}
                disabled
            />
            <TextInput
                label="Invite token"
                placeholder={UUID_PLACEHOLDER}
                mt="md"
                value={user.updateUser?.inviteToken ?? UUID_PLACEHOLDER}
                disabled
            />
            <TextInput
                label="Last known ip address"
                placeholder={"255.255.255.255"}
                mt="md"
                value={`${user.updateUser?.lastKnownIpAddress.address} (${user.updateUser?.lastKnownIpAddress.firstSeen})`}
                disabled
            />
            <TextInput
                label="Created at"
                placeholder={"Date"}
                mt="md"
                value={user.updateUser?.createdAt ?? ""}
                disabled
            />
            <TextInput
                label="Updated at"
                placeholder={"Date"}
                mt="md"
                value={user.updateUser?.updatedAt ?? ""}
                disabled
            />
            <Paper withBorder mt={"xl"} mb={"sm"} style={{maxHeight: "400px", overflow: "scroll"}}>
                <Table highlightOnHover layout={"fixed"}>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Ip Address</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        {user.updateUser?.knownIpAddresses.map(item => (
                            <Table.Tr key={item.address}>
                                <Table.Td>
                                    <div>
                                        <Text fw={500}>{item.address}</Text>
                                        <Text size="sm" c="dimmed">{item.agent}</Text>
                                        <Text size="sm" c="dimmed">{item.firstSeen + " (First seen)"}</Text>
                                    </div>
                                </Table.Td>
                            </Table.Tr>
                        ))}
                        {(user.updateUser?.knownIpAddresses ?? []).length === 0 && (
                            <Table.Tr>
                                <Table.Td>
                                    <Text fs="italic">No Ip-address</Text>
                                </Table.Td>
                            </Table.Tr>
                        )}
                    </Table.Tbody>
                </Table>
            </Paper>
        </React.Fragment>
    );
}

export default Metadata;