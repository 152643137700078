import React, {useEffect} from "react";
import {Badge, rem, Table} from "@mantine/core";
import {Permission} from "../../../models/Permission";
import {useUserFunctionProvider} from "../../../context/UserContext";
import {useNavigate} from "react-router-dom";
import {SortedTable} from "../../../components/sortedtable/SortedTable";
import {ROUTE_APP_TIME} from "../../../Constants";
import ManagedAlert from "../../../components/ManagedAlert";
import {
    useContactContextState,
    useContactContextUpdater,
    useContactFunctionProvider
} from "../../../context/ContactContext";
import {Contact} from "../../../models/Contact";
import PageLoading from "../../../components/PageLoading";
import {getTitle, multiLoad, MultiLoadFunction} from "../../../util/Util";
import {useDisclosure, useDocumentTitle} from "@mantine/hooks";

export const CAN_SEE_CONTACTS = [
    Permission.READ_CONTACT,
    Permission.UPDATE_CONTACT,
    Permission.CREATE_CONTACT,
    Permission.DELETE_CONTACT,
];

function Contacts() {
    useDocumentTitle(getTitle("Contacts"));
    const contacts = useContactContextState();
    const setContacts = useContactContextUpdater();
    const userFunctionProvider = useUserFunctionProvider();
    const contactFunctionProvider = useContactFunctionProvider();
    const navigate = useNavigate();

    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});

    const canSeeContacts = userFunctionProvider.hasPermission(CAN_SEE_CONTACTS);
    const canEditContacts = userFunctionProvider.hasPermission([
        Permission.UPDATE_CONTACT,
        Permission.CREATE_CONTACT,
        Permission.DELETE_CONTACT,
    ]);
    const canCreateContacts = userFunctionProvider.hasPermission([
        Permission.CREATE_CONTACT,
    ]);

    const getContacts: MultiLoadFunction = (onComplete) => {
        contactFunctionProvider.getContacts(undefined, navigate, () => {
        }, () => {
            setAlert({message: "Error getting contacts", color: "red"});
        }, () => {
            onComplete();
        });
    };

    useEffect(() => {
        if (!canSeeContacts) {
            navigate(ROUTE_APP_TIME);
        }

        multiLoad([getContacts], loadingHandlers.open, loadingHandlers.close);
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <PageLoading/>
    ) : (
        <React.Fragment>
            <ManagedAlert size={"xs"} mb={"md"} message={alert.message} color={alert.color} onClose={() => {
                setAlert(prevState => ({...prevState, message: ""}));
            }}/>
            <SortedTable
                columns={[
                    {
                        name: "name",
                        data: (item: Contact) => item.isBusiness ? item.businessName : item.getPreferredName()
                    },
                    {name: "is business", data: (item: Contact) => item.isBusiness.toString()},
                    {name: "email", data: (item: Contact) => item.email},
                    {name: "phone number", data: (item: Contact) => item.personalInformation.phoneNumber},
                    {name: "active", data: (item: Contact) => item.active.toString()}
                ]}
                data={contacts.contacts}
                onRow={item => {
                    return (
                        <React.Fragment>
                            <Table.Td>{item.isBusiness ? item.businessName : item.getPreferredName()}</Table.Td>
                            <Table.Td>
                                <Badge color={item.isBusiness ? "pink" : "cyan"} size="xs" radius="xs"
                                       style={{minWidth: rem(60)}}>
                                    {item.isBusiness ? "Business" : "Person"}
                                </Badge>
                            </Table.Td>
                            <Table.Td>{item.email}</Table.Td>
                            <Table.Td>{item.personalInformation.phoneNumber}</Table.Td>
                            <Table.Td>
                                <Badge color={item.active ? "green" : "red"} size="xs" radius="xs"
                                       style={{minWidth: rem(60)}}>
                                    {item.active ? "Active" : "Inactive"}
                                </Badge>
                            </Table.Td>
                        </React.Fragment>
                    );
                }}
                onFilter={(data, search) => {
                    const query = search.toLowerCase().trim();
                    return data.filter(value => {
                        const values = [
                            value.isBusiness ? value.businessName : value.getPreferredName(),
                            value.isBusiness ? "business" : "person",
                            value.email,
                            value.personalInformation.phoneNumber,
                            value.active ? "active" : "inactive"
                        ];

                        return values.some(key => key.toLowerCase().includes(query));
                    });
                }}
                canEdit={canEditContacts}
                canCreate={canCreateContacts}
                onNew={() => setContacts(prevState => {
                    return {...prevState, updateContact: undefined, opened: true}
                })}
                onEdit={item => setContacts(prevState => {
                    return {...prevState, updateContact: item, opened: true}
                })}
            />
        </React.Fragment>
    );
}

export default Contacts;