import { rem } from '@mantine/core';

interface JobBoxIconProps extends React.ComponentPropsWithoutRef<'svg'> {
    size?: number | string;
}

export function JobBoxIcon({ size, style, color, ...others }: JobBoxIconProps) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={color}
            viewBox="0 0 18 18"
            style={{ width: rem(size), height: rem(size), ...style }}
            {...others}
        >
            <g data-name="Layer 1">
                <path d="m9,0H2C.89,0,0,.89,0,2v14c0,1.11.89,2,2,2h14c1.11,0,2-.89,2-2v-7h-2v7H2V2h7"/>
                <polyline points="4 12 4 14 14 14 14 12 4 12"/>
                <polyline points="4 8 4 10 14 10 14 9 13 8 4 8"/>
                <polygon points="11 6 9 4 4 4 4 6"/>
            </g>
            <g data-name="Layer 3">
                <polygon points="16 9 9 2 9 0 18 9"/>
            </g>
        </svg>
    );
}