export class Title {
    id: string;
    name: string;
    burdenRate: number;
    billableRate: number;

    constructor(id: string, name: string, burdenRate: number, billableRate: number) {
        this.id = id;
        this.name = name;
        this.burdenRate = burdenRate;
        this.billableRate = billableRate;
    }

    static arrayFromJson(json: any): Title[] | undefined {
        if (json === undefined || json === null) {
            return undefined;
        }

        let newArray: Title[] = [];
        (json as []).forEach(value => {
            newArray.push(Title.singleFromJson(value));
        });
        return newArray;
    }

    static singleFromJson(json: any): Title {
        return new Title(
            json["id"],
            json["name"],
            parseFloat(json["burdenRate"]),
            parseFloat(json["billableRate"]),
        );
    }
}