import React from "react";
import {Anchor, Box, Button, Center, Container, Group, Paper, rem, Text, TextInput, Title,} from '@mantine/core';
import {IconArrowLeft} from '@tabler/icons-react';
import gatewayClasses from "../Gateway.module.css";
import globalClasses from "../../Global.module.css";
import {EMAIL_PLACEHOLDER, EMAIL_VALIDATION_ERROR_TEXT, ROUTE_LOGIN} from "../../../Constants";
import {useNavigate} from "react-router-dom";
import {useUserFunctionProvider} from "../../../context/UserContext";
import {isEmail, useForm} from "@mantine/form";
import GlobalLoading from "../../../components/GlobalLoading";
import ManagedAlert from "../../../components/ManagedAlert";
import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import {getTitle} from "../../../util/Util";

function ForgotPassword() {
    useDocumentTitle(getTitle("Forgot password"));
    const navigate = useNavigate();
    const userFunctions = useUserFunctionProvider();
    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});
    const form = useForm({
        initialValues: {
            email: ''
        },
        validateInputOnChange: true,
        validate: {
            email: isEmail(EMAIL_VALIDATION_ERROR_TEXT)
        }
    });

    const requestPasswordReset = (values: { email: string }) => {
        loadingHandlers.open();
        userFunctions.requestPasswordReset(values.email, () => {
            setAlert({
                message: "If this account exists, an email was sent with instructions on how to reset the password.",
                color: "yellow"
            });
            form.reset();
        }, () => {
            setAlert({message: "Error while trying to reset password", color: "red"});
        }, () => {
            loadingHandlers.close();
        });
    };

    const forgotPasswordContent = (
        <React.Fragment>
            <TextInput
                type={"email"}
                label="Your email"
                placeholder={EMAIL_PLACEHOLDER}
                required
                {...form.getInputProps('email')}
            />
            <Group justify="space-between" mt="lg" className={globalClasses.controls}>
                <Anchor c="dimmed" size="sm" className={globalClasses.control} onClick={() => navigate(ROUTE_LOGIN)}>
                    <Center inline>
                        <IconArrowLeft style={{width: rem(12), height: rem(12)}} stroke={1.5}/>
                        <Box ml={5}>Back to the login page</Box>
                    </Center>
                </Anchor>
                <Button className={globalClasses.control} type={"submit"}>Reset password</Button>
            </Group>
        </React.Fragment>
    );

    return (
        <Box className={gatewayClasses.container}>
            <Box id={"needed-so-flex-does-not-resize-paper"}>
                <Container size={460}>
                    <Title className={gatewayClasses.title} ta="center">
                        Forgot your password?
                    </Title>
                    <Text c="dimmed" fz="sm" ta="center">
                        Enter your email to get a reset link
                    </Text>
                    <form onSubmit={form.onSubmit(requestPasswordReset)}>
                        <GlobalLoading loading={loading}/>
                        <ManagedAlert mt={30} message={alert.message} color={alert.color} onClose={() => {
                            setAlert(prevState => ({...prevState, message: ""}));
                        }}/>
                        <Paper withBorder shadow="md" p={30} mt={30} radius="md" visibleFrom={"xs"}>
                            {forgotPasswordContent}
                        </Paper>
                        <Box mt={30} hiddenFrom={"xs"}>
                            {forgotPasswordContent}
                        </Box>
                    </form>
                </Container>
            </Box>
        </Box>
    );
}

export default ForgotPassword;