// noinspection GraphQLUnresolvedReference

import {GraphQLErrors} from "@apollo/client/errors";
import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo} from "react";
import {Title} from "../models/Title";
import {ApolloError, gql, useLazyQuery, useMutation} from "@apollo/client";
import {useUserContextState, useUserContextUpdater} from "./UserContext";
import {onUnauthenticatedError} from "../util/GraphQl";
import {NavigateFunction} from "react-router-dom";

export interface TitleContextStateType {
    updateTitle: Title | undefined,
    opened: boolean,
    titles: Title[]
}

export interface TitleFunctionProvider {
    getTitles(navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    createTitle(data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    updateTitle(data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    deleteTitle(id: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;
}
const DEFAULT_STATE = {
    updateTitle: undefined,
    opened: false,
    titles: []
};
export const TitleContextState = createContext<TitleContextStateType>(DEFAULT_STATE);
export const TitleContextUpdater = createContext<Dispatch<SetStateAction<TitleContextStateType>>>(() => {
    return;
});
export const TitleFunctionProviderContext = createContext<TitleFunctionProvider>({} as TitleFunctionProvider);

export function useTitleContextState() {
    return useContext(TitleContextState);
}

export function useTitleContextUpdater() {
    return useContext(TitleContextUpdater);
}

export function useTitleFunctionProvider() {
    return useContext(TitleFunctionProviderContext);
}

const GET_TITLE = gql`
    query titles {
        titles {
            ...FullTitle
        }
    }
`;
const CREATE_TITLE = gql`
    mutation createTitle($data: TitleInput!) {
        createTitle(data: $data) {
            ...FullTitle
        }
    }
`;
const UPDATE_TITLE = gql`
    mutation updateTitle($data: TitleInput!) {
        updateTitle(data: $data) {
            ...FullTitle
        }
    }
`;
const DELETE_TITLE = gql`
    mutation deleteTitle($id: ID!) {
        deleteTitle(id: $id)
    }
`;

interface TitleContextProviderProps {
    children?: ReactNode;
}

export function TitleContextProvider(props: TitleContextProviderProps) {
    const user = useUserContextState();
    const setUser = useUserContextUpdater();

    const [titles, setTitles] = React.useState<TitleContextStateType>(DEFAULT_STATE);

    const [getTitles] = useLazyQuery(GET_TITLE);
    const [createTitle] = useMutation(CREATE_TITLE);
    const [updateTitle] = useMutation(UPDATE_TITLE);
    const [deleteTitle] = useMutation(DELETE_TITLE);

    useEffect(() => {
        if(user.user === undefined) {
            setTitles(DEFAULT_STATE);
        }
    }, [user.user]);

    const sharedUpdate = (json: any) => {
        const item = Title.singleFromJson(json);
        setTitles((prevState) => {
            const newItems = [...prevState.titles];
            const index = newItems.findIndex(v => v.id === item.id);
            if (index === -1) {
                newItems.push(item);
            } else {
                newItems[index] = item;
            }
            return {...prevState, titles: newItems, updateTitle: index === -1 ? prevState.updateTitle : item};
        });
    };

    const funcObj = useMemo(() => {
        return {
            getTitles: (navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                getTitles({
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.error !== undefined || r.data === undefined) {
                        onUnauthenticatedError(r.error, setUser, navigate);
                        onError?.(r.error?.graphQLErrors ?? []);
                        return;
                    }

                    setTitles(prevState => ({...prevState, titles: Title.arrayFromJson(r.data.titles) ?? []}));
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            createTitle: (data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                createTitle({
                    variables: data,
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    sharedUpdate(r.data.createTitle);
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            updateTitle: (data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                updateTitle({
                    variables: data,
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    sharedUpdate(r.data.updateTitle);
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            deleteTitle: (id: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                deleteTitle({
                    variables: {
                        id: id ?? ""
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    setTitles((prevState) => {
                        const newItems = [...prevState.titles];
                        const index = newItems.findIndex(v => v.id === id);
                        if (index !== -1) {
                            newItems.splice(index, 1);
                        }
                        return {...prevState, updateTitle: undefined, titles: newItems};
                    });
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            }
        };
    }, []);

    return (
        <TitleContextState.Provider value={titles}>
            <TitleContextUpdater.Provider value={setTitles}>
                <TitleFunctionProviderContext.Provider value={funcObj}>
                    {props.children}
                </TitleFunctionProviderContext.Provider>
            </TitleContextUpdater.Provider>
        </TitleContextState.Provider>
    );
}