// noinspection GraphQLUnresolvedReference

import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo} from "react";
import {User} from "../models/User";
import {ApolloError, gql, useLazyQuery, useMutation} from "@apollo/client";
import {GraphQLErrors} from "@apollo/client/errors";
import {Permission} from "../models/Permission";
import {NavigateFunction} from "react-router-dom";
import {onUnauthenticatedError} from "../util/GraphQl";
import PersonalInformation from "../models/PersonalInformation";

export interface UserContextStateType {
    user: User | undefined,
    loading: boolean,
    updateUser: User | undefined,
    opened: boolean,
    users: User[]
}

export interface UserFunctionProvider {
    login(email: string, password: string, rememberMe: boolean, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    requestPasswordReset(email: string, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    completeCreateUser(email: string, password: string, token: string, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    updateSelf(data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    revokeHashes(id: string | undefined, hashes: string[] | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    hasPermission(checkPerms: Permission[]): boolean;

    logout(navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    getUsers(navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    createUser(email: string, personalInformation: PersonalInformation, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void

    deleteUser(id: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    sendInvite(id: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    updatePassword(email: string, password: string, token: string, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    getPermissions(navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;
}

export const DEFAULT_STATE = {
    user: undefined,
    loading: true,
    updateUser: undefined,
    opened: false,
    users: []
};

export const UserContextState = createContext<UserContextStateType>(DEFAULT_STATE);
export const UserContextUpdater = createContext<Dispatch<SetStateAction<UserContextStateType>>>(() => {
    return;
});
export const UserFunctionProviderContext = createContext<UserFunctionProvider>({} as UserFunctionProvider);

export function useUserContextState() {
    return useContext(UserContextState);
}

export function useUserContextUpdater() {
    return useContext(UserContextUpdater);
}

export function useUserFunctionProvider() {
    return useContext(UserFunctionProviderContext);
}

interface UserContextProviderProps {
    children?: ReactNode;
}

const GET_USER = gql`
    query user {
        user {
            ...FullUser
        }
    }
`;
const GET_USERS = gql`
    query users {
        users {
            ...FullUser
        }
    }
`;
const USER_LOGIN = gql`
    mutation login($email: String!, $password: String!, $rememberMe: Boolean!) {
        login(email: $email, password: $password, rememberMe: $rememberMe) {
            ...FullUser
        }
    }
`;
const REQUEST_PASSWORD_RESET = gql`
    mutation requestPasswordReset($email: String!) {
        requestPasswordReset(email: $email)
    }
`;
const COMPLETE_USER_SIGNUP = gql`
    mutation completeCreateUser($email: String!, $password: String!, $token: String!) {
        completeCreateUser(email: $email, password: $password, token: $token) {
            ...FullUser
        }
    }
`;
const GET_PERMISSIONS = gql`
    query permissions {
        permissions
    }
`;
const UPDATE_SELF = gql`
    mutation updateUser($input: AdminUser!) {
        updateUser(input: $input) {
            ...FullUser
        }
    }
`;
const REVOKE_TOKENS = gql`
    mutation revokeTokens($id: ID, $hashes: [String!]) {
        revokeTokens(id: $id, hashes: $hashes) {
            ...FullUser
        }
    }
`;
const USER_LOGOUT = gql`
    mutation logout {
        logout
    }
`;
const CREATE_USER = gql`
    mutation createUser($email: String!, $personalInformation: PersonalInformationInput) {
        createUser(email: $email, personalInformation: $personalInformation) {
            ...FullUser
        }
    }
`;
const DELETE_USER = gql`
    mutation deleteUser($id: ID!) {
        deleteUser(id: $id)
    }
`;
const SEND_INVITE = gql`
    mutation sendInvite($id: ID!) {
        sendInvite(id: $id) {
            ...FullUser
        }
    }
`;
const UPDATE_PASSWORD = gql`
    mutation updatePassword($email: String!, $password: String!, $resetToken: String!) {
        updatePassword(email: $email, password: $password, resetToken: $resetToken)
    }
`;

export function UserContextProvider(props: UserContextProviderProps) {
    const [user, setUser] = React.useState<UserContextStateType>(DEFAULT_STATE);
    const [permissions, setPermissions] = React.useState<number[]>([]);

    const [getUser] = useLazyQuery(GET_USER);
    const [getUsers] = useLazyQuery(GET_USERS);
    const [login] = useMutation(USER_LOGIN);
    const [requestPasswordReset] = useMutation(REQUEST_PASSWORD_RESET);
    const [completeCreateUser] = useMutation(COMPLETE_USER_SIGNUP);
    const [updateSelf] = useMutation(UPDATE_SELF);
    const [getPermissions] = useLazyQuery(GET_PERMISSIONS);
    const [revokeTokens] = useMutation(REVOKE_TOKENS);
    const [logout] = useMutation(USER_LOGOUT);
    const [createUser] = useMutation(CREATE_USER);
    const [deleteUser] = useMutation(DELETE_USER);
    const [sendInvite] = useMutation(SEND_INVITE);
    const [updatePassword] = useMutation(UPDATE_PASSWORD);

    useEffect(() => {
        if(user.user === undefined) {
            setPermissions([]);
        }
    }, [user.user]);

    useEffect(() => {
        getUser({
            fetchPolicy: "no-cache"
        }).then(r => {
            if (r.error !== undefined || r.data === undefined) {
                setUser(prevState => ({...prevState, user: undefined, loading: false}));
                return;
            }

            const newUser = User.fromJson(r.data.user);
            getPermissions({
                fetchPolicy: "no-cache"
            }).then(r => {
                setPermissions(r.data.permissions ?? []);
            }).catch(() => {
                setPermissions([]);
            }).finally(() => {
                setUser(prevState => ({...prevState, user: newUser, loading: false}));
            });
        }).catch(() => {
            setUser(prevState => ({...prevState, user: undefined, loading: false}));
        });
        // eslint-disable-next-line
    }, []);

    const funcObj: UserFunctionProvider = useMemo(() => {
        return {
            login: (email, password, rememberMe, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                login({
                    variables: {
                        email: email,
                        password: password,
                        rememberMe: rememberMe
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    const newUser = User.fromJson(r.data.login);
                    getPermissions().then(r => {
                        setPermissions(r.data.permissions ?? []);
                    }).catch(() => {
                        setPermissions([]);
                    }).finally(() => {
                        setUser(prevState => ({...prevState, user: newUser}));
                    });
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            requestPasswordReset: (email, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                requestPasswordReset({
                    variables: {
                        email: email,
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            completeCreateUser: (email, password, token, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                completeCreateUser({
                    variables: {
                        email: email,
                        password: password,
                        token: token
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            updateSelf: (data: object, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                updateSelf({
                    variables: data,
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    const user = User.fromJson(r.data.updateUser);
                    setUser(prevState => {
                        const newUsers = [...prevState.users];
                        const index = newUsers.findIndex(item => item.id === user.id);
                        if(index !== -1) {
                            newUsers[index] = user;
                        }
                        return {
                            ...prevState,
                            user: prevState.user?.id === user.id ? user : prevState.user,
                            updateUser: user,
                            users: newUsers
                        };
                    });
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            hasPermission: (checkPerms: Permission[]) => {
                if (checkPerms.length === 0) {
                    return true;
                }

                if (permissions.indexOf(Permission.ADMIN.valueOf()) !== -1) {
                    return true;
                }

                return checkPerms.some(value => permissions.includes(value.valueOf()));
            },
            revokeHashes: (id: string | undefined, hashes: string[] | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                revokeTokens({
                    variables: {
                        id: id,
                        hashes: hashes
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    const user = User.fromJson(r.data.revokeTokens);
                    setUser(prevState => ({
                        ...prevState,
                        user: prevState.user?.id === user.id ? user : prevState.user,
                        updateUser: user
                    }));
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            logout: (navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                logout({
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    setUser(prevState => ({
                        ...prevState,
                        user: undefined,
                        loading: false,
                        updateUser: undefined,
                        opened: false
                    }));
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            getUsers: (navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void)=>  {
                getUsers({
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.error !== undefined || r.data === undefined) {
                        onUnauthenticatedError(r.error, setUser, navigate);
                        onError?.(r.error?.graphQLErrors ?? []);
                        return;
                    }

                    setUser(prevState => ({...prevState, users: User.arrayFromJson(r.data.users) ?? []}));
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            createUser: (email: string, personalInformation: PersonalInformation, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                createUser({
                    variables: {
                        email: email,
                        personalInformation: personalInformation
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    const newUser = User.fromJson(r.data.createUser);
                    setUser(prevState => {
                        const users = [...prevState.users];
                        users.push(newUser);
                        return {...prevState, users: users};
                    });
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            deleteUser: (id: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                deleteUser({
                    variables: {
                        id: id ?? ""
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    setUser(prevState => {
                        const users = [...prevState.users];
                        const index = users.findIndex(v => v.id === id);
                        if (index !== -1) {
                            users.splice(index, 1);
                        }
                        return {...prevState, updateUser: undefined, users: users};
                    });
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            sendInvite: (id: string | undefined, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                sendInvite({
                    variables: {
                        id: id ?? ""
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    const user = User.fromJson(r.data.sendInvite);
                    setUser(prevState => {
                        const newUsers = [...prevState.users];
                        const index = newUsers.findIndex(item => item.id === user.id);
                        if(index !== -1) {
                            newUsers[index] = user;
                        }

                        return {
                            ...prevState,
                            user: prevState.user?.id === user.id ? user : prevState.user,
                            updateUser: user,
                            users: newUsers
                        };
                    });
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            updatePassword: (email, password, token, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                updatePassword({
                    variables: {
                        email: email,
                        password: password,
                        resetToken: token
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            getPermissions: (navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                getPermissions({
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.error !== undefined || r.data === undefined) {
                        onUnauthenticatedError(r.error, setUser, navigate);
                        onError?.(r.error?.graphQLErrors ?? []);
                        return;
                    }
                    setPermissions(r.data.permissions ?? []);
                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
        };
        // eslint-disable-next-line
    }, [permissions]);

    return (
        <UserContextState.Provider value={user}>
            <UserContextUpdater.Provider value={setUser}>
                <UserFunctionProviderContext.Provider value={funcObj}>
                    {props.children}
                </UserFunctionProviderContext.Provider>
            </UserContextUpdater.Provider>
        </UserContextState.Provider>
    );
}