import React from "react";
import {NumberInput, TextInput} from "@mantine/core";
import {useUserFunctionProvider} from "../../context/UserContext";
import {UseFormReturnType} from "@mantine/form";
import {Permission} from "../../models/Permission";
import {useDisclosure} from "@mantine/hooks";
import {hasError} from "../../util/GraphQl";
import {useNavigate} from "react-router-dom";
import {useTitleContextState, useTitleFunctionProvider} from "../../context/TitleContext";
import FormDrawer from "../formdrawer/FormDrawer";

interface TitleFormType {
    id: string;
    name: string;
    burdenRate: string;
    billableRate: string;
}

const DEFAULT_VALUES: TitleFormType = {
    id: '',
    name: '',
    burdenRate: '',
    billableRate: ''
};

interface TitleDrawerProps {
    opened: boolean;
    close: () => void;
}

function TitleDrawer(props: TitleDrawerProps) {
    const userFunctionProvider = useUserFunctionProvider();
    const titles = useTitleContextState();
    const titleFunctionProvider = useTitleFunctionProvider();
    const navigate = useNavigate();
    const [loading, loadingHandlers] = useDisclosure(false);

    const canDelete = userFunctionProvider.hasPermission([Permission.DELETE_TITLE]);
    const canCreate = userFunctionProvider.hasPermission([Permission.CREATE_TITLE]);
    const canUpdate = userFunctionProvider.hasPermission([Permission.UPDATE_TITLE]);

    const deleteTitle = (form: UseFormReturnType<TitleFormType, (values: TitleFormType) => TitleFormType>,
                         setAlert: React.Dispatch<React.SetStateAction<{ message: string, color: string }>>) => {
        loadingHandlers.open();
        setAlert({message: "", color: "red"});
        titleFunctionProvider.deleteTitle(titles.updateTitle?.id, navigate, () => {
            setAlert({message: "Successfully deleted title", color: "green"});
            form.setValues(DEFAULT_VALUES);
        }, (errors) => {
            if (hasError(errors, "UserNotFoundException")) {
                setAlert({message: "Unable to find title to delete", color: "red"});
            } else {
                setAlert({message: "Error while trying to delete title", color: "red"});
            }
        }, () => {
            loadingHandlers.close();
        });
    };

    const complete = (values: TitleFormType,
                      form: UseFormReturnType<TitleFormType, (values: TitleFormType) => TitleFormType>,
                      setAlert: React.Dispatch<React.SetStateAction<{ message: string, color: string }>>) => {
        loadingHandlers.open();
        setAlert({message: "", color: "red"});
        if (titles.updateTitle === undefined) {
            titleFunctionProvider.createTitle({
                data: {
                    name: values.name,
                    burdenRate: parseFloat(values.burdenRate),
                    billableRate: parseFloat(values.billableRate)
                }
            }, navigate, () => {
                setAlert({message: "Successfully created title", color: "green"});
                form.reset();
            }, (errors) => {
                if (hasError(errors, "UserNotFoundException")) {
                    setAlert({message: "Unable to find user", color: "red"});
                } else if (hasError(errors, "TitleAlreadyExistsException")) {
                    setAlert({message: "Title already exists", color: "red"});
                } else {
                    setAlert({message: "Error while trying to create title", color: "red"});
                }
            }, () => {
                loadingHandlers.close();
            });
        } else {
            titleFunctionProvider.updateTitle({
                data: {
                    id: values.id,
                    ...(values.name !== titles.updateTitle?.name && {name: values.name}),
                    ...(parseFloat(values.burdenRate) !== titles.updateTitle?.burdenRate && {burdenRate: parseFloat(values.burdenRate)}),
                    ...(parseFloat(values.billableRate) !== titles.updateTitle?.billableRate && {billableRate: parseFloat(values.billableRate)}),
                }
            }, navigate, () => {
                setAlert({message: "Successfully updated title", color: "green"});
            }, (errors) => {
                if (hasError(errors, "TitleNotFoundException")) {
                    setAlert({message: "Unable to find title to update", color: "red"});
                } else if (hasError(errors, "UserNotFoundException")) {
                    setAlert({message: "Unable to find user", color: "red"});
                } else {
                    setAlert({message: "Error while trying to update title", color: "red"});
                }

            }, () => {
                form.resetDirty();
                form.resetTouched();
                loadingHandlers.close();
            });
        }
    };

    return (
        <FormDrawer
            opened={props.opened}
            onClose={props.close}
            title={"title"}
            titleName={titles.updateTitle?.name}
            defaultValues={DEFAULT_VALUES}
            onComplete={complete}
            onDelete={deleteTitle}
            canDelete={canDelete}
            canCreate={canCreate}
            isUpdate={titles.updateTitle !== undefined}
            updateValue={{
                id: titles.updateTitle?.id ?? '',
                name: titles.updateTitle?.name ?? '',
                burdenRate: titles.updateTitle?.burdenRate.toString() ?? '',
                billableRate: titles.updateTitle?.billableRate.toString() ?? ''
            }}
            form={(form) => (
                <>
                    <TextInput
                        type={"text"}
                        label="Name"
                        placeholder={"Name"}
                        required
                        {...form.getInputProps('name')}
                        disabled={titles.updateTitle ? !canUpdate : !canCreate}
                    />
                    <NumberInput
                        label="Burden Rate"
                        placeholder={"1.0"}
                        mt={"md"}
                        required
                        {...form.getInputProps('burdenRate')}
                        disabled={titles.updateTitle ? !canUpdate : !canCreate}
                    />
                    <NumberInput
                        label="Billable Rate"
                        placeholder={"2.0"}
                        mt={"md"}
                        required
                        {...form.getInputProps('billableRate')}
                        disabled={titles.updateTitle ? !canUpdate : !canCreate}
                    />
                </>
            )}
            loading={loading}
        />
    );
}

export default TitleDrawer;