export class Address {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    notes: string;

    constructor(addressLine1: string, addressLine2: string, city: string, state: string, zipCode: string, notes: string) {
        this.addressLine1 = addressLine1;
        this.addressLine2 = addressLine2;
        this.city = city;
        this.state = state;
        this.zipCode = zipCode;
        this.notes = notes;
    }

    static singleFromJson(json: any): Address | undefined {
        if (json === undefined || json === null) {
            return undefined;
        }

        return new Address(
            json["addressLine1"],
            json["addressLine2"],
            json["city"],
            json["state"],
            json["zipCode"],
            json["notes"]
        );
    }

    equal(compare: Address | undefined) {
        if(compare === undefined) {
            return false;
        }

        return this.addressLine1 === compare.addressLine1 &&
            this.addressLine2 === compare.addressLine2 &&
            this.city === compare.city &&
            this.zipCode === compare.zipCode &&
            this.notes === compare.notes;
    }

    toSingleLine() {
        if(this.addressLine1.length === 0) {
            return undefined;
        }
        return this.addressLine1 + ", " + this.addressLine2 + " " + this.city + ", " + this.state + " " +this.zipCode;
    }
}