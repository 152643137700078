export function getOrDefault(json: any, defaultValue: any): any {
    if (json === undefined || json === null) {
        return defaultValue;
    }

    return json;
}

export const colors = ['gray', 'red', 'pink', 'grape', 'violet', 'indigo', 'blue', 'cyan', 'green', 'lime', 'yellow', 'orange', 'teal'];

export function getColorFromString(value: string | undefined) {
    const uValue = (value?.toUpperCase()) ?? "U";
    return colors[Math.floor((uValue.charCodeAt(0) - 65) / 2.0)];
}

export function parseDate(date: Date | null) {
    if (date == null) {
        return "";
    }

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return year + "-" + month + "-" + day + "T00:00:00.00Z";
}

export type MultiLoadFunction = (onComplete: () => void) => void;

export function multiLoad(functions: MultiLoadFunction[], onStart?: () => void, onComplete?: () => void) {
    onStart?.();
    let completed = 0;
    functions.forEach(func => {
        func(() => {
            completed++;
            if (completed === functions.length) {
                onComplete?.();
            }
        });
    });
}

export function getTitle(val: string) {
    return "JobBox | " + val;
}