import React, {useEffect} from "react";
import {Table} from "@mantine/core";
import {Permission} from "../../../models/Permission";
import {useUserFunctionProvider} from "../../../context/UserContext";
import {useNavigate} from "react-router-dom";
import {SortedTable} from "../../../components/sortedtable/SortedTable";
import {ROUTE_APP_TIME} from "../../../Constants";
import ManagedAlert from "../../../components/ManagedAlert";
import {useTitleContextState, useTitleContextUpdater, useTitleFunctionProvider} from "../../../context/TitleContext";
import {Title} from "../../../models/Title";
import {getTitle, multiLoad, MultiLoadFunction} from "../../../util/Util";
import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import PageLoading from "../../../components/PageLoading";

export const CAN_SEE_TITLES = [
    Permission.READ_TITLE,
    Permission.UPDATE_TITLE,
    Permission.CREATE_TITLE,
    Permission.DELETE_TITLE,
];

function Titles() {
    useDocumentTitle(getTitle("Titles"));
    const titles = useTitleContextState();
    const setTitles = useTitleContextUpdater();
    const userFunctionProvider = useUserFunctionProvider();
    const titleFunctionProvider = useTitleFunctionProvider();
    const navigate = useNavigate();

    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});

    const canSeeTitles = userFunctionProvider.hasPermission(CAN_SEE_TITLES);
    const canEditTitles = userFunctionProvider.hasPermission([
        Permission.UPDATE_TITLE,
        Permission.CREATE_TITLE,
        Permission.DELETE_TITLE,
    ]);
    const canCreateTitles = userFunctionProvider.hasPermission([
        Permission.CREATE_TITLE,
    ]);

    const getTitles: MultiLoadFunction = (onComplete) => {
        titleFunctionProvider.getTitles(navigate, () => {
        }, () => {
            setAlert({message: "Error getting titles", color: "red"});
        }, () => {
            onComplete();
        });
    };

    useEffect(() => {
        if (!canSeeTitles) {
            navigate(ROUTE_APP_TIME);
        }

        multiLoad([getTitles], loadingHandlers.open, loadingHandlers.close);
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <PageLoading/>
    ) : (
        <React.Fragment>
            <ManagedAlert size={"xs"} mb={"md"} message={alert.message} color={alert.color} onClose={() => {
                setAlert(prevState => ({...prevState, message: ""}));
            }}/>
            <SortedTable
                columns={[
                    {name: "name", data: (item: Title) => item.name},
                    {name: "billable rate", data: (item: Title) => item.billableRate.toString()},
                    {name: "burden rate", data: (item: Title) => item.burdenRate.toString()}
                ]}
                data={titles.titles}
                onRow={item => {
                    return (
                        <React.Fragment>
                            <Table.Td>{item.name}</Table.Td>
                            <Table.Td>{item.billableRate}</Table.Td>
                            <Table.Td>{item.burdenRate}</Table.Td>
                        </React.Fragment>
                    );
                }}
                onFilter={(data, search) => {
                    const query = search.toLowerCase().trim();
                    return data.filter(value => {
                        const values = [
                            value.name,
                            value.billableRate.toString(),
                            value.burdenRate.toString(),
                        ];

                        return values.some(key => key.toLowerCase().includes(query));
                    });
                }}
                canEdit={canEditTitles}
                canCreate={canCreateTitles}
                onNew={() => setTitles(prevState => {
                    return {...prevState, updateTitle: undefined, opened: true}
                })}
                onEdit={item => setTitles(prevState => {
                    return {...prevState, updateTitle: item, opened: true}
                })}
            />
        </React.Fragment>
    )
        ;
}

export default Titles;