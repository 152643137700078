import PersonalInformation from "./PersonalInformation";
import IpAddress from "./IpAddress";
import TokenHash from "./TokenHash";

export class User {
    id: string;
    email: string;
    permissionGroupIds: string[];
    knownIpAddresses: IpAddress[];
    lastKnownIpAddress: IpAddress;
    personalInformation: PersonalInformation;
    createdAt: string;
    updatedAt: string;
    tokenHashes: TokenHash[];
    revokedTokenHashes: TokenHash[];
    active: boolean;
    titleId: string;
    inviteToken: string;


    constructor(id: string,
                email: string,
                permissionGroupIds: string[],
                knownIpAddresses: IpAddress[],
                lastKnownIpAddress: IpAddress,
                personalInformation: PersonalInformation,
                createdAt: string,
                updatedAt: string,
                tokenHashes: TokenHash[],
                revokedTokenHashes: TokenHash[],
                active: boolean,
                titleId: string,
                inviteToken: string) {
        this.id = id;
        this.email = email;
        this.permissionGroupIds = permissionGroupIds;
        this.knownIpAddresses = knownIpAddresses;
        this.lastKnownIpAddress = lastKnownIpAddress;
        this.personalInformation = personalInformation;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.tokenHashes = tokenHashes;
        this.revokedTokenHashes = revokedTokenHashes;
        this.active = active;
        this.titleId = titleId;
        this.inviteToken = inviteToken;
    }

    static arrayFromJson(json: any): User[] | undefined {
        if (json === undefined || json === null) {
            return undefined;
        }

        let newArray: User[] = [];
        (json as []).forEach(value => {
            newArray.push(User.fromJson(value));
        });
        return newArray;
    }

    static fromJson(json: any): User {
        return new User(
            json["id"],
            json["email"],
            json["permissionGroupIds"] === null || json["permissionGroupIds"] === undefined ? [] : json["permissionGroupIds"],
            IpAddress.arrayFromJson(json["knownIpAddresses"]),
            IpAddress.singleFromJson(json["lastKnownIpAddress"]),
            PersonalInformation.singleFromJson(json["personalInformation"]),
            json["createdAt"],
            json["updatedAt"],
            TokenHash.arrayFromJson(json["tokenHashes"]),
            TokenHash.arrayFromJson(json["revokedTokenHashes"]),
            (json["active"] === true || json["active"] === "true"),
            json["titleId"] === null ? "" : json["titleId"],
            json["inviteToken"] === null ? "" : json["inviteToken"],
        );
    }

    getPreferredName(): string {
        const pn = (this.personalInformation.preferredFirstName.length === 0 ?
                this.personalInformation.firstName :
                this.personalInformation.preferredFirstName
        ) + " " + this.personalInformation.lastName;
        return pn.trim().length === 0 ? this.email : pn;
    }

    getInitials() {
        if (this.personalInformation === undefined ||
            (this.personalInformation.firstName.length === 0 && this.personalInformation.lastName.length === 0)) {
            return "";
        }
        return (this.personalInformation.firstName.at(0) ?? "") + (this.personalInformation.lastName.at(0) ?? "");
    }


}