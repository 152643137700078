// noinspection GraphQLUnresolvedReference

import {GraphQLErrors} from "@apollo/client/errors";
import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo} from "react";
import {Title} from "../models/Title";
import {ApolloError, gql, useLazyQuery, useMutation} from "@apollo/client";
import {useUserContextState, useUserContextUpdater} from "./UserContext";
import {hasError, onUnauthenticatedError} from "../util/GraphQl";
import {NavigateFunction} from "react-router-dom";
import {Time} from "../models/Time";

export interface TimeContextStateType {
    updateTime: Time | undefined,
    timeReport: Time[]
}

export interface TimeFunctionProvider {
    getTime(date: string, userId: string | undefined, navigate: NavigateFunction, onSuccess?: (time: Time | undefined) => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    getTimeReport(from: string, to: string, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    updateTime(data: object, userId: string | undefined, navigate: NavigateFunction, onSuccess?: (time: Time | undefined) => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;

    lockTime(from: string, to: string, lock: boolean, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void): void;
}
const DEFAULT_STATE = {
    updateTime: undefined,
    timeReport: []
};
export const TimeContextState = createContext<TimeContextStateType>(DEFAULT_STATE);
export const TimeContextUpdater = createContext<Dispatch<SetStateAction<TimeContextStateType>>>(() => {
    return;
});
export const TimeFunctionProviderContext = createContext<TimeFunctionProvider>({} as TimeFunctionProvider);

export function useTimeContextState() {
    return useContext(TimeContextState);
}

export function useTimeContextUpdater() {
    return useContext(TimeContextUpdater);
}

export function useTimeFunctionProvider() {
    return useContext(TimeFunctionProviderContext);
}

const GET_TIME = gql`
    query time($date: String!, $userId: ID) {
        time(date: $date, userId: $userId) {
            ...FullTime
        }
    }
`;
const UPDATE_TIME = gql`
    mutation updateTime($data: TimeInput!, $userId: ID) {
        updateTime(data: $data, userId: $userId) {
            ...FullTime
        }
    }
`;
const GET_TIME_REPORT = gql`
    query timeReport($from: String!, $to: String!) {
        timeReport(from: $from, to: $to) {
            ...FullTime
        }
    }
`;
const LOCK_TIME = gql`
    mutation lockTime($from: String!, $to: String!, $lock: Boolean!) {
        lockTime(from: $from, to: $to, lock: $lock)
    }
`;

interface TimeContextProviderProps {
    children?: ReactNode;
}

export function TimeContextProvider(props: TimeContextProviderProps) {
    const user = useUserContextState();
    const setUser = useUserContextUpdater();

    const [time, setTime] = React.useState<TimeContextStateType>(DEFAULT_STATE);

    useEffect(() => {
        if(user.user === undefined) {
            setTime(DEFAULT_STATE);
        }
    }, [user.user]);

    const [getTime] = useLazyQuery(GET_TIME);
    const [updateTime] = useMutation(UPDATE_TIME);
    const [getTimeReport] = useLazyQuery(GET_TIME_REPORT);
    const [lockTime] = useMutation(LOCK_TIME);

    const funcObj = useMemo(() => {
        return {
            getTime: (date: string, userId: string | undefined, navigate: NavigateFunction, onSuccess?: (time: Time | undefined) => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                getTime({
                    variables: {
                        date: date,
                        userId: userId
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.error !== undefined || r.data === undefined) {
                        if (hasError(r.error?.graphQLErrors ?? [], "TimeNotFoundException")) {
                            setTime(prevState => ({...prevState, updateTime: undefined}));
                        }
                        onUnauthenticatedError(r.error, setUser, navigate);
                        onError?.(r.error?.graphQLErrors ?? []);
                        return;
                    }

                    const val = Time.singleFromJson(r.data.time);
                    setTime(prevState => ({...prevState, updateTime: val}));
                    onSuccess?.(val);
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            getTimeReport: (from: string, to: string, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                getTimeReport({
                    variables: {
                        from: from,
                        to: to
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.error !== undefined || r.data === undefined) {
                        onUnauthenticatedError(r.error, setUser, navigate);
                        onError?.(r.error?.graphQLErrors ?? []);
                        return;
                    }

                    setTime(prevState => ({...prevState, timeReport: Time.arrayFromJson(r.data.timeReport) ?? []}));
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            updateTime: (data: object, userId: string | undefined, navigate: NavigateFunction, onSuccess?: (time: Time | undefined) => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                updateTime({
                    variables: {
                        data: data,
                        userId: userId
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    const val = Time.singleFromJson(r.data.updateTime);
                    setTime(prevState => ({...prevState, updateTime: val}));
                    onSuccess?.(val);
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            },
            lockTime: (from: string, to: string, lock: boolean, navigate: NavigateFunction, onSuccess?: () => void, onError?: (errors: GraphQLErrors) => void, onFinally?: () => void) => {
                lockTime({
                    variables: {
                        from: from,
                        to: to,
                        lock: lock
                    },
                    fetchPolicy: "no-cache"
                }).then(r => {
                    if (r.errors !== undefined || r.data === undefined) {
                        onError?.(r.errors ?? []);
                        return;
                    }

                    onSuccess?.();
                }).catch((error: ApolloError) => {
                    onUnauthenticatedError(error, setUser, navigate);
                    onError?.(error.graphQLErrors ?? []);
                }).finally(() => {
                    onFinally?.();
                });
            }
        };
    }, []);

    return (
        <TimeContextState.Provider value={time}>
            <TimeContextUpdater.Provider value={setTime}>
                <TimeFunctionProviderContext.Provider value={funcObj}>
                    {props.children}
                </TimeFunctionProviderContext.Provider>
            </TimeContextUpdater.Provider>
        </TimeContextState.Provider>
    );
}