import React, {useEffect} from "react";
import {Avatar, Badge, Group, rem, Table, Text} from "@mantine/core";
import {Permission} from "../../../models/Permission";
import {useUserContextState, useUserContextUpdater, useUserFunctionProvider} from "../../../context/UserContext";
import {useNavigate} from "react-router-dom";
import {getColorFromString, getTitle, multiLoad, MultiLoadFunction} from "../../../util/Util";
import {SortedTable} from "../../../components/sortedtable/SortedTable";
import {User} from "../../../models/User";
import {useTitleContextState, useTitleFunctionProvider} from "../../../context/TitleContext";
import ManagedAlert from "../../../components/ManagedAlert";
import PageLoading from "../../../components/PageLoading";
import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import {ROUTE_APP_TIME} from "../../../Constants";

export const CAN_SEE_USERS = [
    Permission.READ_EMPLOYEES,
    Permission.UPDATE_EMPLOYEES,
    Permission.DELETE_EMPLOYEES,
    Permission.CREATE_EMPLOYEES
];

function Users() {
    useDocumentTitle(getTitle("Users"));
    const user = useUserContextState();
    const setUser = useUserContextUpdater();
    const userFunctionProvider = useUserFunctionProvider();
    const titles = useTitleContextState();
    const titleFunctionProvider = useTitleFunctionProvider();
    const navigate = useNavigate();

    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});

    const canSeeUsers = userFunctionProvider.hasPermission(CAN_SEE_USERS);
    const canEditUsers = userFunctionProvider.hasPermission([
        Permission.UPDATE_EMPLOYEES,
        Permission.DELETE_EMPLOYEES,
        Permission.CREATE_EMPLOYEES,
    ]);
    const canCreateUsers = userFunctionProvider.hasPermission([
        Permission.CREATE_EMPLOYEES,
    ]);
    const canSeeTitles = userFunctionProvider.hasPermission([Permission.READ_TITLE, Permission.UPDATE_TITLE, Permission.DELETE_TITLE, Permission.CREATE_TITLE]);


    const getUsers: MultiLoadFunction = (onComplete) => {
        userFunctionProvider.getUsers(navigate, () => {
        }, () => {
            setAlert({message: "Error getting titles", color: "red"});
        }, () => {
            onComplete();
        });
    };

    const getTitles: MultiLoadFunction = (onComplete) => {
        if (!canSeeTitles) {
            onComplete();
            return;
        }

        titleFunctionProvider.getTitles(navigate, () => {
        }, () => {
            setAlert({message: "Error getting titles", color: "red"});
        }, () => {
            onComplete();
        });
    };

    useEffect(() => {
        if (!canSeeUsers) {
            navigate(ROUTE_APP_TIME);
        }

        multiLoad([getUsers, getTitles], loadingHandlers.open, loadingHandlers.close);
        // eslint-disable-next-line
    }, []);

    return loading ? (
        <PageLoading/>
    ) : (
        <React.Fragment>
            <ManagedAlert size={"xs"} mb={"md"} message={alert.message} color={alert.color} onClose={() => {
                setAlert(prevState => ({...prevState, message: ""}));
            }}/>
            <SortedTable
                columns={[
                    {name: "name", data: (item: User) => item.getPreferredName()},
                    {name: "email", data: (item: User) => item.email},
                    {name: "phone number", data: (item: User) => item.personalInformation.phoneNumber},
                    {name: "title", data: (item: User) => canSeeTitles ? item.titleId : ""},
                    {name: "active", data: (item: User) => item.active.toString()}
                ]}
                data={user.users}
                onRow={item => {
                    const initials = (item.getInitials().toUpperCase()) ?? "U";
                    const color = getColorFromString(initials);
                    return (
                        <React.Fragment>
                            <Table.Td>
                                <Group gap="sm" wrap={"nowrap"}>
                                    <Avatar size={26} color={color}>{initials}</Avatar>
                                    <Text size="sm" fw={500}>
                                        {item.getPreferredName()}
                                    </Text>
                                </Group>
                            </Table.Td>
                            <Table.Td>{item.email}</Table.Td>
                            <Table.Td>{item.personalInformation.phoneNumber}</Table.Td>
                            <Table.Td>
                                <Text fw={500} size={"sm"}>
                                    {canSeeTitles ? titles.titles.find(title => title.id === item.titleId)?.name ?? "" : "[ REDACTED ]"}
                                </Text>
                            </Table.Td>
                            <Table.Td>
                                <Badge color={item.active ? "green" : "red"} size="xs" radius="xs"
                                       style={{minWidth: rem(60)}}>
                                    {item.active ? "Active" : "Inactive"}
                                </Badge>
                            </Table.Td>
                        </React.Fragment>
                    );
                }}
                canEdit={canEditUsers}
                canCreate={canCreateUsers}
                onFilter={(data, search) => {
                    const query = search.toLowerCase().trim();
                    return data.filter(value => {
                        const values = [
                            value.getPreferredName(),
                            value.email,
                            value.personalInformation.phoneNumber,
                            canSeeTitles ? value.titleId : "",
                            value.active ? "active" : "inactive"
                        ];

                        return values.some(key => key.toLowerCase().includes(query));
                    });
                }}
                onNew={() => setUser(prevState => {
                    return {...prevState, updateUser: undefined, opened: true}
                })}
                onEdit={item => setUser(prevState => {
                    return {...prevState, updateUser: item, opened: true}
                })}
            />
        </React.Fragment>
    );
}

export default Users;