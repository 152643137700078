import {JobStateEnum} from "./JobStateEnum";

export class JobState {
    date: string;
    state: JobStateEnum;


    constructor(date: string, state: JobStateEnum) {
        this.date = date;
        this.state = state;
    }

    static arrayFromJson(json: any): JobState[] | undefined {
        if (json === undefined || json === null) {
            return undefined;
        }

        let newArray: JobState[] = [];
        (json as []).forEach(value => {
            newArray.push(JobState.singleFromJson(value));
        });
        return newArray;
    }

    static singleFromJson(json: any): JobState {
        return new JobState(
            json["date"],
            json["state"] as JobStateEnum
        );
    }
}