import React from 'react';
import {Box, Collapse, Group, rem, Text, ThemeIcon, UnstyledButton} from '@mantine/core';
import {IconChevronRight} from '@tabler/icons-react';
import classes from './NavLinkGroups.module.css';
import {useDisclosure} from "@mantine/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import cx from "clsx";

interface LinkProps {
    label: string;
    link: string;
}

interface LinkGroupProps {
    icon: React.FC<any>;
    label: string;
    initiallyOpened?: boolean;
    links?: LinkProps[];
    link?: string;
}

export function LinkGroup({icon: Icon, label, initiallyOpened, links, link}: LinkGroupProps) {
    const navigate = useNavigate();
    const location = useLocation();
    const hasLinks = links !== undefined;
    const [opened, handlers] = useDisclosure(initiallyOpened ?? false);
    const items = (hasLinks ? links : []).map((link) => (
        <Text<'a'>
            component="a"
            className={classes.link}
            href={link.link}
            key={link.label}
            onClick={(event) => event.preventDefault()}
        >
            {link.label}
        </Text>
    ));

    return (
        <>
            <UnstyledButton onClick={() => link === undefined ? handlers.toggle() : navigate(link)} className={cx(classes.control, location.pathname === link ? classes.selected : undefined)}>
                <Group justify="space-between" gap={0}>
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                        <ThemeIcon variant="light" size={30}>
                            <Icon style={{width: rem(18), height: rem(18)}}/>
                        </ThemeIcon>
                        <Box ml="md">{label}</Box>
                    </Box>
                    {hasLinks && (
                        <IconChevronRight
                            className={classes.chevron}
                            stroke={1.5}
                            style={{
                                width: rem(16),
                                height: rem(16),
                                transform: opened ? 'rotate(-90deg)' : 'none',
                            }}
                        />
                    )}
                </Group>
            </UnstyledButton>
            {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
        </>
    );
}