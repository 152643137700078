import {ApolloError} from "@apollo/client";
import {User} from "../models/User";
import {NavigateFunction} from "react-router-dom";
import {GraphQLErrors} from "@apollo/client/errors";
import {ROUTE_LOGIN} from "../Constants";
import {Dispatch, SetStateAction} from "react";
import {ServerParseError} from "@apollo/client/link/http/parseAndCheckHttpResponse";
import {DEFAULT_STATE, UserContextStateType} from "../context/UserContext";

export function onUnauthenticatedError(error: ApolloError | undefined,
                                       setUser: Dispatch<SetStateAction<UserContextStateType>>,
                                       navigate: NavigateFunction) {
    if(error === undefined) {
        return;
    }

    if (hasErrorFromApollo(error, "UnauthenticatedException") || hasNetwork401(error)) {
        setUser(DEFAULT_STATE);
        navigate(ROUTE_LOGIN);
    }
}

export function hasNetwork401(apolloError: ApolloError) {
    return apolloError.networkError !== undefined && (apolloError.networkError as ServerParseError)?.statusCode === 401;
}

export function hasErrorFromApollo(apolloError: ApolloError, error: string): boolean {
    if (apolloError.graphQLErrors === undefined) {
        return false;
    }

    return hasError(apolloError.graphQLErrors, error);
}

export function hasError(errors: GraphQLErrors, error: string): boolean {
    for (const gQlError of errors) {
        if (gQlError.extensions.classification === error) {
            return true;
        }
    }

    return false;
}