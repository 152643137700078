import React from "react";
import {IconInfoCircle} from "@tabler/icons-react";
import {Alert, useMantineTheme} from "@mantine/core";
import {MantineColor} from "@mantine/core/lib/core";
import {MantineSpacing} from "@mantine/core/lib/core/MantineProvider";
import {StyleProp} from "@mantine/core/lib/core/Box/style-props/style-props.types";
import {MantineSize} from "@mantine/core/lib/core/MantineProvider/theme.types";

interface ManagedAlertProps {
    message: string;
    color: string;
    variant?: string;
    onClose: () => void;
    size?: MantineSize;
    mt?: StyleProp<MantineSpacing>;
    mb?: StyleProp<MantineSpacing>;
}

function ManagedAlert(props: ManagedAlertProps) {
    const theme = useMantineTheme();
    return props.message.length !== 0 ? (
        <Alert
            variant={props.variant ?? "filled"}
            color={props.color as MantineColor}
            withCloseButton
            title={props.message}
            icon={<IconInfoCircle/>}
            onClose={props.onClose}
            mt={props.mt}
            mb={props.mb}
            styles={{
                root: {
                    padding: `${theme.spacing[props.size ?? "sm"]}`
                },
                closeButton: {
                    marginTop: 0
                }
            }}
        />
    ) : (
        <React.Fragment/>
    );
}

export default ManagedAlert;