import {Text} from "@mantine/core";
import React from "react";

interface PageTitleProps {
    text: string;
}

function PageTitle(props: PageTitleProps) {
    return (
        <Text fz={24} fw={700} mb={16} lh={1} style={{textTransform: "capitalize"}}>{props.text}</Text>
    );
}

export default PageTitle;