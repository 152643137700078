import React from "react";
import {
    Anchor,
    Box,
    Button,
    Checkbox,
    Container,
    Flex,
    Group,
    Paper,
    PasswordInput,
    Text,
    TextInput,
    Title,
} from '@mantine/core';
import classes from "./Login.module.css";
import gatewayClasses from "../Gateway.module.css";
import {useLocation, useNavigate} from "react-router-dom";
import {EMAIL_PLACEHOLDER, EMAIL_VALIDATION_ERROR_TEXT, ROUTE_FORGOT_PASSWORD, ROUTE_SIGNUP} from "../../../Constants";
import {isEmail, useForm} from "@mantine/form";
import {useUserFunctionProvider} from "../../../context/UserContext";
import {useDisclosure, useDocumentTitle} from "@mantine/hooks";
import ManagedAlert from "../../../components/ManagedAlert";
import GlobalLoading from "../../../components/GlobalLoading";
import {getTitle} from "../../../util/Util";

function Login() {
    useDocumentTitle(getTitle("Login"));
    const navigate = useNavigate();
    const location = useLocation();
    const userFunctions = useUserFunctionProvider();
    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({
        message: location.state?.alert?.message ?? "",
        color: location.state?.alert?.color ?? "red"
    });
    const form = useForm({
        initialValues: {
            email: '',
            password: '',
            rememberMe: false
        },
        validateInputOnBlur: true,
        validate: {
            email: isEmail(EMAIL_VALIDATION_ERROR_TEXT)
        }
    });

    const login = (values: { email: string, password: string, rememberMe: boolean }) => {
        loadingHandlers.open();
        userFunctions.login(values.email, values.password, values.rememberMe, () => {

        }, () => {
            setAlert({message: "Error while trying to login", color: "red"});
        }, () => {
            loadingHandlers.close();
        });
    };

    const loginContent = (
        <React.Fragment>
            <TextInput
                type={"email"}
                label="Email"
                placeholder={EMAIL_PLACEHOLDER}
                required
                {...form.getInputProps('email')}
            />
            <PasswordInput
                label="Password"
                placeholder="Your password"
                required mt="md"
                {...form.getInputProps('password')}
            />
            <Flex direction={"column-reverse"}>
                <Button fullWidth mt="xl" type={"submit"}>Sign in</Button>
                <Group justify="space-between" mt="lg">
                    <Checkbox
                        label="Remember me"
                        {...form.getInputProps('rememberMe', {type: 'checkbox'})}
                    />
                    <Anchor component="button" size="sm" onClick={() => navigate(ROUTE_FORGOT_PASSWORD)}>
                        Forgot password?
                    </Anchor>
                </Group>
            </Flex>
        </React.Fragment>
    );

    return (
        <Box className={gatewayClasses.container}>
            <Box id={"needed-so-flex-does-not-resize-paper"}>
                <Container size={420}>
                    <Title ta="center" className={classes.title}>
                        Welcome back!
                    </Title>
                    <Text c="dimmed" size="sm" ta="center" mt={5}>
                        Do not have an account yet?{' '}
                        <Anchor size="sm" component="button" onClick={() => navigate(ROUTE_SIGNUP)}>
                            Create account
                        </Anchor>
                    </Text>
                    <form onSubmit={form.onSubmit(login)}>
                        <GlobalLoading loading={loading}/>
                        <ManagedAlert mt={30} message={alert.message} color={alert.color} onClose={() => {
                            setAlert(prevState => ({...prevState, message: ""}));
                        }}/>
                        <Paper withBorder shadow="md" p={30} mt={30} radius="md" visibleFrom={"xs"}>
                            {loginContent}
                        </Paper>
                        <Box mt={30} hiddenFrom={"xs"}>
                            {loginContent}
                        </Box>
                    </form>
                </Container>
            </Box>
        </Box>
    );
}

export default Login;