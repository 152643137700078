import {Address} from "./Address";
import PersonalInformation from "./PersonalInformation";


export class Contact {
    id: string;
    email: string;
    personalInformation: PersonalInformation
    isBusiness: boolean;
    businessName: string;
    mailingAddress: Address | undefined;
    billingAddress: Address | undefined;
    notes: string;
    active: boolean;

    constructor(id: string,
                email: string,
                personalInformation: PersonalInformation,
                isBusiness: boolean,
                businessName: string,
                mailingAddress: Address | undefined,
                billingAddress: Address | undefined,
                notes: string,
                active: boolean) {
        this.id = id;
        this.email = email;
        this.personalInformation = personalInformation;
        this.isBusiness = isBusiness;
        this.businessName = businessName;
        this.mailingAddress = mailingAddress;
        this.billingAddress = billingAddress;
        this.notes = notes;
        this.active = active;
    }

    getPreferredName(): string {
        if(this.isBusiness) {
            return this.businessName;
        }

        const pn = (this.personalInformation.preferredFirstName.length === 0 ?
                this.personalInformation.firstName :
                this.personalInformation.preferredFirstName
        ) + " " + this.personalInformation.lastName;
        return pn.trim().length === 0 ? this.email : pn;
    }

    static arrayFromJson(json: any): Contact[] | undefined {
        if (json === undefined || json === null) {
            return undefined;
        }

        let newArray: Contact[] = [];
        (json as []).forEach(value => {
            newArray.push(Contact.singleFromJson(value));
        });
        return newArray;
    }

    static singleFromJson(json: any): Contact {
        return new Contact(
            json["id"],
            json["email"],
            PersonalInformation.singleFromJson(json["personalInformation"]),
            (json["isBusiness"] === "true" || json["isBusiness"] === true),
            json["businessName"],
            Address.singleFromJson(json["mailingAddress"]),
            Address.singleFromJson(json["billingAddress"]),
            json["notes"],
            (json["active"] === "true" || json["active"] === true),
        );
    }
}