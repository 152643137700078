export class PermissionGroup {
    id: string;
    name: string;
    permissions: number[];

    constructor(id: string, name: string, permissions: number[]) {
        this.id = id;
        this.name = name;
        this.permissions = permissions;
    }

    static arrayFromJson(json: any): PermissionGroup[] | undefined {
        if (json === undefined || json === null) {
            return undefined;
        }

        let newArray: PermissionGroup[] = [];
        (json as []).forEach(value => {
            newArray.push(PermissionGroup.singleFromJson(value));
        });
        return newArray;
    }

    static singleFromJson(json: any): PermissionGroup {
        return new PermissionGroup(
            json["id"],
            json["name"],
            json["permissions"] === undefined || json["permissions"] === null ? [] : json["permissions"]
        );
    }
}