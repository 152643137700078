export default class TokenHash {
    id: string;
    tokenHash: string;
    expirationDate: string;
    agent: string;

    constructor(id: string, tokenHash: string, expirationDate: string, agent: string) {
        this.id = id;
        this.tokenHash = tokenHash;
        this.expirationDate = expirationDate;
        this.agent = agent;
    }

    static arrayFromJson(json: any): TokenHash[] {
        if (json === undefined || json === null) {
            return [];
        }

        let newArray: TokenHash[] = [];
        (json as []).forEach(value => {
            newArray.push(TokenHash.singleFromJson(value));
        });
        return newArray;
    }

    static singleFromJson(json: any): TokenHash {
        if (json === undefined || json === null) {
            return new TokenHash("", "", "", "");
        }

        return new TokenHash(
            json["id"],
            json["tokenHash"],
            json["expirationDate"],
            json["agent"]
        );
    }
}