import {Address} from "./Address";
import {JobState} from "./JobState";

export class Job {
    id: string;
    date: string;
    name: string;
    shortName: string;
    customerId: string;
    location: Address | undefined;
    states: JobState[] | undefined;
    notes: string;


    constructor(id: string,
                date: string,
                name: string,
                shortName: string,
                customerId: string,
                location: Address | undefined,
                states: JobState[] | undefined,
                notes: string) {
        this.id = id;
        this.date = date;
        this.name = name;
        this.shortName = shortName;
        this.customerId = customerId;
        this.location = location;
        this.states = states;
        this.notes = notes;
    }

    static arrayFromJson(json: any): Job[] | undefined {
        if (json === undefined || json === null) {
            return undefined;
        }

        let newArray: Job[] = [];
        (json as []).forEach(value => {
            newArray.push(Job.singleFromJson(value));
        });
        return newArray;
    }

    static singleFromJson(json: any): Job {
        return new Job(
            json["id"],
            json["date"],
            json["name"],
            json["shortName"],
            json["customerId"],
            Address.singleFromJson(json["location"]),
            JobState.arrayFromJson(json["states"]),
            json["notes"]
        );
    }

    hasAddress() {
        return this.location !== undefined && this.location.addressLine1.length > 0;
    }

    dateToString() {
        return new Date(Date.parse(this.date))
            .toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric'})
    }
}