import React, {ReactNode, useEffect, useMemo} from "react";
import {
    ActionIcon,
    Blockquote, Button, Checkbox,
    Container,
    Group,
    Input,
    NumberInput,
    Paper,
    SegmentedControl,
    Select,
    Stack,
    Text,
    Tabs, Loader, Progress, Box, useMantineTheme, Accordion, Avatar, Table, NativeSelect
} from "@mantine/core";
import classes from "../../Global.module.css";
import {Permission} from "../../../models/Permission";
import {useUserContextState, useUserFunctionProvider} from "../../../context/UserContext";
import {useNavigate} from "react-router-dom";
import {ROUTE_APP_TIME} from "../../../Constants";
import ManagedAlert from "../../../components/ManagedAlert";
import {useJobContextState, useJobFunctionProvider} from "../../../context/JobContext";
import {IconCheck, IconClock, IconPlus, IconReport, IconTrashX} from "@tabler/icons-react";
import TimeReport from "./TimeReport";
import {getColorFromString, getTitle, multiLoad, MultiLoadFunction, parseDate} from "../../../util/Util";
import {useDisclosure, useDocumentTitle, useMediaQuery} from "@mantine/hooks";
import PageLoading from "../../../components/PageLoading";
import SubmitTime from "./SubmitTime";
import {DateInput, DatePickerInput} from "@mantine/dates";
import globalClasses from "../../Global.module.css";
import cx from "clsx";
import {TimeItem} from "../../../models/TimeItem";
import {useTimeContextState, useTimeFunctionProvider} from "../../../context/TimeContext";
import {hasError} from "../../../util/GraphQl";
import {SortedTable} from "../../../components/sortedtable/SortedTable";
import dayjs from "dayjs";
import {Time as TimeObj} from "../../../models/Time";

export const CAN_SEE_TIME = [];

interface TabProps {
    visible: boolean;
    updateKey?: string;
    onUpdate?: () => void;
}
function getTodayMinusDays(days: number) {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
}


function TimeTab(props: TabProps) {
    const theme = useMantineTheme();
    const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

    const users = useUserContextState();
    const jobs = useJobContextState();
    const userFunctionProvider = useUserFunctionProvider();
    const time = useTimeContextState();
    const timeFunctionProvider = useTimeFunctionProvider();
    const navigate = useNavigate();
    const [date, setDate] = React.useState<Date | null>(new Date());
    const [newTime, setNewTime] = React.useState<TimeItem[]>([]);
    const [selectedUser, setSelectedUser] = React.useState(users.user?.id);
    const [locked, setLocked] = React.useState(time.updateTime?.locked ?? false);

    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "green"});

    const canSeeOthersTime = userFunctionProvider.hasPermission([
        Permission.READ_OTHERS_TIME,
        Permission.UPDATE_OTHERS_TIME
    ]);
    const canUpdateOthersTime = userFunctionProvider.hasPermission([Permission.UPDATE_OTHERS_TIME]);

    const cannotDelete = (index: number, array: TimeItem[]): boolean => {
        return array[0].job === null ||
            (canUpdateOthersTime ? false : time.updateTime?.locked ?? false) ||
            index === array.length -1 ||
            array[index].time <= 0
    };

    const saveTime = (newTime: TimeItem[]) => {
        loadingHandlers.open();
        timeFunctionProvider.updateTime({
            id: time.updateTime?.id,
            date: parseDate(date),
            time: newTime.filter(v => v.job !== null && v.job.length > 0 && v.time > 0),
            locked: canUpdateOthersTime ? locked : undefined
        }, canUpdateOthersTime ? selectedUser : undefined, navigate, (time) => {
            const tTime = [...time?.copyTimeItems() ?? []];
            tTime.push(new TimeItem(null, 0));
            setNewTime(tTime);
            setLocked(time?.locked ?? false);
            setAlert({message: "Saved time", color: "green"});
            props.onUpdate?.();
        }, () => {
            setAlert({message: "Error updating time", color: "red"});
        }, () => {
            loadingHandlers.close();
        });
    };

    useEffect(() => {
        loadingHandlers.open();
        timeFunctionProvider.getTime(parseDate(date), canSeeOthersTime ? selectedUser : undefined, navigate, (time) => {
            const tTime = [...time?.copyTimeItems() ?? []];
            tTime.push(new TimeItem(null, 0));
            setNewTime(tTime);
            setLocked(time?.locked ?? false);
        }, (errors) => {
            if (hasError(errors, "TimeNotFoundException")) {
                setNewTime([new TimeItem(null, 0)]);
                setLocked(false);
            } else if (hasError(errors, "TimeLockedException")) {
                setAlert({message: "Time is locked", color: "red"});
            } else {
                setAlert({message: "Error getting time", color: "red"});
            }
        }, () => {
            loadingHandlers.close();
        });
        // eslint-disable-next-line
    }, [date, selectedUser]);

    useEffect(() => {
        if(newTime === null || newTime.length === 0) {
            return;
        }
        saveTime(newTime);
    }, [locked]);

    return (
        <Stack gap={"xs"} display={props.visible ? undefined : "none"}>
            <Group>
                <Group grow style={{flexGrow: 1}}>
                    <DatePickerInput
                        value={date}
                        onChange={setDate}
                        label="Date"
                        placeholder="Date"
                        className={globalClasses.control}
                        style={{width: '100%'}}
                        dropdownType={isXs ? "modal" : undefined}
                    />
                    {isXs ? (
                        <NativeSelect
                            label={"User"}
                            placeholder="User"
                            data={users.users.map(item => ({
                                value: item.id,
                                label: item.getPreferredName()
                            }))}
                            value={selectedUser}
                            onChange={(event) => setSelectedUser(event.currentTarget.value ?? undefined)}
                            className={cx(globalClasses.control, !canUpdateOthersTime && globalClasses.hidden)}
                            style={{width: '100%'}}
                        />
                    ) : (
                        <Select
                            label={"User"}
                            placeholder="User"
                            data={users.users.map(item => ({
                                value: item.id,
                                label: item.getPreferredName()
                            }))}
                            nothingFoundMessage="Nothing found..."
                            searchable
                            value={selectedUser}
                            onChange={(value) => setSelectedUser(value ?? undefined)}
                            className={cx(globalClasses.control, !canUpdateOthersTime && globalClasses.hidden)}
                            style={{width: '100%'}}
                        />
                    )}
                </Group>
                {loading ? (
                    <React.Fragment>
                        <Loader color="blue" size={24} style={{margin: "23.7px 2px 0px 2px"}} visibleFrom={"sm"}/>
                        <Progress color="cyan" value={100} striped animated style={{width: '100%'}} hiddenFrom={'sm'}/>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box visibleFrom={"sm"}><IconCheck stroke={1.5} color={alert.color} size={24} style={{margin: "23.7px 2px 0px 2px"}}/></Box>
                        <Progress color={alert.color} value={100} style={{width: '100%'}} hiddenFrom={'sm'}/>
                    </React.Fragment>
                )}
            </Group>
            {newTime.map((item, index, array) => (
                <Group mt={"md"} key={index}>
                    <Group grow style={{flexGrow: 1}}>
                        {isXs ? (
                            <NativeSelect
                                placeholder="Job"
                                data={[...jobs.jobs.filter(job => (array.find(t => t.job === job.id) === undefined) || job.id === item.job).sort().map(item => ({
                                    value: item.id,
                                    label: item.name
                                })), {value: "", label: ""}]}
                                value={item.job ?? ""}
                                onChange={(event) => setNewTime((prevState) => {
                                    const newTimes = [...prevState];
                                    newTimes[index] = new TimeItem(event.currentTarget.value, newTimes[index].time);
                                    if(newTimes[index].job === "" || newTimes[index].job === null) {
                                        newTimes.splice(index, 1);
                                        saveTime(newTimes);
                                    }

                                    if(index === newTimes.length - 1) {
                                        newTimes.push(new TimeItem(null, 0));
                                    }

                                    return newTimes;
                                })}
                                className={globalClasses.control}
                                disabled={canUpdateOthersTime ? false : time.updateTime?.locked ?? false}
                                style={{width: '100%'}}
                            />
                        ) : (
                            <Select
                                placeholder="Job"
                                data={jobs.jobs.filter(job => (array.find(t => t.job === job.id) === undefined) || job.id === item.job).sort().map(item => ({
                                    value: item.id,
                                    label: item.name
                                }))}
                                nothingFoundMessage="Nothing found..."
                                searchable
                                value={item.job}
                                onChange={(event) => setNewTime((prevState) => {
                                    const newTimes = [...prevState];
                                    newTimes[index] = new TimeItem(event, newTimes[index].time);
                                    if(newTimes[index].job === "" || newTimes[index].job === null) {
                                        newTimes.splice(index, 1);
                                        saveTime(newTimes);
                                    }

                                    if(index === newTimes.length - 1) {
                                        newTimes.push(new TimeItem(null, 0));
                                    }

                                    return newTimes;
                                })}
                                className={globalClasses.control}
                                disabled={canUpdateOthersTime ? false : time.updateTime?.locked ?? false}
                                style={{width: '100%'}}
                            />
                        )}
                        <NumberInput
                            placeholder={"Hours"}
                            className={globalClasses.control}
                            value={item.time === 0 ? "" : item.time}
                            onChange={value => {
                                const newVal = parseFloat(value.toString());
                                if(newVal === array[index].time || isNaN(newVal)) {
                                    return;
                                }
                                const newTimes = [...array];
                                newTimes[index].time = newVal;
                                saveTime(newTimes);
                            }}
                            disabled={array[index].job === null || array[index].job === "" || (canUpdateOthersTime ? false : time.updateTime?.locked ?? false)}
                            style={{width: '100%'}}
                            rightSection={!cannotDelete(index, array) && isXs === true ? (
                                <ActionIcon
                                    variant="subtle"
                                    color={"red"}
                                    onClick={() => {
                                        const newTimes = [...array];
                                        newTimes.splice(index, 1);

                                        saveTime(newTimes);
                                    }}
                                >
                                    <IconTrashX size={"70%"} stroke={1.5}/>
                                </ActionIcon>
                            ) : undefined}
                        />
                    </Group>
                    <ActionIcon
                        variant="filled"
                        className={globalClasses.control}
                        onClick={() => {
                            const newTimes = [...array];
                            newTimes.splice(index, 1);

                            saveTime(newTimes);
                        }}
                        disabled={cannotDelete(index, array)}
                        visibleFrom={"xs"}
                    >
                        <IconTrashX size={"70%"} stroke={1.5}/>
                    </ActionIcon>
                </Group>
            ))}
            <Group mt={"md"} justify={"space-between"}>
                <Blockquote color="yellow" p={12} className={globalClasses.control}>
                    {(newTime.length === 0 || newTime[0].job === null) ? (
                        "No time submitted for this date"
                    ) : (
                        "Total time: " + newTime.map(v => v.time).reduce((p, c) => p + c) + " hours"
                    )}
                </Blockquote>
                <Checkbox
                    label="Locked"
                    checked={locked}
                    onChange={() => setLocked(prevState => (!prevState))}
                    disabled={users.user?.id !== selectedUser && !canUpdateOthersTime}
                    className={!canUpdateOthersTime && globalClasses.hidden}
                />
            </Group>
        </Stack>
    );
}

function HistoryTab(props: TabProps) {
    const theme = useMantineTheme();
    const isXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

    const time = useTimeContextState();
    const timeFunctionProvider = useTimeFunctionProvider();
    const users = useUserContextState();
    const userFunctionProvider = useUserFunctionProvider();
    const jobs = useJobContextState();
    const navigate = useNavigate();

    const [from, setFrom] = React.useState<Date | null>(getTodayMinusDays(6));
    const [to, setTo] = React.useState<Date | null>(new Date());
    const [locked, setLocked] = React.useState(true);

    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});

    const canUpdateOthersTime = userFunctionProvider.hasPermission([
        Permission.UPDATE_OTHERS_TIME,
    ]);

    const accord = useMemo(() => {
        const items = new Array<{userId: string, date: string, jobId: string, hours: number}>();
        time.timeReport.forEach(value => {
            value.time?.forEach(tValue => {
                items.push({
                    userId: value.userId,
                    date: value.date,
                    jobId: tValue.job ?? "",
                    hours: tValue.time
                });
            });
        });
        return items;
    }, [time.timeReport]);

    const totalHours = useMemo(() => {
        const items = new Array<{userId: string, hours: number}>();
        users.users.filter(u => u.active).forEach(user => {
            items.push({
                userId: user.id,
                hours: time.timeReport
                    .filter(v => v.userId === user.id)
                    .map(v => v.time)
                    .filter(v => v !== undefined)
                    .flat()
                    .map(d => d!.time)
                    .reduce((p, c) => (p + c), 0) ?? 0
            });
        });
        return items;
    }, [time.timeReport]);

    const lockTime = () => {
        setAlert({message: "", color: "red"});
        timeFunctionProvider.lockTime(parseDate(from), parseDate(to), locked, navigate, () => {
            setAlert({message: "Updated time successfully", color: "green"});
        }, () => {
            setAlert({message: "Error updating time", color: "red"});
        }, () => {
        })
    };

    const getTimeReport: MultiLoadFunction = (onComplete) => {
        timeFunctionProvider.getTimeReport(parseDate(from), parseDate(to), navigate, () => {
        }, () => {
            setAlert({message: "Error getting jobs", color: "red"});
        }, () => {
            onComplete();
        });
    };

    useEffect(() => {
        multiLoad([getTimeReport], loadingHandlers.open, loadingHandlers.close);
        // eslint-disable-next-line
    }, [from, to, props.updateKey]);

    return (
        <Stack display={props.visible ? undefined : "none"}>
            <ManagedAlert size={"xs"} mb={"md"} message={alert.message} color={alert.color} onClose={() => {
                setAlert(prevState => ({...prevState, message: ""}));
            }}/>
            <Group grow>
                <DatePickerInput
                    value={from}
                    onChange={setFrom}
                    label="From"
                    placeholder="Date"
                    className={globalClasses.control}
                    dropdownType={isXs ? "modal" : undefined}
                />
                <DatePickerInput
                    value={to}
                    onChange={setTo}
                    label="To"
                    placeholder="Date"
                    className={globalClasses.control}
                    dropdownType={isXs ? "modal" : undefined}
                />
            </Group>
            <SortedTable
                columns={[
                    {
                        name: "user",
                        data: (item: {userId: string, date: string, jobId: string, hours: number}) =>
                            users.users.find(v => v.id === item.userId)?.getPreferredName() ?? item.userId
                    },
                    {
                        name: "date",
                        data: (item: {userId: string, date: string, jobId: string, hours: number}) =>
                            Date.parse(item.date).toString()
                    },
                    {
                        name: "job",
                        data: (item: {userId: string, date: string, jobId: string, hours: number}) =>
                            jobs.jobs.find(j => j.id === item.jobId)?.name ?? item.jobId
                    },
                    {
                        name: "hours",
                        data: (item: {userId: string, date: string, jobId: string, hours: number}) =>
                            item.hours.toString()
                    },
                ]}
                data={accord}
                onRow={item => {
                    return (
                        <React.Fragment>
                            <Table.Td>{users.users.find(v => v.id === item.userId)?.getPreferredName() ?? item.userId}</Table.Td>
                            <Table.Td>{(dayjs.utc(item.date).format("dddd MMM D, YYYY"))}</Table.Td>
                            <Table.Td>{jobs.jobs.find(j => j.id === item.jobId)?.name ?? item.jobId}</Table.Td>
                            <Table.Td>{item.hours}</Table.Td>
                        </React.Fragment>
                    );
                }}
                onFilter={(data) => data}
                onNew={() => {
                }}
                onEdit={() => {
                }}
                isBasic
            />
            <SortedTable
                columns={[
                    {
                        name: "user",
                        data: (item: {userId: string, hours: number}) =>
                            users.users.find(v => v.id === item.userId)?.getPreferredName() ?? item.userId
                    },
                    {
                        name: "hours",
                        data: (item: {userId: string, hours: number}) =>
                            item.hours.toString()
                    },
                ]}
                data={totalHours}
                onRow={item => {
                    return (
                        <React.Fragment>
                            <Table.Td>{users.users.find(v => v.id === item.userId)?.getPreferredName() ?? item.userId}</Table.Td>
                            <Table.Td>{item.hours}</Table.Td>
                        </React.Fragment>
                    );
                }}
                onFilter={(data) => data}
                onNew={() => {
                }}
                onEdit={() => {
                }}
                isBasic
            />
            {/*<Accordion variant="contained" mt={"md"} multiple defaultValue={undefined}>
                {Array.from(accord.entries()).map((kvp, index) => {
                    const fUser = users.users.find(v => v.id === kvp[0]);
                    const initials = (fUser?.getInitials().toUpperCase()) ?? "U";
                    const color = getColorFromString(initials);
                    const data = kvp[1].flatMap(v => v.time?.map(t => ({
                        date: v.date,
                        job: t.job ?? "",
                        hours: t.time
                    })) ?? []);

                    return (
                        <Accordion.Item value={kvp[0]} key={index}>
                            <Accordion.Control
                                icon={
                                    <Avatar size={26} color={color}>{initials}</Avatar>
                                }
                            >
                                {fUser?.getPreferredName()}
                            </Accordion.Control>
                            <Accordion.Panel>
                                <SortedTable
                                    columns={[
                                        {
                                            name: "date",
                                            data: (item: { date: string, job: string, hours: number }) => Date.parse(item.date).toString()
                                        },
                                        {
                                            name: "job",
                                            data: (item: {
                                                date: string,
                                                job: string,
                                                hours: number
                                            }) => jobs.jobs.find(j => j.id === item.job)?.name ?? item.job
                                        },
                                        {
                                            name: "hours",
                                            data: (item: {
                                                date: string,
                                                job: string,
                                                hours: number
                                            }) => item.hours.toString()
                                        },
                                    ]}
                                    data={data}
                                    onRow={item => {
                                        return (
                                            <React.Fragment>
                                                <Table.Td>{(dayjs.utc(item.date).format("dddd MMM D, YYYY"))}</Table.Td>
                                                <Table.Td>{jobs.jobs.find(j => j.id === item.job)?.name ?? item.job}</Table.Td>
                                                <Table.Td>{item.hours}</Table.Td>
                                            </React.Fragment>
                                        );
                                    }}
                                    onFilter={(data) => data}
                                    onNew={() => {
                                    }}
                                    onEdit={() => {
                                    }}
                                    isBasic
                                />
                                <Text>Total hours: {data.length === 0 ? 0 : data.map(d => d.hours).reduce((p, c) => (p + c), 0)}</Text>
                            </Accordion.Panel>
                        </Accordion.Item>
                    );
                })}
            </Accordion>*/}
            <Group mt={"md"}>
                <Checkbox
                    label="Locked"
                    checked={locked}
                    onChange={() => setLocked(prevState => (!prevState))}
                    disabled={!canUpdateOthersTime}
                />
            </Group>
            <Group mt={"md"}>
                <Button
                    className={globalClasses.control}
                    onClick={lockTime}
                    leftSection={<IconPlus size={16}/>}
                    disabled={!canUpdateOthersTime}
                >
                    Update time
                </Button>
            </Group>
        </Stack>
    );
}

function Time() {
    useDocumentTitle(getTitle("Time"));
    const userFunctionProvider = useUserFunctionProvider();
    const jobFunctionProvider = useJobFunctionProvider();
    const navigate = useNavigate();

    const [tab, setTab] = React.useState('time');
    const [loading, loadingHandlers] = useDisclosure(false);
    const [alert, setAlert] = React.useState({message: "", color: "red"});
    const [key, setKey] = React.useState<string | undefined>(undefined);

    const canSeeJobs = userFunctionProvider.hasPermission([
        Permission.READ_JOB,
    ]);
    const canSeeOthersTime = userFunctionProvider.hasPermission([
        Permission.READ_OTHERS_TIME,
        Permission.UPDATE_OTHERS_TIME
    ]);
    const canSeeUsers = userFunctionProvider.hasPermission([Permission.READ_EMPLOYEES]);

    const getJobs: MultiLoadFunction = (onComplete) => {
        jobFunctionProvider.getJobs(undefined, navigate, () => {
        }, () => {
            setAlert({message: "Error getting jobs", color: "red"});
        }, () => {
            onComplete();
        });
    };
    const getUsers: MultiLoadFunction = (onComplete) => {
        /*if (!canSeeOthersTime) {
            onComplete();
            return;
        }*/

        userFunctionProvider.getUsers(navigate, () => {
        }, () => {
            setAlert({message: "Error getting users", color: "red"});
        }, () => {
            onComplete();
        })
    };

    useEffect(() => {
        if (!canSeeJobs) {
            navigate(ROUTE_APP_TIME);
        }

        multiLoad([getJobs, getUsers], loadingHandlers.open, loadingHandlers.close);
        // eslint-disable-next-line
    }, []);


    return loading ? (
        <PageLoading/>
    ) : (
        <React.Fragment>
            <ManagedAlert size={"xs"} mb={"md"} message={alert.message} color={alert.color} onClose={() => {
                setAlert(prevState => ({...prevState, message: ""}));
            }}/>
            {/*<Tabs defaultValue={"time"}>
                <Tabs.List>
                    <Tabs.Tab value="time" leftSection={<IconClock size={12}/>}>
                        Time
                    </Tabs.Tab>
                    <Tabs.Tab value="runtime" leftSection={<IconReport size={12}/>}
                              disabled={!canSeeOthersTime || !canSeeJobs || !canSeeUsers}>
                        Time Report
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="time">
                    <Paper shadow="xs" radius="xs" p="md" className={classes.paper}>
                        <SubmitTime onUpdate={() => {
                            setKey(Date.now().toString());
                        }}/>
                    </Paper>
                </Tabs.Panel>

                <Tabs.Panel value="runtime">
                    <Paper shadow="xs" radius="xs" p="md" className={classes.paper}>
                        <TimeReport updateKey={key}/>
                    </Paper>
                </Tabs.Panel>
            </Tabs>*/}

            <Container size="sm" p={0}>
                <SegmentedControl
                    value={tab}
                    onChange={setTab}
                    color="blue"
                    data={[
                        {label: 'Time', value: 'time'},
                        {label: 'History', value: 'history'},
                    ]}
                    fullWidth
                    mb={"md"}
                />
                <TimeTab visible={tab === 'time'} onUpdate={() => {
                    setKey(Date.now().toString());
                }}/>
                <HistoryTab visible={tab === 'history'} updateKey={key}/>
                {/*<VehiclesTab visible={tab === 'vehicle'}/>
                <MessageTab visible={tab === 'message'}/>
                <SignalTab visible={tab === 'signal'}/>*/}
            </Container>
        </React.Fragment>
    );
}

export default Time;