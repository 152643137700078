import React from "react";
import {Checkbox, Divider, Group, TextInput} from "@mantine/core";
import {useUserFunctionProvider} from "../../context/UserContext";
import globalClasses from "../../pages/Global.module.css";
import {Permission} from "../../models/Permission";
import {useDisclosure} from "@mantine/hooks";
import {hasError} from "../../util/GraphQl";
import {useNavigate} from "react-router-dom";
import {useContactContextState, useContactFunctionProvider} from "../../context/ContactContext";
import {EMAIL_PLACEHOLDER} from "../../Constants";
import FormDrawer from "../formdrawer/FormDrawer";
import {UseFormReturnType} from "@mantine/form/lib";

interface ContactFormType {
    id: string;
    email: string;
    firstName: string;
    preferredFirstName: string;
    middleName: string;
    lastName: string;
    phoneNumber: string;
    isBusiness: boolean;
    businessName: string;
    mailingAddressLine1: string;
    mailingAddressLine2: string;
    mailingCity: string;
    mailingState: string;
    mailingZipCode: string;
    mailingNotes: string;
    billingAddressLine1: string;
    billingAddressLine2: string;
    billingCity: string;
    billingState: string;
    billingZipCode: string;
    billingNotes: string;
    bSameAsM: boolean;
    notes: string;
    active: boolean
}

const DEFAULT_VALUES = {
    id: '',
    email: '',
    firstName: '',
    preferredFirstName: '',
    middleName: '',
    lastName: '',
    phoneNumber: '',
    isBusiness: true,
    businessName: '',
    mailingAddressLine1: '',
    mailingAddressLine2: '',
    mailingCity: '',
    mailingState: '',
    mailingZipCode: '',
    mailingNotes: '',
    billingAddressLine1: '',
    billingAddressLine2: '',
    billingCity: '',
    billingState: '',
    billingZipCode: '',
    billingNotes: '',
    bSameAsM: true,
    notes: '',
    active: true,
};

interface ContactDrawerProps {
    opened: boolean;
    close: () => void;
}

function ContactDrawer(props: ContactDrawerProps) {
    const userFunctionProvider = useUserFunctionProvider();
    const contacts = useContactContextState();
    const contactFunctionProvider = useContactFunctionProvider();
    const navigate = useNavigate();
    const [loading, loadingHandlers] = useDisclosure(false);

    const canDelete = userFunctionProvider.hasPermission([Permission.DELETE_CONTACT]);
    const canCreate = userFunctionProvider.hasPermission([Permission.CREATE_CONTACT]);
    const canUpdate = userFunctionProvider.hasPermission([Permission.UPDATE_CONTACT]);

    const deleteContact = (form: UseFormReturnType<ContactFormType, (values: ContactFormType) => ContactFormType>,
                           setAlert: React.Dispatch<React.SetStateAction<{message: string, color: string}>>) => {
        loadingHandlers.open();
        setAlert({message: "", color: "red"});
        contactFunctionProvider.deleteContact(contacts.updateContact?.id, navigate, () => {
            setAlert({message: "Successfully deleted contact", color: "green"});
            form.setValues(DEFAULT_VALUES);
        }, (errors) => {
            if (hasError(errors, "UserNotFoundException")) {
                setAlert({message: "Unable to find contact to delete", color: "red"});
            } else {
                setAlert({message: "Error while trying to delete contact", color: "red"});
            }
        }, () => {
            loadingHandlers.close();
        });
    };

    const complete = (values: ContactFormType,
                      form: UseFormReturnType<ContactFormType, (values: ContactFormType) => ContactFormType>,
                      setAlert: React.Dispatch<React.SetStateAction<{message: string, color: string}>>) => {
        loadingHandlers.open();
        setAlert({message: "", color: "red"});
        if (contacts.updateContact === undefined) {
            contactFunctionProvider.createContact({
                data: {
                    email: values.email,
                    personalInformation: {
                        firstName: values.isBusiness ? '' : values.firstName,
                        preferredFirstName: values.isBusiness ? '' : values.preferredFirstName,
                        middleName: values.isBusiness ? '' : values.middleName,
                        lastName: values.isBusiness ? '' : values.lastName,
                        phoneNumber: values.phoneNumber
                    },
                    isBusiness: values.isBusiness,
                    businessName: values.isBusiness ? values.businessName : '',
                    mailingAddress: {
                        addressLine1: values.mailingAddressLine1,
                        addressLine2: values.mailingAddressLine2,
                        city: values.mailingCity,
                        state: values.mailingState,
                        zipCode: values.mailingZipCode,
                        notes: values.mailingNotes,
                    },
                    billingAddress: {
                        addressLine1: values.bSameAsM ? values.mailingAddressLine1 : values.billingAddressLine1,
                        addressLine2: values.bSameAsM ? values.mailingAddressLine2 : values.billingAddressLine2,
                        city: values.bSameAsM ? values.mailingCity : values.billingCity,
                        state: values.bSameAsM ? values.mailingState : values.billingState,
                        zipCode: values.bSameAsM ? values.mailingZipCode : values.billingZipCode,
                        notes: values.bSameAsM ? values.mailingNotes : values.billingNotes,
                    },
                    notes: values.notes,
                    active: values.active
                }
            }, navigate, () => {
                setAlert({message: "Successfully created contact", color: "green"});
                form.reset();
            }, (errors) => {
                if (hasError(errors, "UserNotFoundException")) {
                    setAlert({message: "Unable to find user", color: "red"});
                } else if (hasError(errors, "ContactAlreadyExistsException")) {
                    setAlert({message: "Contact already exists", color: "red"});
                } else {
                    setAlert({message: "Error while trying to create contact", color: "red"});
                }
            }, () => {
                loadingHandlers.close();
            });
        } else {
            contactFunctionProvider.updateContact({
                data: {
                    id: values.id,
                    email: values.email,
                    personalInformation: {
                        firstName: values.isBusiness ? '' : values.firstName,
                        preferredFirstName: values.isBusiness ? '' : values.preferredFirstName,
                        middleName: values.isBusiness ? '' : values.middleName,
                        lastName: values.isBusiness ? '' : values.lastName,
                        phoneNumber: values.phoneNumber
                    },
                    isBusiness: values.isBusiness,
                    businessName: values.isBusiness ? values.businessName : '',
                    mailingAddress: {
                        addressLine1: values.mailingAddressLine1,
                        addressLine2: values.mailingAddressLine2,
                        city: values.mailingCity,
                        state: values.mailingState,
                        zipCode: values.mailingZipCode,
                        notes: values.mailingNotes,
                    },
                    billingAddress: {
                        addressLine1: values.bSameAsM ? values.mailingAddressLine1 : values.billingAddressLine1,
                        addressLine2: values.bSameAsM ? values.mailingAddressLine2 : values.billingAddressLine2,
                        city: values.bSameAsM ? values.mailingCity : values.billingCity,
                        state: values.bSameAsM ? values.mailingState : values.billingState,
                        zipCode: values.bSameAsM ? values.mailingZipCode : values.billingZipCode,
                        notes: values.bSameAsM ? values.mailingNotes : values.billingNotes,
                    },
                    notes: values.notes,
                    active: values.active
                }
            }, navigate, () => {
                setAlert({message: "Successfully updated title", color: "green"});
            }, (errors) => {
                if (hasError(errors, "ContactNotFoundException")) {
                    setAlert({message: "Unable to find contact to update", color: "red"});
                } else if (hasError(errors, "UserNotFoundException")) {
                    setAlert({message: "Unable to find user", color: "red"});
                } else {
                    setAlert({message: "Error while trying to update contact", color: "red"});
                }

            }, () => {
                form.resetDirty();
                form.resetTouched();
                loadingHandlers.close();
            });
        }
    };

    return (
        <FormDrawer
            opened={props.opened}
            onClose={props.close}
            title={"contact"}
            titleName={contacts.updateContact?.getPreferredName()}
            defaultValues={DEFAULT_VALUES}
            onComplete={complete}
            onDelete={deleteContact}
            canDelete={canDelete}
            canCreate={canCreate}
            isUpdate={contacts.updateContact !== undefined}
            updateValue={{
                id: contacts.updateContact?.id ?? '',
                email: contacts.updateContact?.email ?? '',
                firstName: contacts.updateContact?.personalInformation.firstName ?? '',
                preferredFirstName: contacts.updateContact?.personalInformation.preferredFirstName ?? '',
                middleName: contacts.updateContact?.personalInformation.middleName ?? '',
                lastName: contacts.updateContact?.personalInformation.lastName ?? '',
                phoneNumber: contacts.updateContact?.personalInformation.phoneNumber ?? '',
                isBusiness: contacts.updateContact?.isBusiness ?? true,
                businessName: contacts.updateContact?.businessName ?? '',
                mailingAddressLine1: contacts.updateContact?.mailingAddress?.addressLine1 ?? '',
                mailingAddressLine2: contacts.updateContact?.mailingAddress?.addressLine2 ?? '',
                mailingCity: contacts.updateContact?.mailingAddress?.city ?? '',
                mailingState: contacts.updateContact?.mailingAddress?.state ?? '',
                mailingZipCode: contacts.updateContact?.mailingAddress?.zipCode ?? '',
                mailingNotes: contacts.updateContact?.mailingAddress?.notes ?? '',
                billingAddressLine1: contacts.updateContact?.billingAddress?.addressLine1 ?? '',
                billingAddressLine2: contacts.updateContact?.billingAddress?.addressLine2 ?? '',
                billingCity: contacts.updateContact?.billingAddress?.city ?? '',
                billingState: contacts.updateContact?.billingAddress?.state ?? '',
                billingZipCode: contacts.updateContact?.billingAddress?.zipCode ?? '',
                billingNotes: contacts.updateContact?.billingAddress?.notes ?? '',
                bSameAsM: contacts.updateContact?.billingAddress?.equal(contacts.updateContact.mailingAddress) ?? true,
                notes: contacts.updateContact?.notes ?? '',
                active: contacts.updateContact?.active ?? true,
            }}
            form={(form) => (<>
                <Checkbox
                    label="Is Business"
                    mt={"md"}
                    {...form.getInputProps('isBusiness', {type: 'checkbox'})}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
                <TextInput
                    type={"text"}
                    label="Business name"
                    placeholder={"Business name"}
                    required={form.values.isBusiness}
                    mt={"md"}
                    className={!form.values.isBusiness && globalClasses.hidden}
                    {...form.getInputProps('businessName')}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
                <Group justify="space-between" mt="md" grow wrap={"nowrap"} className={form.values.isBusiness && globalClasses.hidden}>
                    <TextInput
                        type={"text"}
                        label="Name"
                        placeholder={"First name"}
                        required={!form.values.isBusiness}
                        {...form.getInputProps('firstName')}
                        disabled={contacts.updateContact ? !canUpdate : !canCreate}
                    />
                    <TextInput
                        type={"text"}
                        label="Preferred name"
                        placeholder={"Preferred first name"}
                        {...form.getInputProps('preferredFirstName')}
                        disabled={contacts.updateContact ? !canUpdate : !canCreate}
                    />
                </Group>
                <Group justify="space-between" mt="md" grow wrap={"nowrap"} className={form.values.isBusiness && globalClasses.hidden}>
                    <TextInput
                        type={"text"}
                        label="Middle name"
                        placeholder={"Middle name"}
                        {...form.getInputProps('middleName')}
                        disabled={contacts.updateContact ? !canUpdate : !canCreate}
                    />
                    <TextInput
                        type={"text"}
                        label="Last name"
                        placeholder={"Last name"}
                        {...form.getInputProps('lastName')}
                        disabled={contacts.updateContact ? !canUpdate : !canCreate}
                    />
                </Group>
                <Divider my={"md"}/>
                <TextInput
                    type={"email"}
                    label="Email"
                    placeholder={EMAIL_PLACEHOLDER}
                    mt="md"
                    {...form.getInputProps('email')}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
                <TextInput
                    type={"tel"}
                    label="Phone number"
                    placeholder={"+1-012-345-6789"}
                    mt="md"
                    {...form.getInputProps('phoneNumber')}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
                <Divider my={"md"} label={"Mailing address"}/>
                <TextInput
                    type={"text"}
                    label="Address line 1"
                    placeholder={"Address line 1"}
                    mt="md"
                    {...form.getInputProps('mailingAddressLine1')}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
                <TextInput
                    type={"text"}
                    label="Address line 2"
                    placeholder={"Address line 2"}
                    mt="md"
                    {...form.getInputProps('mailingAddressLine2')}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
                <Group justify="space-between" mt="md" grow wrap={"nowrap"}>
                    <TextInput
                        type={"text"}
                        label="City"
                        placeholder={"City"}
                        {...form.getInputProps('mailingCity')}
                        disabled={contacts.updateContact ? !canUpdate : !canCreate}
                    />
                    <TextInput
                        type={"text"}
                        label="State"
                        placeholder={"State"}
                        {...form.getInputProps('mailingState')}
                        disabled={contacts.updateContact ? !canUpdate : !canCreate}
                    />
                    <TextInput
                        type={"text"}
                        label="Zip"
                        placeholder={"Zip code"}
                        {...form.getInputProps('mailingZipCode')}
                        disabled={contacts.updateContact ? !canUpdate : !canCreate}
                    />
                </Group>
                <TextInput
                    type={"text"}
                    label="Notes"
                    mt="md"
                    placeholder={"Notes"}
                    {...form.getInputProps('mailingNotes')}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
                <Divider my={"md"} label={"Billing address"}/>
                <Checkbox
                    label="Billing address same as mailing"
                    mt={"md"}
                    {...form.getInputProps('bSameAsM', {type: 'checkbox'})}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
                <TextInput
                    type={"text"}
                    label="Address line 1"
                    placeholder={"Address line 1"}
                    mt="md"
                    {...form.getInputProps('billingAddressLine1')}
                    className={form.values.bSameAsM && globalClasses.hidden}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
                <TextInput
                    type={"text"}
                    label="Address line 2"
                    placeholder={"Address line 2"}
                    mt="md"
                    {...form.getInputProps('billingAddressLine2')}
                    className={form.values.bSameAsM && globalClasses.hidden}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
                <Group justify="space-between" mt="md" grow wrap={"nowrap"} className={form.values.bSameAsM && globalClasses.hidden}>
                    <TextInput
                        type={"text"}
                        label="City"
                        placeholder={"City"}
                        {...form.getInputProps('billingCity')}
                        disabled={contacts.updateContact ? !canUpdate : !canCreate}
                    />
                    <TextInput
                        type={"text"}
                        label="State"
                        placeholder={"State"}
                        {...form.getInputProps('billingState')}
                        disabled={contacts.updateContact ? !canUpdate : !canCreate}
                    />
                    <TextInput
                        type={"text"}
                        label="Zip"
                        placeholder={"Zip code"}
                        {...form.getInputProps('billingZipCode')}
                        disabled={contacts.updateContact ? !canUpdate : !canCreate}
                    />
                </Group>
                <TextInput
                    type={"text"}
                    label="Notes"
                    mt="md"
                    placeholder={"Notes"}
                    {...form.getInputProps('billingNotes')}
                    className={form.values.bSameAsM && globalClasses.hidden}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
                <Divider my={"md"}/>
                <TextInput
                    type={"text"}
                    label="Notes"
                    mt="md"
                    placeholder={"Notes"}
                    {...form.getInputProps('notes')}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
                <Checkbox
                    label="Active"
                    mt={"md"}
                    {...form.getInputProps('active', {type: 'checkbox'})}
                    disabled={contacts.updateContact ? !canUpdate : !canCreate}
                />
            </>)}
            loading={loading}
        />
    );
}

export default ContactDrawer;